import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import MenuContent from '../../../dropdownmenu/MenuContent'

interface FilterDropdownProps {
	title: string
	items: { id: string; label: string }[]
	selectedItems: string[]
	onItemToggle: (item: string) => void
	getItemLabel?: (id: string) => string
}

const FilterDropdown = ({
	title,
	items,
	selectedItems,
	onItemToggle,
	getItemLabel = id => id,
}: FilterDropdownProps) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
			<DropdownMenu.Trigger asChild>
				<button
					className={twMerge(
						'flex w-full items-center justify-between rounded-md bg-gray-700 px-3 py-2 text-sm font-medium text-white transition-colors hover:bg-gray-600',
						isOpen && 'cursor-pointer bg-gray-600',
					)}
				>
					<span>
						{title}
						{selectedItems.length > 0 && ` (${selectedItems.length})`}
					</span>
					<ChevronDown className='h-4 w-4' />
				</button>
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<MenuContent>
					{items.map(item => (
						<DropdownMenu.CheckboxItem
							key={item.id}
							className='cursor-pointer select-none rounded px-2 py-1.5 text-sm text-white outline-none hover:bg-gray-700 data-[highlighted]:bg-gray-700'
							checked={selectedItems.includes(item.id)}
							onCheckedChange={() => onItemToggle(item.id)}
						>
							<DropdownMenu.ItemIndicator className='absolute left-2 inline-flex w-4 items-center justify-center'>
								<span className='h-1.5 w-1.5 rounded-full bg-white' />
							</DropdownMenu.ItemIndicator>
							<span className='ml-4'>{getItemLabel(item.id)}</span>
						</DropdownMenu.CheckboxItem>
					))}
				</MenuContent>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}

export default FilterDropdown
