import { twMerge } from 'tailwind-merge'
import BaseType from '../interface/text/BaseType'

interface IProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
	children: React.ReactNode
	className?: string
	isOnDark?: boolean
}

const Label: React.FC<IProps> = ({
	children,
	className = '',
	isOnDark = false,
	...props
}) => {
	return (
		<label className={twMerge('mt-6 block', className)} {...props}>
			<BaseType isOnDark={isOnDark}>{children}</BaseType>
		</label>
	)
}

export default Label
