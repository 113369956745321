import { useContext } from 'react'
import GameContext from '../contexts/game'
import UsersContext from '../contexts/users'
import { IRegisteredUser } from '../interfaces/users'
import useGetDocumentById from './useGetDocumentById'

type Props = {
	userId: string
	assumedCharacterId?: string // can be used to force a specific character
	fallbackAvatarId?: string // can be used to force a specific fallback avatar
	disableAssumedCharacterLookup?: boolean // can be used to disable assumed character
}

// A function to find a user profile by userId
const findUserProfile = (userId: string, users: IRegisteredUser[]) =>
	users.find(u => u.userId === userId)?.userProfile

export default function useGetAvatarSrc({
	userId,
	assumedCharacterId,
	fallbackAvatarId,
	disableAssumedCharacterLookup = false,
}: Props) {
	const { game } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const user = usersState.users.find(u => u.userId === userId)
	const { fetchDocument } = useGetDocumentById()

	let avatarSrc: string | null = null

	let assumedId = ''
	if (assumedCharacterId) {
		assumedId = assumedCharacterId
	} else if (
		!disableAssumedCharacterLookup &&
		user?.userSettings?.assumedCharacterId
	) {
		assumedId = user.userSettings.assumedCharacterId
	}

	if (assumedId) {
		// find the thumbnail field name for the collection
		const document = fetchDocument(assumedId)
		const collection = game.system?.collections?.find(
			c => c.type === document?.type,
		)
		let assetId = ''
		collection?.thumbnailField?.forEach(field => {
			if (document.values[field]) {
				assetId = document.values[field]
			}
		})

		// find the asset and get its fileurl
		const asset = game.assets.byId[assetId]
		avatarSrc = asset?.fileurl || null
	}

	if (!avatarSrc) {
		const userProfile = findUserProfile(userId, usersState.users)
		avatarSrc = userProfile?.avatar || null
	}

	if (!avatarSrc && fallbackAvatarId) {
		const fallbackAsset = game.assets.byId[fallbackAvatarId]
		avatarSrc = fallbackAsset?.fileurl || null
	}

	return avatarSrc || ''
}
