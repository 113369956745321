import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AuthContext from '../../contexts/auth'
import { passwordValidationSchema } from '../../utils/validation'
import Button from '../FormComponents/Button'
import FormMessage from '../FormComponents/FormMessage'
import Label from '../FormComponents/Label'
import Input from '../Input'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	password: string
	confirmPassword: string
}

interface ITokenVerification {
	email: string
	valid: boolean
}

export default function ResetPassword() {
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [isVerifying, setIsVerifying] = useState(true)
	const [tokenInfo, setTokenInfo] = useState<ITokenVerification | null>(null)
	const [showLogoutPrompt, setShowLogoutPrompt] = useState(false)
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const token = searchParams.get('token')
	const { authState, authDispatch } = useContext(AuthContext)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(passwordValidationSchema),
		reValidateMode: 'onChange',
	})

	useEffect(() => {
		if (!token) {
			navigate('/signin')
			return
		}

		const verifyToken = async () => {
			try {
				const response = await fetch(`/api/verify-reset-token/${token}`)
				const data = await response.json()

				if (response.ok) {
					setTokenInfo(data)
					// If user is logged in and emails don't match, show prompt
					if (
						authState.userDetails.email &&
						authState.userDetails.email !== data.email
					) {
						setShowLogoutPrompt(true)
					}
				} else {
					setError(data.message || 'Invalid reset link')
					setTimeout(() => navigate('/signin'), 3000)
				}
			} catch (err) {
				setError('Failed to verify reset token')
			} finally {
				setIsVerifying(false)
			}
		}

		verifyToken()
	}, [token, navigate, authState.userDetails.email])

	const handleLogout = () => {
		authDispatch({ type: 'LOGOUT' })
		setShowLogoutPrompt(false)
	}

	const onSubmit = async (data: IFormInputs) => {
		setError('')
		setMessage('')

		try {
			const response = await fetch('/api/password-reset', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ token, newPassword: data.password }),
			})

			const responseData = await response.json()

			if (response.ok) {
				setMessage('Password successfully reset')
				setTimeout(() => navigate('/signin'), 2000)
			} else {
				setError(responseData.message || 'Something went wrong')
			}
		} catch (err) {
			setError('Failed to reset password')
		}
	}

	if (isVerifying) {
		return (
			<Card className='h-screen'>
				<div className='text-center'>Verifying reset link...</div>
			</Card>
		)
	}

	if (showLogoutPrompt) {
		return (
			<Card className='h-screen'>
				<div className='space-y-6'>
					<p>
						You are currently logged in as {authState.userDetails.email}. Do you
						want to log out and reset the password for {tokenInfo?.email}?
					</p>
					<div className='flex justify-center gap-4'>
						<Button onClick={handleLogout}>Log Out & Continue</Button>
						<Button onClick={() => navigate('/')}>Cancel</Button>
					</div>
				</div>
			</Card>
		)
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Set New Password' className='text-center' />

			<form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
				<div>
					<Label>New Password</Label>
					<Input
						type='password'
						placeholder='Enter new password'
						inputClassName='dark:bg-gray-900'
						error={errors.password?.message}
						{...register('password')}
					/>
				</div>

				<div>
					<Label>Confirm Password</Label>
					<Input
						type='password'
						placeholder='Confirm new password'
						inputClassName='dark:bg-gray-900'
						error={errors.confirmPassword?.message}
						{...register('confirmPassword')}
					/>
				</div>

				{message && <FormMessage type='success' message={message} />}
				{error && <FormMessage type='error' message={error} />}

				<Button type='submit'>Reset Password</Button>
			</form>
		</Card>
	)
}
