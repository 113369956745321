import { forwardRef, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import Label from './Label'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	className?: string
	label?: string
	inputClassName?: string
	labelClassName?: string
	isOnDark?: boolean
}

const Input = forwardRef<HTMLInputElement, IProps>((props, ref) => {
	const {
		className,
		label,
		inputClassName,
		labelClassName,
		isOnDark,
		...inputProps
	} = props
	return (
		<div className={className}>
			{label && (
				<Label
					className={twMerge('block pb-1', labelClassName)}
					htmlFor={props.name}
					isOnDark={isOnDark}
				>
					{label}
				</Label>
			)}
			<input
				{...inputProps}
				ref={ref}
				className={twMerge(
					'leading-16 w-full rounded-lg bg-gray-100 p-4 text-black',
					inputClassName,
				)}
			/>
		</div>
	)
})

export default Input
