import React from 'react'
import TextareaAutosize, {
	TextareaAutosizeProps,
} from 'react-textarea-autosize'
import { twMerge } from 'tailwind-merge'

interface IProps extends TextareaAutosizeProps {
	className?: string
}

const TextArea = React.forwardRef<HTMLTextAreaElement, IProps>(
	({ className = '', ...props }, ref) => {
		return (
			<TextareaAutosize
				ref={ref}
				className={twMerge(
					`leading-16 mt-1 mb-1 block w-full rounded-md border-none bg-gray-800 p-3 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-800 sm:text-sm`,
					className,
				)}
				{...props}
			/>
		)
	},
)

export default TextArea
