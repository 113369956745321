import { AnnotationMode } from 'pdfjs-dist'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import GameContext from '../../contexts/game'
import useGetCollectionName from '../../hooks/useGetCollectionName'
import { usePdfSheet } from '../../hooks/usePdfSheet'
import DocumentWindowMenuButton from '../Library/MenuButton'
import DocumentWindowTitle from '../Library/Title'
import Menu from '../documentmenu/Menu'
import {
	INTERFACE_BACKGROUND_COLOR,
	INTERFACE_DROPSHADOW,
} from '../interface/constants'
import PDFViewer from '../resources/PDFViewer'
import { ScaleMode, SpreadMode } from '../resources/types/pdfViewerTypes'
import WindowInner from '../window/WindowInner'

const SheetPopout = () => {
	const { game } = useContext(GameContext)
	const { sheetId } = useParams()
	const document = game.documents.byId[sheetId]
	const { singularName } = useGetCollectionName(document)
	const title = document.values?.name || `Untitled ${singularName}`

	const { pdfSheet, loading, error, resource, handleFormChange } =
		usePdfSheet(document)

	const onClose = () => {}

	if (!pdfSheet?.resourceId) {
		return (
			<WindowInner
				title={title}
				style={{
					backgroundColor: INTERFACE_BACKGROUND_COLOR,
					boxShadow: INTERFACE_DROPSHADOW,
				}}
				bodyClassName='p-2'
				onClose={onClose}
				showCloseButton={false}
			>
				<div className='text-red-500'>
					Error: No PDF sheet resource found for document type: {document.type}
				</div>
			</WindowInner>
		)
	}

	if (loading) {
		return (
			<WindowInner
				title={title}
				style={{
					backgroundColor: INTERFACE_BACKGROUND_COLOR,
					boxShadow: INTERFACE_DROPSHADOW,
				}}
				bodyClassName='p-2'
				onClose={onClose}
				showCloseButton={false}
			>
				<div className='flex items-center gap-2'>
					<span className='text-gray-600'>Loading...</span>
				</div>
			</WindowInner>
		)
	}

	if (error || !resource) {
		return (
			<WindowInner
				title={title}
				style={{
					backgroundColor: INTERFACE_BACKGROUND_COLOR,
					boxShadow: INTERFACE_DROPSHADOW,
				}}
				bodyClassName='p-2'
				onClose={onClose}
				showCloseButton={false}
			>
				<div className='text-red-500'>
					Error loading PDF sheet: {error?.message || 'Resource not found'}
				</div>
			</WindowInner>
		)
	}

	const menuButton = (
		<Menu document={document}>
			<DocumentWindowMenuButton />
		</Menu>
	)

	return (
		<WindowInner
			title={
				<DocumentWindowTitle
					title={title}
					singularName={singularName}
					document={document}
				/>
			}
			className='bg-gray-900/70'
			bodyClassName='p-0 overflow-hidden'
			style={{
				backgroundColor: INTERFACE_BACKGROUND_COLOR,
				boxShadow: INTERFACE_DROPSHADOW,
			}}
			onClose={onClose}
			headerIcon={menuButton}
			showCloseButton={false}
		>
			<PDFViewer
				resource={resource}
				isGame={true}
				showTools={false}
				formValues={document.values.formValues}
				annotationMode={AnnotationMode.ENABLE_FORMS}
				onFormChange={handleFormChange}
				initialSpreadMode={SpreadMode.NONE}
				initialScaleMode={ScaleMode.PAGE_WIDTH}
			/>
		</WindowInner>
	)
}

export default SheetPopout
