import LibraryButton from '../toolbar/LibraryButton'
import SyrinscapeButton from '../toolbar/SyrinscapeButton'
import TurnTrackerButton from '../toolbar/TurnTrackerButton'
import Toolbar from './toolbar/Toolbar'

const RightTools = () => {
	return (
		<div className='flex flex-row justify-end'>
			<Toolbar>
				<SyrinscapeButton />
				<TurnTrackerButton />
				{/* <StageButton /> */}
				<LibraryButton />
			</Toolbar>
		</div>
	)
}

export default RightTools
