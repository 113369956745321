import { useMemo } from 'react'
import RPGBookCategoryDescriptions from '../../../../interfaces/bookcategories'
import BookGrid from '../BookGrid'
import { BooksResources } from '../BooksPage'
import { ViewMode } from './ViewSelector'

interface GroupedBookListProps {
	resources: BooksResources
	viewMode: ViewMode
	selectMode: boolean
	selectedIds: string[]
	onSelect: (id: string, selected: boolean) => void
}

const GroupedBookList = ({
	resources,
	viewMode,
	selectMode,
	selectedIds,
	onSelect,
}: GroupedBookListProps) => {
	const groupedResources = useMemo(() => {
		const groups: { [key: string]: BooksResources } = {}

		Object.entries(resources).forEach(([id, resource]) => {
			let groupKey: string

			switch (viewMode) {
				case 'all':
					groupKey = 'all'
					break
				case 'category':
					// A book can be in multiple categories, so we need to handle it differently
					resource.metadata.categories?.forEach(category => {
						if (!groups[category]) {
							groups[category] = {}
						}
						groups[category][id] = resource
					})
					return
				case 'gameSystem':
					groupKey = resource.metadata.game || 'Uncategorized'
					break
				case 'ruleSystem':
					groupKey = resource.metadata.ruleSystem || 'Uncategorized'
					break
				default:
					groupKey = 'Uncategorized'
			}

			if (!groups[groupKey]) {
				groups[groupKey] = {}
			}
			groups[groupKey][id] = resource
		})

		// Sort groups by name, but keep 'Uncategorized' at the end
		return Object.entries(groups)
			.sort(([a], [b]) => {
				if (viewMode === 'all') return 0
				if (a === 'Uncategorized') return 1
				if (b === 'Uncategorized') return -1
				if (viewMode === 'category') {
					const categoryA = RPGBookCategoryDescriptions[a]?.name || a
					const categoryB = RPGBookCategoryDescriptions[b]?.name || b
					return categoryA.localeCompare(categoryB)
				}
				return a.localeCompare(b)
			})
			.reduce((acc, [key, value]) => {
				acc[key] = value
				return acc
			}, {} as { [key: string]: BooksResources })
	}, [resources, viewMode])

	const getGroupTitle = (groupKey: string) => {
		if (viewMode === 'all') {
			return 'All Books'
		}
		if (viewMode === 'category') {
			return RPGBookCategoryDescriptions[groupKey]?.name || groupKey
		}
		return groupKey === 'Uncategorized' ? 'Uncategorized' : groupKey
	}

	return (
		<div className='space-y-8'>
			{Object.entries(groupedResources).map(([groupKey, groupResources]) => (
				<div key={groupKey} className='space-y-4'>
					<h2 className='text-xl font-semibold text-white'>
						{getGroupTitle(groupKey)}
					</h2>
					<BookGrid
						resources={groupResources}
						selectMode={selectMode}
						selectedIds={selectedIds}
						onSelect={onSelect}
					/>
				</div>
			))}
		</div>
	)
}

export default GroupedBookList
