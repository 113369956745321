import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ChevronDown, LayoutGrid } from 'lucide-react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import MenuContent from '../../../dropdownmenu/MenuContent'
import { ViewMode } from './ViewSelector'

interface ViewAsDropdownProps {
	currentView: ViewMode
	onViewChange: (view: ViewMode) => void
}

const views: { id: ViewMode; label: string }[] = [
	{ id: 'all', label: 'All Books' },
	{ id: 'category', label: 'By Category' },
	{ id: 'gameSystem', label: 'By Game System' },
	{ id: 'ruleSystem', label: 'By Rule System' },
]

const ViewAsDropdown = ({ currentView, onViewChange }: ViewAsDropdownProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const handleViewChange = (view: ViewMode) => {
		onViewChange(view)
		localStorage.setItem('bookListView', view)
	}

	return (
		<DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
			<DropdownMenu.Trigger asChild>
				<button
					className={twMerge(
						'flex w-full items-center justify-between rounded-md bg-gray-700 px-3 py-2 text-sm font-medium text-white transition-colors hover:bg-gray-600',
						isOpen && 'cursor-pointer bg-gray-600',
					)}
				>
					<div className='flex items-center gap-2'>
						<LayoutGrid className='h-4 w-4' />
						<span>{views.find(v => v.id === currentView)?.label}</span>
					</div>
					<ChevronDown className='h-4 w-4' />
				</button>
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<MenuContent>
					{views.map(view => (
						<DropdownMenu.Item
							key={view.id}
							className='cursor-pointer select-none rounded px-2 py-1.5 text-sm text-white outline-none hover:bg-gray-700 data-[highlighted]:bg-gray-700'
							onClick={() => handleViewChange(view.id)}
						>
							{view.label}
						</DropdownMenu.Item>
					))}
				</MenuContent>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}

export default ViewAsDropdown
