import DiceBox from '@3d-dice/dice-box'
import DiceParser from '@3d-dice/dice-parser-interface'
import { X } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { DiceConfig } from '../../../../shared/types/dice'
import {
	DiceResult,
	ExpressionResult,
	RollResultArray,
} from '../../interfaces/dicebox'
import { INTERFACE_BRIGHT_GOLD_COLOR } from '../interface/constants'
import Overlapper from '../Overlapper'
import ColorSwatch from '../settings/ColorSwatch'
import Translucency from '../Translucency'
import AdvRollBtn from './AdvRollButton'
import './dice.css'

export interface DicePoolEntry {
	notation: string
	label: string
	count: number
}

const DRP = new DiceParser()
const DICE_CANVAS_ID = 'dice-canvas'
const ASSET_PATH = '/assets/dice-box/'

function calculateDiceScale(viewportHeight: number): number {
	const baseHeight = 1080
	const baseScale = 3.25
	const scaleFactor = viewportHeight / baseHeight
	return baseScale / scaleFactor
}

const rpgDiceConfig: DiceConfig[] = [
	{ label: 'D4', notation: '1d4', icons: ['d4'] },
	{ label: 'D6', notation: '1d6', icons: ['d6'] },
	{ label: 'D8', notation: '1d8', icons: ['d8'] },
	{ label: 'D10', notation: '1d10', icons: ['d10'] },
	{ label: 'D12', notation: '1d12', icons: ['d12'] },
	{ label: 'D20', notation: '1d20', icons: ['d20'] },
	{ label: 'D100', notation: '1d100', icons: ['d10', 'd10'] },
]

interface DiceToolsProps {
	color?: string
	diceConfig?: DiceConfig[]
	showColorPicker?: boolean
	onColorChange?: (color: string) => void
	onRollResults?: (results: ExpressionResult) => void
	rollNotation?: string
	onDicePoolChange?: (hasActiveDice: boolean) => void
}

export default function DiceTools({
	color = '#222222',
	diceConfig = rpgDiceConfig,
	showColorPicker = true,
	onColorChange,
	onRollResults = () => {},
	rollNotation,
	onDicePoolChange,
}: DiceToolsProps) {
	const [diceColor, setDiceColor] = useState(color)
	const [dicePool, setDicePool] = useState<DicePoolEntry[]>([])
	const diceRef = useRef<DiceBox | null>(null)
	const [isInitialized, setIsInitialized] = useState(false)
	const [isRolling, setIsRolling] = useState(false)
	const [needsClear, setNeedsClear] = useState(false)

	useEffect(() => {
		onDicePoolChange?.(dicePool.length > 0)
	}, [dicePool, onDicePoolChange])

	const addToDicePool = useCallback((notation: string, label: string) => {
		setDicePool(prev => {
			const existingEntry = prev.find(entry => entry.notation === notation)
			if (existingEntry) {
				return prev.map(entry =>
					entry.notation === notation
						? { ...entry, count: entry.count + 1 }
						: entry,
				)
			}
			return [...prev, { notation, label, count: 1 }]
		})
	}, [])

	const clearDicePool = useCallback(() => {
		setDicePool([])
	}, [])

	const handleRollComplete = useCallback(
		(results: DiceResult) => {
			const rerolls = DRP.handleRerolls(results)

			if (rerolls.length) {
				rerolls.forEach((roll: RollResultArray) =>
					diceRef.current?.add(roll, roll.groupId),
				)
				return rerolls
			}

			const finalResults = DRP.parseFinalResults(results)
			onRollResults(finalResults)
			setIsRolling(false)
			setNeedsClear(true)
			clearDicePool()
		},
		[onRollResults, clearDicePool],
	)

	const rollDice = useCallback(
		async (notation: string) => {
			if (!isInitialized || isRolling || !diceRef.current) return

			try {
				const parsedNotation = DRP.parseNotation(notation)
				setIsRolling(true)
				setNeedsClear(false)
				await diceRef.current.show().roll(parsedNotation)
			} catch {
				setIsRolling(false)
			}
		},
		[isInitialized, isRolling],
	)

	const rollDicePool = useCallback(() => {
		if (dicePool.length === 0) return

		const notation = dicePool
			.map(entry => `${entry.count}${entry.notation.slice(1)}`)
			.join('+')

		rollDice(notation)
		clearDicePool()
	}, [dicePool, rollDice, clearDicePool])

	const updateDiceTheme = useCallback(
		(newColor: string) => {
			if (diceRef.current && isInitialized) {
				try {
					diceRef.current.updateConfig({ themeColor: newColor })
				} catch {
					// Theme update failed, but this is not critical
				}
			}
		},
		[isInitialized],
	)

	const handleColorChange = useCallback(
		(newColor: string) => {
			setDiceColor(newColor)
			updateDiceTheme(newColor)
			onColorChange?.(newColor)
		},
		[onColorChange, updateDiceTheme],
	)

	const setupDiceBox = useCallback(async () => {
		try {
			const viewportHeight = window.innerHeight
			const calculatedScale = calculateDiceScale(viewportHeight)

			diceRef.current = new DiceBox({
				id: DICE_CANVAS_ID,
				assetPath: ASSET_PATH,
				startingHeight: 4,
				throwForce: 5,
				spinForce: 3,
				lightIntensity: 1,
				scale: calculatedScale,
				friction: 1,
				linearDamping: 0.3,
				angularDamping: 0.3,
				restitution: 0.5,
				themeColor: diceColor,
			})

			await diceRef.current.init()
			diceRef.current.onRollComplete = handleRollComplete
			setIsInitialized(true)
		} catch {
			setIsInitialized(false)
		}
	}, [diceColor, handleRollComplete])

	useEffect(() => {
		setupDiceBox()
		return () => {
			if (diceRef.current) {
				diceRef.current.destroy?.()
				diceRef.current = null
			}
		}
	}, [setupDiceBox])

	useEffect(() => {
		if (isInitialized) {
			updateDiceTheme(diceColor)
		}
	}, [isInitialized, diceColor, updateDiceTheme])

	useEffect(() => {
		setDiceColor(color)
	}, [color])

	useEffect(() => {
		const handleClick = () => {
			if (needsClear && diceRef.current) {
				diceRef.current.clear()
				setNeedsClear(false)
			}
		}

		window.addEventListener('click', handleClick)
		return () => window.removeEventListener('click', handleClick)
	}, [needsClear])

	useEffect(() => {
		if (rollNotation && isInitialized && !isRolling) {
			rollDice(rollNotation)
		}
	}, [rollNotation, isInitialized, isRolling, rollDice])

	useEffect(() => {
		const handleResize = () => {
			if (diceRef.current) {
				const newScale = calculateDiceScale(window.innerHeight)
				diceRef.current.updateConfig({ scale: newScale })
			}
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const removeFromDicePool = useCallback((notation: string) => {
		setDicePool(prev => {
			const existingEntry = prev.find(entry => entry.notation === notation)
			if (existingEntry && existingEntry.count > 1) {
				return prev.map(entry =>
					entry.notation === notation
						? { ...entry, count: entry.count - 1 }
						: entry,
				)
			}
			return prev.filter(entry => entry.notation !== notation)
		})
	}, [])

	const buttonElements = useMemo(() => {
		const configToUse = diceConfig?.length ? diceConfig : rpgDiceConfig
		return configToUse.map((btn, index) => {
			const poolEntry = dicePool.find(entry => entry.notation === btn.notation)

			return (
				<div key={index} className='transition-all duration-300'>
					<div className='relative'>
						<AdvRollBtn
							label={btn.label}
							tooltip={btn.notation}
							notation={btn.notation}
							onRoll={() => addToDicePool(btn.notation, btn.label)}
							onRemove={removeFromDicePool}
							disabled={!isInitialized || isRolling}
							icons={btn.icons}
						/>
						{poolEntry && (
							<div
								className='absolute -top-1.5 -left-1.5 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-xs font-bold text-white'
								style={{
									backgroundColor: INTERFACE_BRIGHT_GOLD_COLOR,
								}}
							>
								{poolEntry.count}
							</div>
						)}
					</div>
				</div>
			)
		})
	}, [
		diceConfig,
		isInitialized,
		isRolling,
		addToDicePool,
		removeFromDicePool,
		dicePool,
	])

	return (
		<div className='pointer-events-auto flex justify-center'>
			<div className='group w-[500px]'>
				<Overlapper className='w-full' topMost='first'>
					<AdvRollBtn
						label={<X />}
						onRoll={clearDicePool}
						notation=''
						disabled={dicePool.length === 0}
					/>
					{buttonElements}
					<AdvRollBtn
						label='Roll'
						onRoll={rollDicePool}
						tooltip=''
						notation=''
						disabled={isRolling || dicePool.length === 0}
					/>
					{showColorPicker && (
						<div
							className={twMerge(
								'rounded-full p-2 opacity-0 transition-all duration-300 group-hover:opacity-100',
								Translucency,
							)}
						>
							<ColorSwatch
								color={diceColor}
								colorName='dice'
								setEnableClickOutside={() => {}}
								onChange={handleColorChange}
								className='flex aspect-square h-5 w-5 flex-shrink-0 items-center justify-center'
							/>
						</div>
					)}
				</Overlapper>
			</div>
		</div>
	)
}
