import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_DROPSHADOW } from '../interface/constants'
import menuContentClasses from './menuContentClasses'

type Props = { children: React.ReactNode; className?: string }

const MenuContent = ({ children, className }: Props) => {
	return (
		<DropdownMenu.Content
			sideOffset={5}
			collisionPadding={20}
			className={twMerge(menuContentClasses, className)}
			style={{
				width: 'max-content',
				minWidth: '10rem',
				boxShadow: INTERFACE_DROPSHADOW,
			}}
		>
			{children}
		</DropdownMenu.Content>
	)
}

export default MenuContent
