import { gql, useMutation, useQuery } from '@apollo/client'
import { FolderIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IGame } from '../../../interfaces/game'
import AdminGameDetail from './GameDetail'

// Local interface extension for the admin view
interface IAdminGame extends Omit<IGame, 'creator'> {
	creator: {
		_id: string
		name: string
		email: string
	}
}

export const GET_ALL_GAMES = gql`
	query GetAllGames {
		adminGames {
			_id
			title
			description
			coverImage
			assets {
				byId
				allIds
			}
			creator {
				name
				email
			}
			users
			createdAt
			updatedAt
			isTemplate
		}
	}
`

const DELETE_GAMES = gql`
	mutation DeleteGames($gameIds: [ID!]!) {
		deleteGames(gameIds: $gameIds)
	}
`

const formatDate = (dateString: string) => {
	try {
		// Try parsing as ISO string first
		const date = new Date(dateString)
		if (isNaN(date.getTime())) {
			// If invalid, try parsing as Unix timestamp (in milliseconds)
			const timestampDate = new Date(parseInt(dateString))
			return timestampDate.toLocaleDateString()
		}
		return date.toLocaleDateString()
	} catch {
		return 'Invalid Date'
	}
}

function GameCover({ game }: { game: IAdminGame }) {
	if (!game.coverImage || !game.assets.byId[game.coverImage]) {
		return (
			<div className='flex h-12 w-16 items-center justify-center rounded bg-gray-200 text-gray-500'></div>
		)
	}

	const asset = game.assets.byId[game.coverImage]
	const key = asset.fileurl.replace('files/', '')

	return (
		<img
			className='h-12 w-16 rounded object-cover'
			src={`/files/${key}`}
			alt='Game cover'
			onError={e => {
				const target = e.target as HTMLImageElement
				target.onerror = null
				target.parentElement?.replaceChild(
					(() => {
						const div = document.createElement('div')
						div.className =
							'flex h-12 w-16 items-center justify-center rounded bg-gray-200 text-gray-500'
						div.textContent = 'No Cover'
						return div
					})(),
					target,
				)
			}}
		/>
	)
}

export default function AdminGames() {
	const { id } = useParams()
	const navigate = useNavigate()
	const [selectedGames, setSelectedGames] = useState<string[]>([])
	const [isSlideoverOpen, setIsSlideoverOpen] = useState(false)
	const selectedGameId = id || null
	const { data, loading, error } = useQuery<{ adminGames: IAdminGame[] }>(
		GET_ALL_GAMES,
	)
	const [deleteGames] = useMutation(DELETE_GAMES, {
		refetchQueries: [{ query: GET_ALL_GAMES }],
	})
	const [showTemplatesOnly, setShowTemplatesOnly] = useState(false)

	if (loading) return <div>Loading games...</div>
	if (error) return <div>Error loading games</div>

	const games = data?.adminGames || []

	const filteredGames = games.filter(game =>
		showTemplatesOnly ? game.isTemplate : true,
	)

	const handleSelectGame = (gameId: string) => {
		setSelectedGames(prev =>
			prev.includes(gameId)
				? prev.filter(id => id !== gameId)
				: [...prev, gameId],
		)
	}

	const handleSelectAll = () => {
		if (selectedGames.length === games.length) {
			setSelectedGames([])
		} else {
			setSelectedGames(games.map(game => game._id))
		}
	}

	const handleDeleteSelected = async () => {
		if (!selectedGames.length) return

		if (
			window.confirm(
				`Are you sure you want to delete ${selectedGames.length} games?`,
			)
		) {
			try {
				await deleteGames({
					variables: { gameIds: selectedGames },
				})
				setSelectedGames([])
			} catch (err) {
				console.error('Error deleting games:', err)
				alert('Failed to delete games')
			}
		}
	}

	const handleGameClick = (gameId: string) => {
		navigate(`/admin/games/${gameId}`)
		setIsSlideoverOpen(true)
	}

	const handleCloseSlideOver = () => {
		setIsSlideoverOpen(false)
		// Let the transition complete before navigating away
		setTimeout(() => {
			navigate('/admin/games')
		}, 500) // Match this with the transition duration
	}

	return (
		<div>
			<div className='mb-8 flex items-center justify-between'>
				<div className='flex items-center gap-4'>
					<h1 className='text-2xl font-bold'>Games</h1>
					<div className='flex items-center gap-2'>
						<input
							type='checkbox'
							id='templateFilter'
							checked={showTemplatesOnly}
							onChange={e => setShowTemplatesOnly(e.target.checked)}
							className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
						/>
						<label htmlFor='templateFilter' className='text-sm text-gray-700'>
							Show Templates Only
						</label>
					</div>
				</div>
				{selectedGames.length > 0 && (
					<button
						onClick={handleDeleteSelected}
						className='rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700'
					>
						Delete Selected ({selectedGames.length})
					</button>
				)}
			</div>

			<div className='overflow-hidden rounded-lg bg-white shadow'>
				<table className='min-w-full divide-y divide-gray-200'>
					<thead className='bg-gray-50'>
						<tr>
							<th className='px-6 py-3 text-left'>
								<input
									type='checkbox'
									checked={selectedGames.length === games.length}
									onChange={handleSelectAll}
									className='h-4 w-4 rounded border-gray-300'
								/>
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Game
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Creator
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Players
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Created
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Last Updated
							</th>
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-200 bg-white'>
						{filteredGames.map(game => (
							<tr key={game._id} className='hover:bg-gray-50'>
								<td className='whitespace-nowrap px-6 py-4'>
									<input
										type='checkbox'
										checked={selectedGames.includes(game._id)}
										onChange={() => handleSelectGame(game._id)}
										className='h-4 w-4 rounded border-gray-300'
									/>
								</td>
								<td className='whitespace-nowrap px-6 py-4'>
									<div
										onClick={() => handleGameClick(game._id)}
										className='flex cursor-pointer items-center hover:bg-gray-50'
									>
										<div className='h-12 w-16 flex-shrink-0'>
											<GameCover game={game} />
										</div>
										<div className='ml-4'>
											<div className='flex items-center gap-2 text-sm font-medium text-gray-900'>
												{game.title}
												{game.isTemplate && (
													<span className='inline-flex items-center gap-1 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
														<FolderIcon className='h-3 w-3' />
														Template
													</span>
												)}
											</div>
											<div className='text-sm text-gray-500'>
												{game.description}
											</div>
										</div>
									</div>
								</td>
								<td className='whitespace-nowrap px-6 py-4'>
									<div className='text-sm text-gray-900'>
										{game.creator.name}
									</div>
									<div className='text-sm text-gray-500'>
										{game.creator.email}
									</div>
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{game.users.length}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{formatDate(game.createdAt)}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{formatDate(game.updatedAt)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<AdminGameDetail
				game={games.find(g => g._id === selectedGameId) || undefined}
				onClose={handleCloseSlideOver}
				open={isSlideoverOpen}
			/>
		</div>
	)
}
