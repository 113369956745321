import { useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useGetAvatarUrl from '../../hooks/useGetAvatarUrl'
import useTheme from '../../hooks/useTheme'
import Avatar from '../Avatar'
import Overlapper from '../Overlapper'
import MediumUppercase from '../interface/text/MediumUppercase'

const PlayerTurnTracker = () => {
	const { game } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { currentRound, turnOrder, currentTurnIndex } = game?.turnTracker || {}
	const { primary, secondary } = useTheme()
	const [hoveredName, setHoveredName] = useState<string | null>(null)
	const getAvatarUrl = useGetAvatarUrl()

	// Render nothing if there's no current round or turn order
	if (!currentRound || !turnOrder?.allIds.length) {
		return null
	}

	const participants = turnOrder.allIds
		.map((id, index) => {
			const participant = turnOrder.byId[id]
			const isCurrentTurn = index === currentTurnIndex
			const isActive = participant.isActive
			const name = participant.identified ? participant.name : 'Unidentified'
			const avatarUrl = getAvatarUrl(participant.documentId)

			// Find any user who has assumed this character
			const userAssumingCharacter = usersState?.users?.find(
				user =>
					user.userSettings?.assumedCharacterId === participant.documentId,
			)
			const color = userAssumingCharacter?.userSettings?.color || 'transparent'
			const isOnline = userAssumingCharacter?.online ?? true

			return {
				id,
				name,
				isCurrentTurn,
				isActive,
				color,
				avatarUrl,
				isOnline,
			}
		})
		.filter(participant => participant.isActive) // Filter out inactive participants

	return (
		<div className='-mt-4 space-y-4 pb-0'>
			<Overlapper
				className='mt-2 space-x-2'
				selectedIndex={currentTurnIndex}
				hoverScale={1.1}
				align='center'
			>
				{participants.map(participant => (
					<div
						key={participant.id}
						className={twMerge(
							'relative',
							!participant.isOnline && 'opacity-40 hover:opacity-100',
							'transition-opacity duration-300',
						)}
						title={participant.name}
						onMouseEnter={() => setHoveredName(participant.name)}
						onMouseLeave={() => setHoveredName(null)}
					>
						<Avatar
							src={participant.avatarUrl}
							color={
								participant.color === 'transparent'
									? primary
									: participant.color
							}
							userName={participant.name}
							className={twMerge(
								'aspect-[3/4] h-24 transform transition-all duration-300 ease-in-out',
								participant.isCurrentTurn ? 'mx-2' : '',
							)}
							style={{
								transform: participant.isCurrentTurn ? 'scale(1.25)' : 'none',
								transformOrigin: 'center 50%',
							}}
							size='md'
						/>
					</div>
				))}
			</Overlapper>

			<div className='mt-2 text-center text-sm'>
				<MediumUppercase style={{ color: primary }}>
					{hoveredName || participants[currentTurnIndex]?.name}
				</MediumUppercase>
				<MediumUppercase style={{ color: secondary }}>
					Round {currentRound}
				</MediumUppercase>
			</div>
		</div>
	)
}

export default PlayerTurnTracker
