import React, { useContext, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import UsersContext from '../../contexts/users'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useGetUserById from '../../hooks/useGetUserById'
import { DiceMessage } from '../../interfaces/chat'
import {
	DiceResult,
	ExpressionResult,
	RollResultArray,
} from '../../interfaces/dicebox'
import Avatar from '../Avatar'
import SmallUppercase from '../interface/text/SmallUppercase'
import MessageMenu from './MessageMenu'
// Import all dice components
import D10Icon from '../dice-icons/D10Icon'
import D12Icon from '../dice-icons/D12Icon'
import D20Icon from '../dice-icons/D20Icon'
import D4Icon from '../dice-icons/D4Icon'
import D6Icon from '../dice-icons/D6Icon'
import D8Icon from '../dice-icons/D8Icon'

interface SimpleDiceMessageProps {
	message: DiceMessage
	onDelete: () => void
	showSender?: boolean
	className?: string
	style?: React.CSSProperties
}

const DieDisplay = ({
	roll,
	dieSize,
	color,
}: {
	roll: { roll: number; critical?: 'success' | 'failure'; reroll?: boolean }
	dieSize: number
	color: string
}) => {
	const DieIcon = useMemo(() => {
		switch (dieSize) {
			case 4:
				return D4Icon
			case 6:
				return D6Icon
			case 8:
				return D8Icon
			case 10:
				return D10Icon
			case 12:
				return D12Icon
			case 20:
				return D20Icon
			default:
				return D6Icon
		}
	}, [dieSize])

	return (
		<div className='relative mx-1 inline-block'>
			<div className='absolute left-1/2 top-1/2 z-10 flex -translate-y-[50%] -translate-x-1/2 items-center justify-center whitespace-nowrap text-lg font-semibold text-white [text-shadow:_-1px_-1px_0_#000,_1px_-1px_0_#000,_-1px_1px_0_#000,_1px_1px_0_#000]'>
				{roll.roll}
				{/* {roll.critical === 'success' && '!'}
				{roll.critical === 'failure' && '✗'}
				{roll.reroll && 'ᴿ'} */}
			</div>
			<DieIcon className='h-10 w-10' fill={color} stroke='black' />
		</div>
	)
}

const DiceGroup = ({
	roll,
	color,
}: {
	roll: RollResultArray
	color: string
}) => {
	return (
		<div className='inline-flex flex-wrap items-center'>
			{roll.rolls.map((r, idx) => (
				<DieDisplay key={idx} roll={r} dieSize={roll.die.value} color={color} />
			))}
		</div>
	)
}

const SimpleDiceMessage: React.FC<SimpleDiceMessageProps> = React.memo(
	({ message, onDelete, className, style, showSender }) => {
		const { user } = useGetUserById(message.sender)
		const { usersState } = useContext(UsersContext)
		const senderColor =
			usersState.users.find(u => u.userId === message.sender)?.userSettings
				.color || '#ff0000'
		const avatarSrc = useGetAvatarSrc({
			userId: message.sender,
			assumedCharacterId: message.assumedCharacterId,
			fallbackAvatarId: message.fallbackAvatarId,
			disableAssumedCharacterLookup: false,
		})

		const name = useMemo(
			() =>
				user?.userProfile?.name ||
				`${message.fallbackName}${user ? '' : ' (removed)'}`,
			[user, message.fallbackName],
		)

		const containerClassName = useMemo(
			() =>
				twMerge(
					'max-w-4/6 message inline-block transition-all duration-200',
					showSender ? 'my-2' : 'mt-1',
					className,
				),
			[showSender, className],
		)

		const renderDiceResult = (results: DiceResult) => {
			if (results.type === 'expressionroll') {
				const expr = results as ExpressionResult
				return (
					<div className='flex flex-wrap items-center gap-2'>
						{expr.dice.map((die, idx) => (
							<React.Fragment key={idx}>
								{die.type === 'die' ? (
									<DiceGroup
										roll={die as RollResultArray}
										color={senderColor}
									/>
								) : (
									<span className='mx-1 text-white'>{die.value}</span>
								)}
							</React.Fragment>
						))}
						<span className='ml-2 self-end whitespace-nowrap text-white'>
							= {results.value}
						</span>
					</div>
				)
			} else if (results.type === 'die') {
				return (
					<div className='flex items-center'>
						<DiceGroup roll={results as RollResultArray} color={senderColor} />
						<span className='ml-2 self-end whitespace-nowrap text-white'>
							= {results.value}
						</span>
					</div>
				)
			}
			return 'Unknown roll type'
		}

		return (
			<MessageMenu onDelete={onDelete}>
				<div id={message._id} className={containerClassName} style={style}>
					<div className='flex'>
						{showSender && <Avatar src={avatarSrc} />}
						<div className={!showSender ? 'ml-9' : ''}>
							{showSender && (
								<SmallUppercase className='ml-2 mb-1'>{name}</SmallUppercase>
							)}
							<div className='flex-grow rounded-lg p-3'>
								<div className='font-mono text-sm'>
									{renderDiceResult(message.diceResults)}
								</div>
								{message.diceResults.successes > 0 ||
								message.diceResults.failures > 0 ? (
									<p className='mt-1 text-xs text-gray-400'>
										{message.diceResults.successes > 0 &&
											`${message.diceResults.successes} success${
												message.diceResults.successes !== 1 ? 'es' : ''
											}`}
										{message.diceResults.successes > 0 &&
											message.diceResults.failures > 0 &&
											' and '}
										{message.diceResults.failures > 0 &&
											`${message.diceResults.failures} failure${
												message.diceResults.failures !== 1 ? 's' : ''
											}`}
									</p>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</MessageMenu>
		)
	},
)

SimpleDiceMessage.displayName = 'SimpleDiceMessage'

export default SimpleDiceMessage
