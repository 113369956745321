interface SidebarHeadingProps {
	children: React.ReactNode
}

const SidebarHeading = ({ children }: SidebarHeadingProps) => {
	return (
		<h2 className='mb-2 text-xs font-bold uppercase tracking-widest text-gray-400'>
			{children}
		</h2>
	)
}

export default SidebarHeading
