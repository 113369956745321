import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useEffect, useState } from 'react'
import ToolButton from '../../interface/toolbar/ToolButton'

interface PDFPageControlsProps {
	pageNumber: number
	pagesCount: number
	onPageChange: (newPage: number, isAbsolute?: boolean) => void
}

const PDFPageControls = ({
	pageNumber,
	pagesCount,
	onPageChange,
}: PDFPageControlsProps) => {
	const [inputValue, setInputValue] = useState(pageNumber.toString())

	useEffect(() => {
		setInputValue(pageNumber.toString())
	}, [pageNumber])

	const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value)
	}

	const handleBlur = () => {
		const value = parseInt(inputValue)
		if (!isNaN(value) && value >= 1 && value <= pagesCount) {
			onPageChange(value, true)
		} else {
			setInputValue(pageNumber.toString())
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			const value = parseInt(inputValue)
			if (!isNaN(value) && value >= 1 && value <= pagesCount) {
				onPageChange(value, true)
			} else {
				setInputValue(pageNumber.toString())
			}
		}
	}

	return (
		<div className='flex items-center gap-2'>
			<ToolButton
				onClick={() => onPageChange(pageNumber - 1)}
				disabled={pageNumber <= 1}
				tooltip='Previous Page'
			>
				<ChevronLeft />
			</ToolButton>
			<input
				type='number'
				value={inputValue}
				onChange={handlePageInputChange}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
				min={1}
				max={pagesCount}
				className='w-12 rounded border border-gray-300 p-0.5 text-center text-sm text-black'
			/>
			<span className='whitespace-nowrap text-sm  text-gray-600'>
				/ {pagesCount}
			</span>
			<ToolButton
				onClick={() => onPageChange(pageNumber + 1)}
				disabled={pageNumber >= pagesCount}
				tooltip='Next Page'
			>
				<ChevronRight />
			</ToolButton>
		</div>
	)
}

export default PDFPageControls
