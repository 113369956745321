import React from 'react'
import { twMerge } from 'tailwind-merge'

type BaseTypeProps = React.HTMLAttributes<HTMLDivElement> & {
	children: React.ReactNode
	className?: string
	isOnDark?: boolean
}

const BaseType: React.FC<BaseTypeProps> = ({
	children,
	className,
	isOnDark = false,
	...props
}) => {
	return (
		<div
			className={twMerge(
				'text-base',
				isOnDark ? 'text-gray-200' : 'text-gray-700',
				className,
			)}
			{...props}
		>
			{children}
		</div>
	)
}

export default BaseType
