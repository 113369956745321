import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { IAsset } from '../../../../../shared/types/asset'
import { IDocument } from '../../../../../shared/types/document'
import { ISceneValues } from '../../../../../shared/types/scene'
import GameContext from '../../../contexts/game'
import useRemoveAsset from '../../../hooks/useRemoveAsset'
import AssetManager from '../../AssetManager'
import EffectsOverlaySelector from '../../interface/EffectsOverlaySelector'
import ActivateSceneButton from '../ActivateSceneButton'
import SceneName from './SceneName'
import SceneSecret from './SceneSecret'
import SceneSubtitle from './SceneSubtitle'

type Props = {
	doc: IDocument
}

const SceneEditor = ({ doc }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { game } = useContext(GameContext)
	const { removeAsset } = useRemoveAsset()
	const form = useForm<ISceneValues>({
		defaultValues: doc.values,
	})

	const values = useWatch({
		control: form.control,
	})

	const handleUpdate = () => {
		console.log('handleUpdate', values)
		const updatedDocument = {
			...doc,
			version: doc.version + 1,
			values: {
				...doc.values,
				...values,
				coverId: values.coverId || doc.values.coverId,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument,
			},
		})
	}

	useEffect(handleUpdate, [values])

	const handleAssetChange = (asset?: IAsset) => {
		try {
			if (!asset) {
				return
			}

			form.setValue('coverId', asset._id)
		} catch (error) {
			console.error('Failed to update document with new asset:', error)
			if (asset?._id) {
				removeAsset({
					variables: {
						gameId: game._id,
						assetId: asset._id,
					},
				})
			}
		}
	}

	return (
		<FormProvider {...form}>
			<div>
				<div className='relative'>
					<AssetManager
						className='mx-auto max-w-xl'
						assetId={doc.values.coverId}
						onAssetChange={handleAssetChange}
					/>
					{doc.values.coverId && (
						<div className='absolute bottom-4 left-4 z-20'>
							<ActivateSceneButton sceneId={doc._id} />
						</div>
					)}
				</div>

				<div className='p-4'>
					<SceneName doc={doc} />
					<SceneSubtitle doc={doc} />
					<SceneSecret doc={doc} />
					<EffectsOverlaySelector doc={doc} />
				</div>
			</div>
		</FormProvider>
	)
}

export default SceneEditor
