export const MIN_WINDOW_WIDTH = 300
export const MIN_WINDOW_HEIGHT = 400

export const WINDOW_SIZE_MAP = {
	small: { width: MIN_WINDOW_WIDTH, height: MIN_WINDOW_HEIGHT },
	medium: { width: MIN_WINDOW_WIDTH * 1.4, height: MIN_WINDOW_HEIGHT * 1.6 },
	large: { width: MIN_WINDOW_WIDTH * 3, height: MIN_WINDOW_HEIGHT * 3 },
} as const

export type WindowSize = keyof typeof WINDOW_SIZE_MAP
