// the 'right' input
import { forwardRef } from 'react'
import Loader from 'react-loader-spinner'
import { twMerge } from 'tailwind-merge'

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	loading?: boolean
	inputClassName?: string
	error?: string
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
	(
		{ className = '', loading = false, inputClassName, error, ...props },
		ref,
	) => {
		return (
			<div className={twMerge('flex pt-1', className)}>
				<div className='flex-1'>
					<input
						type='text'
						ref={ref}
						className={twMerge(
							'block w-full flex-1 rounded-md bg-gray-800 py-2 pl-3 pr-0 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500 sm:text-sm',
							inputClassName,
							loading && 'pr-10',
							props.disabled && 'cursor-not-allowed opacity-50',
						)}
						disabled={loading || props.disabled}
						{...props}
					/>

					{loading && (
						<div className='-ml-8 flex items-center'>
							<Loader type='Oval' color='#666' height={20} width={20} />
						</div>
					)}
				</div>

				{error && <p className='mt-2 text-sm text-red-400'>{error}</p>}
			</div>
		)
	},
)

export default Input
