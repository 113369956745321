import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { IGameCard } from '../../interfaces/game'
import GameMenuContent from './GameMenuContent'

type Props = {
	game: IGameCard
	refetch?: () => void
	children: React.ReactElement
	show: boolean
	onOpen?: () => void
	onClose?: () => void
}

const GameMenu = ({
	game,
	refetch,
	children,
	show,
	onOpen,
	onClose,
}: Props) => {
	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<DropdownMenu.Root
			onOpenChange={open => {
				setIsOpen(open)
				if (open) {
					onOpen?.()
				} else {
					onClose?.()
				}
			}}
		>
			<DropdownMenu.Trigger
				className={twMerge(
					'absolute bottom-4 right-4 z-10 transition-opacity duration-300',
					show || isOpen ? 'opacity-100' : 'opacity-0',
				)}
			>
				{React.cloneElement(React.Children.only(children), { isOpen })}
			</DropdownMenu.Trigger>
			<GameMenuContent game={game} refetch={refetch} />
		</DropdownMenu.Root>
	)
}

export default GameMenu
