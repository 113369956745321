import { useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { BookResource } from '../../../../shared/types/resources'
import PdfViewerContext from '../../contexts/book'
import GameContext from '../../contexts/game'
import { ResourceContext } from '../../contexts/resources'
import reducer from '../../reducers/book'
import { getInitialState } from '../../utils/getInitialBookState'
import PDFViewer from '../resources/PDFViewer'

const BookPopout = () => {
	const { game } = useContext(GameContext)
	const { resources } = useContext(ResourceContext)
	const { bookId } = useParams()
	const page = new URLSearchParams(window.location.search).get('page')
	const book = game.books.byId[bookId]
	const resource = useMemo(
		() => resources[book.resourceId],
		[resources, book.resourceId],
	) as BookResource | undefined

	const name = resource?.name
	const [bookState, bookDispatch] = useReducer(reducer, getInitialState(bookId))
	const platformName = 'Realms Inc'

	// Set window title and styling
	useEffect(() => {
		if (name) {
			document.title = `${name} - ${game.title} — ${platformName}`
		} else {
			document.title = platformName
		}

		document.body.className = 'document'
		document.body.style.backgroundColor = 'black'
		document.documentElement.style.backgroundColor = 'black'

		return () => {
			document.title = platformName
			document.body.className = ''
		}
	}, [name, game.title])

	// Set display options when resource loads
	useEffect(() => {
		if (resource?.metadata?.displayOptions) {
			bookDispatch({
				type: 'SET_DISPLAY_OPTIONS',
				payload: resource.metadata.displayOptions,
			})
		}
	}, [resource])

	if (resource === undefined) return null

	return (
		<PdfViewerContext.Provider value={{ bookState, bookDispatch }}>
			<PDFViewer resource={resource} page={page} isGame={true} />
		</PdfViewerContext.Provider>
	)
}

export default BookPopout
