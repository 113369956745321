import React, { useEffect, useMemo, useState } from 'react'
import { DiceMessage, IMessage } from '../../interfaces/chat'
import ChatbotMessage from './ChatBotMessage'
import Document from './Document'
import MediaMessage from './MediaMessage'
import SimpleDiceMessage from './SimpleDiceMessage'
import SystemMessage from './SystemMessage'
import TextMessage from './TextMessage'
import { FADE_TIME, LEGACY_MESSAGE_TIMESTAMP } from './constants'

type Props = {
	message: IMessage
	onDelete: () => void
	showSender: boolean
	noFade?: boolean
}

const isDiceMessage = (message: IMessage): message is DiceMessage => {
	return message.type === 'dice' && 'diceResults' in message
}

const Message: React.FC<Props> = React.memo(props => {
	const messageTime = props.message.createdAt || LEGACY_MESSAGE_TIMESTAMP
	const [age, setAge] = useState(Date.now() - messageTime)
	const [shouldShow, setShouldShow] = useState(true)
	const [shouldFade, setShouldFade] = useState(false)

	useEffect(() => {
		if (props.noFade) {
			setShouldShow(true)
			setShouldFade(false)
			return
		}

		if (age >= FADE_TIME) {
			setShouldShow(false)
			return
		}

		const timeToFade = FADE_TIME - age
		const fadeTimer = setTimeout(() => {
			setShouldFade(true)
			setTimeout(() => setShouldShow(false), 1000)
		}, timeToFade)

		const ageTimer = setInterval(() => {
			const newAge = Date.now() - messageTime
			setAge(newAge)
		}, 1000)

		return () => {
			clearTimeout(fadeTimer)
			clearInterval(ageTimer)
		}
	}, [messageTime, age, props.noFade])

	const messageClass = `transition-opacity duration-1000 ${
		shouldFade ? 'opacity-0' : 'opacity-100'
	}`

	const messageContent = useMemo(() => {
		switch (props.message.type) {
			case 'chatbot':
				return (
					<ChatbotMessage
						{...props}
						className={messageClass}
						showSender={true}
					/>
				)

			case 'dice':
				if (isDiceMessage(props.message)) {
					return (
						<SimpleDiceMessage
							{...props}
							className={messageClass}
							message={props.message}
							showSender={true}
						/>
					)
				}
				return null

			case 'message':
				return (
					<TextMessage {...props} className={messageClass} showSender={true} />
				)

			case 'media':
				return <MediaMessage {...props} className={messageClass} />

			case 'system':
				return <SystemMessage {...props} className={messageClass} />

			case 'document':
				return <Document {...props} className={messageClass} />

			case 'folder':
				return <div className={messageClass}>ehm folder? fix this</div>

			default:
				return null
		}
	}, [props, messageClass])

	if (!shouldShow) return null

	return messageContent
})

Message.displayName = 'Message'

export default Message
