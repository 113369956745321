import axios from 'axios'
import ObjectId from 'bson-objectid'
import { useContext } from 'react'
import AuthContext from '../contexts/auth'

// Add interface for upload result
export interface FileUploadResult {
	_id: string
	name: string
	fileurl: string
	filesize: number
	filetype: string
	width: number
	height: number
	creator: string
	createdAt: string
	updatedAt: string
	usedInGames: string[]
	thumbnailurl: string
}

const useFileUpload = () => {
	const { authState } = useContext(AuthContext)

	const uploadFile = async (
		file: File,
		onProgress?: (percent: number) => void,
	): Promise<FileUploadResult> => {
		const ACCEPTED_MIME_TYPES = [
			'image/jpeg',
			'image/png',
			'image/gif',
			'image/bmp',
			'image/webp',
			'video/mp4',
			'video/webm',
			'application/pdf',
		]

		if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
			throw new Error('Unsupported file type')
		}

		const userId = authState.userId || 'anonymous'

		const presignedUrlResponse = await fetch('/api/presigned-url', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: file.name,
				contentType: file.type,
			}),
		})
		const { url: presignedUrl, key } = await presignedUrlResponse.json()

		await axios.put(presignedUrl, file, {
			headers: {
				'Content-Type': file.type,
			},
			onUploadProgress: progressEvent => {
				if (progressEvent.total) {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total,
					)
					onProgress?.(percentCompleted)
				}
			},
		})

		const result = {
			_id: ObjectId().toHexString(),
			name: file.name,
			fileurl: `/files/${key}`,
			filesize: file.size,
			filetype: file.type,
			width: 0,
			height: 0,
			creator: userId,
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			usedInGames: [],
			thumbnailurl: '',
		}

		return result
	}

	return uploadFile
}

export default useFileUpload
