import React from 'react'

interface D12IconProps extends React.SVGProps<SVGSVGElement> {
	fill?: string
	stroke?: string
}

const D12Icon: React.FC<D12IconProps> = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	...props
}) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 595.28 595.28'
		{...props}
	>
		<path
			d='M45.49,231.12v136.23c0,8.98,2.84,17.73,8.12,24.99l80.07,110.21c5.28,7.26,12.72,12.67,21.26,15.45l129.56,42.1c8.54,2.77,17.74,2.77,26.28,0l129.56-42.1c8.54-2.77,15.98-8.18,21.26-15.45l80.07-110.21c5.28-7.26,8.12-16.01,8.12-24.99v-136.23c0-8.98-2.84-17.73-8.12-24.99l-80.07-110.21c-5.28-7.26-12.72-12.67-21.26-15.45l-129.56-42.1c-8.54-2.77-17.74-2.77-26.28,0l-129.56,42.1c-8.54,2.77-15.98,8.18-21.26,15.45l-80.07,110.21c-5.28,7.26-8.12,16.01-8.12,24.99Z'
			fill={fill}
			stroke={stroke}
			strokeWidth='22.11'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<polygon
			points='297.64 128.6 135.36 246.5 197.35 437.27 397.93 437.27 459.91 246.5 297.64 128.6'
			fill='none'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='297.64'
			y1='42.43'
			x2='297.64'
			y2='128.6'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='53.41'
			y1='219.87'
			x2='135.36'
			y2='246.5'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='146.7'
			y1='506.98'
			x2='197.35'
			y2='437.27'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='541.87'
			y1='219.87'
			x2='459.91'
			y2='246.5'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='448.58'
			y1='506.98'
			x2='397.93'
			y2='437.27'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default D12Icon
