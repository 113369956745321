import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { twMerge } from 'tailwind-merge'
import { useQueue } from '../../contexts/queue'
import Card from '../pages/elements/Card'

interface ResourceDropzoneProps {
	children: React.ReactNode
	cardClassName?: string
	cardInnerClassName?: string
	style?: any
}

const ResourceDropzone = ({
	children,
	cardClassName,
	cardInnerClassName,
	style,
}: ResourceDropzoneProps) => {
	const [fileDragOver, setFileDragOver] = useState<boolean>(false)
	const { addToQueue } = useQueue()

	const dropHandler = (files: File[]) => {
		setFileDragOver(false)
		try {
			addToQueue(files)
		} catch (error) {
			alert('Error processing file(s)')
		}
	}

	return (
		<div className='relative'>
			<Dropzone
				onDragEnter={e => {
					if (e.target === e.currentTarget) {
						setFileDragOver(true)
					}
				}}
				onDragLeave={e => {
					if (e.target === e.currentTarget) {
						setFileDragOver(false)
					}
				}}
				onDrop={dropHandler}
				accept='.pdf'
				noClick
			>
				{({ getRootProps, isDragActive }) => {
					const isOver = isDragActive || fileDragOver
					return (
						<Card
							{...getRootProps()}
							style={{
								...style,
								marginLeft: style?.margin,
								marginRight: style?.margin,
							}}
							className={twMerge(
								' mt-0 cursor-default p-0 transition-all duration-500',
								isOver && 'dark:bg-blue-950 bg-blue-100',
								cardClassName,
								'overflow-visible',
							)}
							innerClassName={twMerge('max-w-none', cardInnerClassName)}
						>
							{children}
						</Card>
					)
				}}
			</Dropzone>
		</div>
	)
}

export default ResourceDropzone
