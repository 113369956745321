import { Combobox } from '@headlessui/react'
import { Search, X } from 'lucide-react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

export interface SearchSuggestion {
	type: 'title' | 'author' | 'publisher' | 'game' | 'ruleSystem' | 'tag'
	value: string
}

interface SearchInputProps
	extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	id?: string
	suggestions?: SearchSuggestion[]
	onSuggestionClick?: (suggestion: SearchSuggestion) => void
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	value: string
}

const SearchInput: React.FC<SearchInputProps> = ({
	id = 'search',
	suggestions = [],
	onSuggestionClick,
	onChange,
	value,
	className,
	...props
}) => {
	const getSuggestionLabel = (type: SearchSuggestion['type']) => {
		switch (type) {
			case 'title':
				return 'Title'
			case 'author':
				return 'Author'
			case 'publisher':
				return 'Publisher'
			case 'game':
				return 'Game'
			case 'ruleSystem':
				return 'Rule System'
			case 'tag':
				return 'Tag'
			default:
				return type
		}
	}

	const handleClear = () => {
		onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
	}

	return (
		<Combobox
			value={value}
			onChange={(newValue: string) => {
				const suggestion = suggestions.find(s => s.value === newValue)
				if (suggestion) {
					onSuggestionClick?.(suggestion)
				}
				onChange({
					target: { value: newValue },
				} as React.ChangeEvent<HTMLInputElement>)
			}}
		>
			<div className='relative'>
				{!value && (
					<Search className='absolute left-2 top-1/2 z-10 h-4 w-4 -translate-y-1/2 text-gray-500' />
				)}
				{value && (
					<button
						onClick={handleClear}
						className='absolute left-2 top-1/2 z-10 -translate-y-1/2 rounded p-0.5 text-gray-500 hover:bg-gray-600 hover:text-gray-300'
						type='button'
					>
						<X className='h-4 w-4' />
					</button>
				)}
				<Combobox.Input
					id={id}
					placeholder='Search books...'
					className={twMerge(
						'w-full rounded-md bg-gray-700 px-3 py-2 pl-9 text-sm text-gray-100 placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-600',
						className,
					)}
					onChange={e => onChange(e)}
					displayValue={(val: string) => val}
					{...props}
				/>
				{suggestions.length > 0 && (
					<Combobox.Options className='absolute left-0 right-0 top-[calc(100%+4px)] z-[100] max-h-[300px] w-full min-w-[300px] overflow-auto rounded-md border border-gray-700 bg-gray-800 p-1 shadow-lg'>
						{suggestions.map(suggestion => (
							<Combobox.Option
								key={`${suggestion.type}-${suggestion.value}`}
								value={suggestion.value}
								className={({ active }) =>
									`cursor-pointer text-sm text-gray-100 ${
										active ? 'bg-gray-700' : ''
									}`
								}
							>
								<div className='flex items-center justify-between px-3 py-2'>
									<span>{suggestion.value}</span>
									<span className='ml-2 text-xs text-gray-400'>
										{getSuggestionLabel(suggestion.type)}
									</span>
								</div>
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	)
}

export default SearchInput
