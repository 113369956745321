import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../FormComponents/Button'
import ModalWindow from '../../../window/ModalWindow'

interface EditFieldModalProps {
	title: string
	fieldName: string
	initialValue: string
	onSave: (value: string) => void
	onClose: () => void
}

const EditFieldModal: FC<EditFieldModalProps> = ({
	title,
	fieldName,
	initialValue,
	onSave,
	onClose,
}) => {
	const [isOpen, setIsOpen] = useState(true)
	const { register, handleSubmit } = useForm({
		defaultValues: {
			[fieldName]: initialValue,
		},
	})

	const handleClose = () => {
		setIsOpen(false)
		setTimeout(onClose, 500)
	}

	const onSubmit = (data: any) => {
		onSave(data[fieldName])
		handleClose()
	}

	return (
		<ModalWindow
			id={`edit-${fieldName}-modal`}
			title={title}
			size='small'
			onClose={handleClose}
			isOpen={isOpen}
			footerChildren={
				<div className='flex justify-end gap-2'>
					<Button
						type='button'
						onClick={handleClose}
						className='mt-0 bg-gray-800 hover:bg-gray-700'
					>
						Cancel
					</Button>
					<Button type='submit' form='edit-field-form' className='mt-0'>
						Save
					</Button>
				</div>
			}
		>
			<form id='edit-field-form' onSubmit={handleSubmit(onSubmit)}>
				<input
					{...register(fieldName)}
					type={fieldName === 'email' ? 'email' : 'text'}
					className='block w-full appearance-none rounded-md border border-gray-700 bg-gray-800 px-3 py-2 text-white placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
					autoFocus
				/>
			</form>
		</ModalWindow>
	)
}

export default EditFieldModal
