import { gql, useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { IGame } from '../../../interfaces/game'
import SlideoverPanel from '../../SlideoverPanel'
import { GET_ALL_GAMES } from './Games'

const SET_GAME_TEMPLATE = gql`
	mutation SetGameTemplate($gameId: ID!, $isTemplate: Boolean!) {
		setGameTemplate(gameId: $gameId, isTemplate: $isTemplate) {
			_id
			isTemplate
		}
	}
`

interface GameDetailProps {
	game?: IGame
	onClose?: () => void
	open?: boolean
}

export default function AdminGameDetail({
	game,
	onClose,
	open = false,
}: GameDetailProps) {
	const { id } = useParams()

	const [setGameTemplate] = useMutation(SET_GAME_TEMPLATE, {
		refetchQueries: [{ query: GET_ALL_GAMES }],
	})

	const { data, loading, error } = useQuery(GET_ALL_GAMES, {
		skip: !!game,
	})

	if (loading) return <div>Loading...</div>
	if (error) return <div>Error loading game details</div>

	const gameData = game || data?.adminGames?.find(g => g._id === id)
	if (!gameData) return <div>Game not found</div>

	const handleTemplateToggle = async (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const newValue = e.target.checked
		const action = newValue
			? 'mark this game as a template'
			: 'remove this game from templates'

		if (window.confirm(`Are you sure you want to ${action}?`)) {
			try {
				await setGameTemplate({
					variables: {
						gameId: gameData._id,
						isTemplate: newValue,
					},
				})
			} catch (err) {
				console.error('Error toggling template status:', err)
				alert('Failed to update template status')
				// Reset checkbox to original value
				e.target.checked = !newValue
			}
		} else {
			// User cancelled - reset checkbox
			e.target.checked = !newValue
		}
	}

	const content = (
		<div className='space-y-6'>
			<div>
				<h3 className='text-lg font-medium leading-6 text-gray-900'>
					{gameData.title}
				</h3>
				<p className='mt-1 text-sm text-gray-500'>{gameData.description}</p>
				<div className='mt-4 flex items-center gap-4'>
					<a
						href={`/game/${gameData._id}`}
						className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
					>
						Go to Game
					</a>
					<div className='flex items-center gap-2'>
						<input
							type='checkbox'
							id='isTemplate'
							checked={gameData.isTemplate}
							onChange={handleTemplateToggle}
							className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
						/>
						<label htmlFor='isTemplate' className='text-sm text-gray-700'>
							Template Game
						</label>
					</div>
				</div>
			</div>

			<div className='border-t border-gray-200 pt-5'>
				<dl className='grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2'>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Creator</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{gameData.creator.name}
						</dd>
						<dd className='text-sm text-gray-500'>{gameData.creator.email}</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Players</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{gameData.users.length}
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Created</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{new Date(gameData.createdAt).toLocaleDateString()}
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Last Updated</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{new Date(gameData.updatedAt).toLocaleDateString()}
						</dd>
					</div>
				</dl>
			</div>
		</div>
	)

	return (
		<SlideoverPanel
			open={open}
			onClose={onClose || (() => {})}
			title='Game Details'
		>
			{content}
		</SlideoverPanel>
	)
}
