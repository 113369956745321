import ObjectID from 'bson-objectid'
import { FC, useRef, useState } from 'react'
import { BookResource } from '../../../../shared/types/resources'
import { ICollection } from '../../../../shared/types/system'
import { useGame } from '../../hooks/useGame'
import useProcessBook from '../../hooks/useProcessBook'
import Button from '../FormComponents/Button'
import PDFSheetConfigModal from './PDFSheetConfigModal'
import PDFSheetRow from './PDFSheetRow'

interface Props {
	setEnableClickOutside?: (enable: boolean) => void
}

const SettingsPDFSheets: FC<Props> = ({ setEnableClickOutside }) => {
	const { game, dispatch } = useGame()
	const [configuring, setConfiguring] = useState<string | null>(null)
	const [configuringResource, setConfiguringResource] =
		useState<BookResource | null>(null)
	const [isUploading, setIsUploading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const fileInputRef = useRef<HTMLInputElement>(null)
	const { processBook } = useProcessBook()

	const handleClick = () => {
		setError(null)
		fileInputRef.current?.click()
	}

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0]
		if (!file) return

		if (file.type !== 'application/pdf') {
			setError('Please select a PDF file')
			return
		}

		try {
			setIsUploading(true)
			setError(null)
			console.log('Processing PDF:', file.name)
			const { resource } = await processBook(file, {
				generateEmbeddings: false,
				generateSummary: false,
				skipAIMetadata: true,
				resourceType: 'sheet',
			})
			console.log('Processed PDF:', {
				resourceId: resource._id,
				metadata: resource.metadata,
				formFields: resource.metadata?.pdf?.formFields,
			})

			const formFields = resource.metadata?.pdf?.formFields
			if (!formFields || formFields.length === 0) {
				alert(
					'This PDF does not contain any form fields. Only form-fillable PDFs can be used as PDF sheets.',
				)
				return
			}

			setConfiguring(resource._id)
			setConfiguringResource(resource)
			setEnableClickOutside?.(false)
		} catch (error) {
			console.error('Error uploading PDF:', error)
			setError(error.message || 'Failed to upload PDF. Please try again.')
		} finally {
			setIsUploading(false)
			if (fileInputRef.current) {
				fileInputRef.current.value = ''
			}
		}
	}

	const handleSaveConfig = async (collection: ICollection) => {
		try {
			setError(null)
			if (configuring && configuringResource) {
				const formFields = configuringResource.metadata?.pdf?.formFields
				if (!formFields || formFields.length === 0) {
					setError(
						'This PDF does not contain any form fields. Only form-fillable PDFs can be used as PDF sheets.',
					)
					return
				}

				dispatch({
					type: 'ADD_PDF_SHEET',
					payload: {
						id: new ObjectID().toHexString(),
						resourceId: configuring,
						collection,
					},
				})
				setConfiguring(null)
				setConfiguringResource(null)
			}
			setEnableClickOutside?.(true)
		} catch (error) {
			console.error('Error saving PDF configuration:', error)
			setError('Failed to save PDF configuration. Please try again.')
		}
	}

	const handleDelete = async (id: string) => {
		if (
			window.confirm(
				'Are you sure you want to delete this PDF sheet? WARNING:This will also delete all documents created using this sheet.',
			)
		) {
			try {
				setError(null)
				const sheet = game.pdfSheets?.byId[id]
				if (sheet) {
					// First delete all documents of this collection type
					const documentsToDelete = Object.values(game.documents.byId)
						.filter(doc => doc.type === sheet.collection.type)
						.map(doc => doc._id)

					// Delete the documents first
					documentsToDelete.forEach(docId => {
						dispatch({
							type: 'DELETE_DOCUMENT',
							payload: { documentId: docId },
						})
					})

					// Then delete the PDF sheet
					dispatch({
						type: 'REMOVE_PDF_SHEET',
						payload: { id },
					})
				}
			} catch (error) {
				console.error('Error deleting PDF sheet:', error)
				setError(
					'Failed to delete PDF sheet and associated documents. Please try again.',
				)
			}
		}
	}

	const handleCloseModal = () => {
		setError(null)
		setConfiguring(null)
		setConfiguringResource(null)
		setEnableClickOutside?.(true)
	}

	return (
		<div className='space-y-4'>
			{error && (
				<div className='rounded bg-red-900/50 p-2 text-sm text-red-200'>
					{error}
				</div>
			)}

			{/* List of existing PDF sheets */}
			{game.pdfSheets && (
				<div className='space-y-2'>
					{Object.entries(game.pdfSheets.byId || {}).map(([id, sheet]) => (
						<PDFSheetRow
							key={id}
							id={id}
							resourceId={sheet.resourceId}
							onDelete={handleDelete}
							collection={sheet.collection}
						/>
					))}
				</div>
			)}

			{/* Add PDF button */}
			<div>
				<input
					type='file'
					ref={fileInputRef}
					onChange={handleFileChange}
					accept='.pdf'
					className='hidden'
				/>
				<Button
					onClick={handleClick}
					className='bg-gray-800'
					disabled={isUploading}
				>
					{isUploading ? 'Uploading...' : 'Add a PDF'}
				</Button>

				<p className='mt-4 text-sm text-gray-500'>
					PDF sheets let form-fillable PDFs be used as record sheets (e.g.
					character sheets). These can be either in addition to, or in place of
					a full system installation. Note: PDF scripting is not supported.
				</p>
			</div>

			{/* Configuration Modal for new PDFs */}
			{configuring && (
				<PDFSheetConfigModal
					onClose={handleCloseModal}
					onSave={handleSaveConfig}
					resource={configuringResource}
				/>
			)}
		</div>
	)
}

export default SettingsPDFSheets
