import { useContext } from 'react'
import { BookResource } from '../../../../shared/types/resources'
import GameContext from '../../contexts/game'
import { ResourceContext } from '../../contexts/resources'
import useItemFilter from '../../hooks/UseItemFilter'
import BookCover from '../pages/books/BookCover'

type Props = {
	bookId: string
}

const Book = ({ bookId }: Props) => {
	const { game } = useContext(GameContext)
	const { resources } = useContext(ResourceContext)
	const book = game.books.byId[bookId]
	const resource = resources[book.resourceId] as BookResource
	const { checkAccess } = useItemFilter()
	const hasAccess = checkAccess(book)

	if (!resource || !hasAccess) {
		return null
	}

	return <BookCover resource={resource} book={book} onLightBackground={true} />
}

export default Book
