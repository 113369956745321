import React from 'react'

interface D10IconProps extends React.SVGProps<SVGSVGElement> {
	fill?: string
	stroke?: string
}

const D10Icon: React.FC<D10IconProps> = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	...props
}) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 595.28 595.28'
		{...props}
	>
		<rect
			x='101.77'
			y='101.77'
			width='391.74'
			height='391.74'
			rx='28.35'
			ry='28.35'
			transform='translate(297.64 -123.29) rotate(45)'
			fill={fill}
			stroke={stroke}
			strokeWidth='22.11'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<polyline
			points='156.05 270.24 297.64 345.68 297.64 556.65'
			fill='none'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<polyline
			points='556.87 297.15 439.23 270.24 297.64 345.68'
			fill='none'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<polyline
			points='156.05 270.24 297.64 36.27 439.23 270.24'
			fill='none'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='38.41'
			y1='297.15'
			x2='156.05'
			y2='270.24'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default D10Icon
