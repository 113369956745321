import { useQuery } from '@apollo/client'
import React, { useReducer } from 'react'
import { ResourceContext } from '../contexts/resources'
import { GET_RESOURCES_BY_USER } from '../graphql/resources'
import { resourceReducer } from '../reducers/resource'

type Props = {
	userId?: string
	children: React.ReactNode
}

const ResourcesProvider = ({ userId, children }: Props) => {
	const [resources, dispatchResource] = useReducer(resourceReducer, {})

	const { loading, error } = useQuery(GET_RESOURCES_BY_USER, {
		variables: { userId },
		skip: !userId,
		fetchPolicy: 'no-cache', // cannot figure out why caching won't work for this query
		onCompleted: data => {
			const resources = data.getResourcesByUser
			dispatchResource({
				type: 'ADD_RESOURCES',
				payload: {
					resources,
				},
			})
		},
		onError: error => {
			console.error('Error loading resources', error)
		},
	})

	if (!userId) {
		return (
			<ResourceContext.Provider value={{ resources: {}, dispatchResource }}>
				{children}
			</ResourceContext.Provider>
		)
	}

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		console.error(error)
		return <div>Error...</div>
	}

	return (
		<ResourceContext.Provider value={{ resources, dispatchResource }}>
			{children}
		</ResourceContext.Provider>
	)
}

export default ResourcesProvider
