import React from 'react'
import { BookResource } from '../../../../../shared/types/resources'
import BookCover from './BookCover'
import { BooksResources } from './BooksPage' // Adjust path if needed

const CELL_MIN_WIDTH = 250
const CELL_ASPECT_RATIO = { width: 9, height: 12 }
const CELL_HEIGHT =
	CELL_MIN_WIDTH * (CELL_ASPECT_RATIO.height / CELL_ASPECT_RATIO.width)

interface BookGridProps {
	resources: BooksResources
	selectMode?: boolean
	selectedIds?: string[]
	onSelect?: (id: string, selected: boolean) => void
}

const BookGrid: React.FC<BookGridProps> = ({
	resources,
	selectMode,
	selectedIds = [],
	onSelect,
}) => {
	return (
		<ul
			className='mx-auto grid gap-x-0 gap-y-4 p-3 transition-all duration-500'
			style={{
				gridTemplateColumns: `repeat(auto-fit, minmax(${CELL_MIN_WIDTH}px, 1fr))`,
				gridAutoRows: `${CELL_HEIGHT}px`,
			}}
		>
			{Object.entries(resources).map(([id, resource]) => {
				const isSelected = selectedIds.includes(resource._id)
				return (
					<li key={id} className='relative flex flex-col justify-end'>
						{selectMode && (
							<input
								type='checkbox'
								checked={isSelected}
								onChange={() => onSelect && onSelect(resource._id, !isSelected)}
								className='absolute top-2 right-2 z-10'
							/>
						)}
						<BookCover resource={resource as BookResource} />
					</li>
				)
			})}
		</ul>
	)
}

export default BookGrid
