import { Button } from '@headlessui/react'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../../hooks/useTheme'
import Tooltip from '../../Tooltip'

interface ToolButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string
	tooltip?: string
	isOpen?: boolean
	disabled?: boolean
}

const ToolButton = forwardRef<HTMLButtonElement, ToolButtonProps>(
	({ className, tooltip, isOpen, disabled, ...rest }, ref) => {
		const [showTooltip, setShowTooltip] = useState(false)
		const { primary } = useTheme()
		const buttonRef = useRef<HTMLButtonElement>(null)

		useEffect(() => {
			if (typeof ref === 'function') {
				ref(buttonRef.current)
			} else if (ref) {
				ref.current = buttonRef.current
			}
		}, [ref])

		return (
			<>
				<Button
					className={twMerge(
						'pointer-events-auto flex h-12 w-12 items-center justify-center overflow-hidden rounded-full p-1 text-gray-800 hover:bg-gray-200',
						className,
						disabled && 'cursor-not-allowed opacity-40 hover:bg-transparent',
					)}
					onMouseEnter={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
					ref={buttonRef}
					style={{
						border: isOpen ? `3px solid ${primary}` : '',
					}}
					disabled={disabled}
					{...rest}
				/>
				{tooltip && (
					<Tooltip open={showTooltip} anchor={buttonRef} placement='bottom'>
						{tooltip}
					</Tooltip>
				)}
			</>
		)
	},
)

ToolButton.displayName = 'ToolButton'

export default ToolButton
