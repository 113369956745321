import { useContext, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import Documents from './components/Documents'
import EventManager from './components/EventManager'
import ResourcesProvider from './components/ResourcesProvider'
import Sockets from './components/Sockets'
import ToolsManager from './components/ToolsManager'
import Users from './components/Users'
import NoDocument from './components/documentApp/NoDocument'
import DragAndDropManager from './components/draganddrop/DragAndDropManager'
import SheetPopout from './components/sheet/SheetPopout'
import Windows from './components/window/Windows'
import { initialGameState } from './config'
import AuthContext from './contexts/auth'
import GameContext from './contexts/game'
import { SocketContext, socket } from './contexts/socket'
import GameReducer from './reducers/game'

const SheetApp = () => {
	const [game, dispatch] = useReducer(GameReducer, initialGameState)
	const { authState } = useContext(AuthContext)
	const { sheetId } = useParams()
	const document = game.documents.byId[sheetId]
	// @ts-ignore
	const creatorId = game.creator._id

	if (!authState.userId) {
		console.error('Error: Authstate user id missing...', authState)
		return (
			<div className='text-white'>
				Auth state User ID not found... Try to refresh or sign out and in again.
			</div>
		)
	}

	if (!sheetId) return <div className='text-white'>No sheet ID found...</div>

	return (
		<GameContext.Provider value={{ game, dispatch }}>
			<SocketContext.Provider value={socket}>
				<Sockets>
					<ResourcesProvider userId={creatorId}>
						<EventManager>
							<DragAndDropManager>
								<ToolsManager>
									<Windows>
										<Documents>
											<Users>
												{!document && <NoDocument />}
												{!game._id && (
													<div className='text-white'>Game not found...</div>
												)}
												{document && <SheetPopout />}
											</Users>
										</Documents>
									</Windows>
								</ToolsManager>
							</DragAndDropManager>
						</EventManager>
					</ResourcesProvider>
				</Sockets>
			</SocketContext.Provider>
		</GameContext.Provider>
	)
}

export default SheetApp
