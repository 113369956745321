import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import GameContext from '../../contexts/game'
import Label from '../FormComponents/Label'
import Input from '../Input'

const GameName = () => {
	const { game, dispatch } = useContext(GameContext)
	const { register, watch } = useFormContext()

	// Watch for changes and update the game title
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === 'title' && value.title !== undefined) {
				dispatch({
					type: 'SET_GAME_TITLE',
					payload: {
						title: value.title,
					},
				})
			}
		})

		return () => subscription.unsubscribe()
	}, [watch, dispatch])

	return (
		<div className='center mt-4 flex align-middle'>
			<Label htmlFor='title' className='mt-3 flex-grow'>
				Name
			</Label>
			<Input
				className='w-80'
				placeholder='Game Name...'
				defaultValue={game.title}
				{...register('title')}
			/>
		</div>
	)
}

export default GameName
