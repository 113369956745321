import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { BookOpen, Minus, Plus } from 'lucide-react'
import { useState } from 'react'
import CheckboxItem from '../../dropdownmenu/CheckboxItem'
import MenuContent from '../../dropdownmenu/MenuContent'
import Separator from '../../dropdownmenu/Separator'
import ToolButton from '../../interface/toolbar/ToolButton'
import { ScaleModeType, SpreadModeType } from '../types/pdfViewerTypes'

interface PDFDisplayOptionsButtonProps {
	scale: number
	scaleMode: ScaleModeType
	spreadMode: SpreadModeType
	onZoomIn: () => void
	onZoomOut: () => void
	onScaleModeChange: (mode: ScaleModeType | null) => void
	onSpreadModeChange: (mode: SpreadModeType) => void
}

const PDFDisplayOptionsButton = ({
	scale,
	scaleMode,
	spreadMode,
	onZoomIn,
	onZoomOut,
	onScaleModeChange,
	onSpreadModeChange,
}: PDFDisplayOptionsButtonProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const onOpenChange = (open: boolean) => {
		setIsOpen(open)
	}

	const handleZoomIn = (e: React.MouseEvent) => {
		e.preventDefault()
		onScaleModeChange(null)
		onZoomIn()
	}

	const handleZoomOut = (e: React.MouseEvent) => {
		e.preventDefault()
		onScaleModeChange(null)
		onZoomOut()
	}

	const handleScaleModeChange = (mode: ScaleModeType) => {
		onScaleModeChange(mode)
	}

	return (
		<DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
			<DropdownMenu.Trigger asChild>
				<ToolButton tooltip={isOpen ? '' : 'Display Options'} isOpen={isOpen}>
					<BookOpen />
				</ToolButton>
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<MenuContent>
					<CheckboxItem
						checked={spreadMode === 0}
						onCheckedChange={() => onSpreadModeChange(0)}
						onSelect={e => e.preventDefault()}
					>
						Single Page
					</CheckboxItem>
					<CheckboxItem
						checked={spreadMode === 1}
						onCheckedChange={() => onSpreadModeChange(1)}
						onSelect={e => e.preventDefault()}
					>
						Two Pages (Even First)
					</CheckboxItem>
					<CheckboxItem
						checked={spreadMode === 2}
						onCheckedChange={() => onSpreadModeChange(2)}
						onSelect={e => e.preventDefault()}
					>
						Two Pages (Odd First)
					</CheckboxItem>

					<Separator />

					<CheckboxItem
						checked={scaleMode === 'auto'}
						onCheckedChange={() => handleScaleModeChange('auto')}
						onSelect={e => e.preventDefault()}
					>
						Automatic Zoom
					</CheckboxItem>
					<CheckboxItem
						checked={scaleMode === 'page-actual'}
						onCheckedChange={() => handleScaleModeChange('page-actual')}
						onSelect={e => e.preventDefault()}
					>
						Actual Size
					</CheckboxItem>
					<CheckboxItem
						checked={scaleMode === 'page-width'}
						onCheckedChange={() => handleScaleModeChange('page-width')}
						onSelect={e => e.preventDefault()}
					>
						Page Width
					</CheckboxItem>
					<CheckboxItem
						checked={scaleMode === 'page-fit'}
						onCheckedChange={() => handleScaleModeChange('page-fit')}
						onSelect={e => e.preventDefault()}
					>
						Page Fit
					</CheckboxItem>

					<Separator />

					<div className='flex items-center justify-between px-2 py-1'>
						<button
							onClick={handleZoomOut}
							className='aspect-square w-8 rounded px-2 py-1 hover:bg-gray-100'
						>
							<Minus className='h-4 w-4' />
						</button>
						<span className='flex-1 text-center text-sm'>
							{Math.round(scale * 100)}%
						</span>
						<button
							onClick={handleZoomIn}
							className='rounded px-2 py-1 hover:bg-gray-100'
						>
							<Plus className='h-4 w-4' />
						</button>
					</div>
				</MenuContent>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}

export type { PDFDisplayOptionsButtonProps }
export default PDFDisplayOptionsButton
