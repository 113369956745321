import { Square2StackIcon } from '@heroicons/react/24/solid'
import { useContext, useEffect, useState } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import Button from '../FormComponents/Button'
import Input from '../Input'
import Popover from '../Popover'
import InviteTokenResetButton from './InviteTokenResetButton'

interface InvitePlayerPopoverProps {
	isOpen: boolean
	setIsOpen: (open: boolean) => void
	anchorRef?: React.RefObject<HTMLButtonElement>
}

export default function InvitePlayerPopover({
	isOpen,
	setIsOpen,
	anchorRef,
}: InvitePlayerPopoverProps) {
	const { game } = useContext(GameContext)
	const [token, setToken] = useState('')
	const { primary } = useTheme()

	useEffect(() => {
		setToken(game.inviteToken)
	}, [game.inviteToken])

	const copyToClipboard = () => {
		navigator.clipboard.writeText(
			`${window.location.href}?inviteToken=${token}`,
		)
	}

	return (
		<Popover
			id='token-popover'
			open={isOpen}
			anchor={anchorRef}
			onClose={() => setIsOpen(false)}
			placement='bottom'
			className='bg-gray-900 p-1'
			style={{
				width: '400px',
			}}
		>
			<div className='flex'>
				<Input
					type='text'
					className='w-2/4 flex-1 pt-0'
					inputClassName='rounded-r-none text-gray-500'
					value={`${window.location.href}?inviteToken=${token}`}
					readOnly
				/>
				<Button
					type='button'
					className='flex-0 mt-0 ml-px w-12 rounded-l-none p-1 leading-4 active:bg-green-700 active:text-white'
					onClick={copyToClipboard}
					label='Copy Invitation Link'
					style={{
						color: primary,
					}}
				>
					<Square2StackIcon className='h-5 w-5 self-center' />
				</Button>

				<InviteTokenResetButton />
			</div>
			<p className='mt-2 mb-1 text-center text-sm text-gray-500'>
				Invite friends to the game with this link
			</p>
		</Popover>
	)
}
