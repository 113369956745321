import { useMemo } from 'react'
import { ICollection } from '../../../shared/types/system'
import { useGame } from './useGame'
import { usePdfCollections } from './usePdfCollections'

export default function useAllCollections() {
	const { game } = useGame()
	const pdfCollections = usePdfCollections()

	const allCollections = useMemo(() => {
		const systemCollections = game.system?.collections || []

		// Combine both collections, with PDF collections taking precedence
		const combined = new Map<string, ICollection>()

		// Add system collections first
		systemCollections.forEach(collection => {
			combined.set(collection.type, collection)
		})

		// Add/override with PDF collections
		pdfCollections.forEach(collection => {
			combined.set(collection.type, collection)
		})

		return Array.from(combined.values())
	}, [game.system?.collections, pdfCollections])

	return allCollections
}
