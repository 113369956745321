import { produce } from 'immer'
import { PdfSheetActions, PdfSheets } from '../../../shared/types/pdfSheet'

export const pdfSheetReducer = produce(
	(draft: PdfSheets | undefined, action: PdfSheetActions) => {
		// Initialize pdfSheets if undefined
		if (!draft) {
			draft = {
				byId: {},
				allIds: [],
			}
		}

		switch (action.type) {
			case 'ADD_PDF_SHEET': {
				const { id, resourceId, collection } = action.payload
				draft.byId[id] = {
					resourceId,
					collection,
				}
				draft.allIds.push(id)
				break
			}

			case 'REMOVE_PDF_SHEET': {
				const { id } = action.payload
				delete draft.byId[id]
				draft.allIds = draft.allIds.filter(existingId => existingId !== id)
				break
			}

			case 'UPDATE_PDF_SHEET': {
				const { id, collection } = action.payload
				if (draft.byId[id]) {
					draft.byId[id].collection = {
						...draft.byId[id].collection,
						...collection,
						fieldMappings: {
							...draft.byId[id].collection.fieldMappings,
							...collection.fieldMappings,
						},
						thumbnailField:
							collection.thumbnailField ||
							draft.byId[id].collection.thumbnailField,
					}
				}
				break
			}
		}

		return draft
	},
)
