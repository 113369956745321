import { Transition } from '@headlessui/react'
import { debounce } from 'lodash'
import { AnnotationEditorType, AnnotationMode, getDocument } from 'pdfjs-dist'
import { PDFViewerOptions } from 'pdfjs-dist/types/web/pdf_viewer'
import 'pdfjs-dist/web/pdf_viewer.css'
import {
	EventBus,
	PDFLinkService,
	PDFScriptingManager,
	PDFViewer as PDFViewerComponent,
} from 'pdfjs-dist/web/pdf_viewer.mjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePdfModifier } from '../../hooks/usePdfModifier'
import { configurePdfWorker } from '../../utils/pdfConfig'
import { usePdfKeyboardNavigation } from './hooks/usePdfKeyboardNavigation'
import PDFOutline from './PDFOutline'
import PDFToolbar from './pdftoolbar/PDFToolbar'
import './PDFViewer.css'
import {
	PDFViewerProps,
	ScaleMode,
	ScaleModeType,
	SpreadMode,
	SpreadModeType,
	TextLayerMode,
} from './types/pdfViewerTypes'
import { useCustomImageAnnotations } from './useCustomImageAnnotations'
import useEventBusDebugger from './useEventBusDebugger'

configurePdfWorker()

const DEBUG = false

// UI Constants
const TRANSITION_DURATION = 200 // milliseconds
const RESCALE_DELAY = TRANSITION_DURATION + 50 // Wait for transition to complete before rescaling
const STORAGE_KEY_PREFIX = 'pdf-viewer-settings-'

interface PDFViewerSettings {
	outlineWidth: number
	showOutline: boolean
	lastPage: number
}

// Add viewer preferences type
interface ViewerPreferences {
	FitWindow?: boolean
	CenterWindow?: boolean
	DisplayDocTitle?: boolean
	HideMenubar?: boolean
	HideToolbar?: boolean
	HideWindowUI?: boolean
	Direction?: 'L2R' | 'R2L'
	[key: string]: any // for any other properties we might encounter
}

export const PDFViewer = ({
	resource,
	page = '1',
	isGame = false,
	showTools = true,
	formValues,
	onFormChange,
	enableAnnotations = true,
	initialSpreadMode,
	initialScaleMode,
	annotationMode = AnnotationMode.ENABLE_STORAGE,
	storePageInUrl = false,
}: PDFViewerProps) => {
	false && console.log('isGame', isGame)
	// Get a stable identifier for the resource
	const resourceIdentifier = useMemo(() => {
		if ('fileurl' in resource) {
			return resource.fileurl
		}
		return ''
	}, [resource])

	// Initialize settings
	const initialSettings = useMemo(() => {
		const savedSettings = localStorage.getItem(
			STORAGE_KEY_PREFIX + resourceIdentifier,
		)
		if (savedSettings) {
			try {
				const parsed = JSON.parse(savedSettings) as PDFViewerSettings
				return parsed
			} catch (e) {
				console.error('Failed to parse saved PDF viewer settings:', e)
			}
		}
		return {
			outlineWidth: 256,
			showOutline: false,
			lastPage: parseInt(page) || 1,
		}
	}, [resourceIdentifier, page])

	const [outlineWidth, setOutlineWidth] = useState(initialSettings.outlineWidth)
	const [showOutline, setShowOutline] = useState(initialSettings.showOutline)

	// Current page is now only updated via the viewer's pagechanging event
	const [currentPage, setCurrentPage] = useState(() => {
		// URL parameter takes precedence over localStorage
		const urlPage = parseInt(page)
		if (!isNaN(urlPage) && urlPage > 0) {
			return urlPage
		}
		return initialSettings.lastPage
	})

	// Debounced URL update
	const updateURL = useMemo(
		() =>
			debounce((page: number) => {
				if (typeof window === 'undefined' || !page || !storePageInUrl) return
				try {
					const url = new URL(window.location.href)
					url.searchParams.set('page', page.toString())
					window.history.replaceState({}, '', url.toString())
				} catch (e) {
					console.error('Failed to update URL:', e)
				}
			}, 500),
		[storePageInUrl],
	)

	// Debounced localStorage update
	const updateLocalStorage = useMemo(
		() =>
			debounce((settings: PDFViewerSettings) => {
				if (!resourceIdentifier || typeof window === 'undefined') return
				localStorage.setItem(
					STORAGE_KEY_PREFIX + resourceIdentifier,
					JSON.stringify(settings),
				)
			}, 500),
		[resourceIdentifier],
	)

	useEffect(() => {
		updateURL(currentPage)
		return () => updateURL.cancel()
	}, [currentPage, updateURL])

	useEffect(() => {
		const settings: PDFViewerSettings = {
			outlineWidth,
			showOutline,
			lastPage: currentPage || 1,
		}
		updateLocalStorage(settings)
		return () => updateLocalStorage.cancel()
	}, [outlineWidth, showOutline, currentPage, updateLocalStorage])

	const { modifyPdfWithFormData } = usePdfModifier()
	const containerRef = useRef<HTMLDivElement>(null)
	const viewerRef = useRef<HTMLDivElement>(null)
	const pdfViewerRef = useRef<PDFViewerComponent | null>(null)
	const eventBusRef = useRef<EventBus | null>(null)
	const scriptingManagerRef = useRef<PDFScriptingManager | null>(null)
	const fieldMapRef = useRef<Map<string, string>>(new Map())
	const [pageCount, setPageCount] = useState(0)
	const [scale, setScale] = useState(1)
	const [scaleMode, setScaleMode] = useState<ScaleModeType>(
		initialScaleMode ?? ScaleMode.AUTO,
	)
	const [spreadMode, setSpreadMode] = useState<SpreadModeType>(
		initialSpreadMode ?? SpreadMode.EVEN,
	)
	const [outline, setOutline] = useState<any[] | null>(null)
	const [error, setError] = useState<Error | null>(null)
	const pdfUrl = resource.fileurl
	const isLoadingRef = useRef(false)
	const [isLoading, setIsLoading] = useState(true) // Add state for loading

	useEventBusDebugger(eventBusRef, isLoadingRef.current, DEBUG)

	const createFieldMap = async () => {
		const fieldMap = new Map<string, string>()
		const pdfDoc = pdfViewerRef.current?.pdfDocument
		if (!pdfDoc) return fieldMap

		for (let i = 1; i <= pdfDoc.numPages; i++) {
			const page = await pdfDoc.getPage(i)
			const annotations = await page.getAnnotations()
			annotations.forEach(annotation => {
				if (annotation.fieldName) {
					fieldMap.set(annotation.id, annotation.fieldName)
				}
			})
		}

		return fieldMap
	}

	const loadPdf = async () => {
		if (isLoadingRef.current) return
		isLoadingRef.current = true
		try {
			if (!containerRef.current || !viewerRef.current) return

			const finalPdfUrl = await modifyPdfWithFormData(pdfUrl, formValues)

			const eventBus = new EventBus()
			eventBusRef.current = eventBus

			const linkService = new PDFLinkService({ eventBus })

			const scriptingManager = new PDFScriptingManager({
				eventBus,
				sandboxBundleSrc: '/pdf.sandbox.min.mjs',
			})
			scriptingManagerRef.current = scriptingManager

			const viewerSettings: PDFViewerOptions = {
				container: containerRef.current,
				viewer: viewerRef.current,
				eventBus,
				linkService,
				scriptingManager,
				textLayerMode: TextLayerMode.ENABLED,
				annotationMode,
				annotationEditorMode: AnnotationEditorType.NONE,
				removePageBorders: true,
				enableHWA: true,
			}

			const viewer = new PDFViewerComponent(viewerSettings)
			pdfViewerRef.current = viewer
			linkService.setViewer(viewer)

			const loadingTask = getDocument({ url: finalPdfUrl })
			const pdfDocument = await loadingTask.promise

			// Read all PDF viewer settings using proper API methods
			const [layout, mode, viewerPreferences] = await Promise.all([
				pdfDocument.getPageLayout(),
				pdfDocument.getPageMode(),
				pdfDocument.getViewerPreferences() as Promise<ViewerPreferences>,
			])

			// Apply layout settings if they exist
			if (layout) {
				switch (layout) {
					case 'SinglePage':
						viewer.spreadMode = SpreadMode.NONE
						break
					case 'OneColumn':
						viewer.scrollMode = 1 // vertical scrolling
						viewer.spreadMode = SpreadMode.NONE
						break
					case 'TwoColumnLeft':
					case 'TwoPageLeft':
						viewer.spreadMode = SpreadMode.ODD
						break
					case 'TwoColumnRight':
					case 'TwoPageRight':
						viewer.spreadMode = SpreadMode.EVEN
						break
				}
			}

			// Apply page mode settings
			if (mode) {
				switch (mode) {
					case 'UseOutlines':
						setShowOutline(true)
						break
					case 'UseThumbs':
						// TODO: Implement thumbnail view
						break
					case 'FullScreen':
						// TODO: Implement fullscreen mode
						break
				}
			}

			// Apply viewer preferences
			if (viewerPreferences) {
				// Use optional chaining for safe property access
				if (viewerPreferences?.FitWindow === true) {
					viewer.currentScaleValue = ScaleMode.PAGE_FIT
				}
				// TODO: Handle other viewer preferences as needed:
				// - Direction (L2R/R2L)
				// - HideToolbar
				// - HideMenubar
				// - HideWindowUI
				// - CenterWindow
				// - DisplayDocTitle
			}

			linkService.setDocument(pdfDocument)
			scriptingManager.setViewer(viewer)
			viewer.setDocument(pdfDocument)

			await viewer.pagesPromise

			eventBus.on('pagechanging', evt => {
				setCurrentPage(evt.pageNumber)
			})

			eventBus.on('annotationlayerrendered', async () => {
				fieldMapRef.current = await createFieldMap()
			})

			// Set default modes if not specified in PDF
			if (!layout) {
				viewer.spreadMode = spreadMode
			}
			if (viewerPreferences?.FitWindow !== true) {
				viewer.currentScaleValue = scaleMode
			}

			// Set initial page without calling setCurrentPage
			const pageNumber = parseInt(page)
			if (
				!isNaN(pageNumber) &&
				pageNumber > 0 &&
				pageNumber <= pdfDocument.numPages
			) {
				viewer.currentPageNumber = pageNumber
			}

			setPageCount(pdfDocument.numPages)

			try {
				const docOutline = await pdfDocument.getOutline()
				if (docOutline) {
					setOutline(docOutline)
				}
			} catch (error) {
				console.error('[PDFViewer] Error getting outline:', error)
			}

			eventBusRef.current.on('dispatcheventinsandbox', handleSandboxDispatch, {
				external: true,
			})
		} catch (error) {
			setError(error instanceof Error ? error : new Error('Failed to load PDF'))
		} finally {
			isLoadingRef.current = false
			setIsLoading(false)
		}
	}

	useEffect(() => {
		loadPdf()
	}, [])

	const getAllFieldValues = async () => {
		const fieldValues = {}
		for (let i = 1; i <= pdfViewerRef.current?.pdfDocument?.numPages; i++) {
			const page = await pdfViewerRef.current?.pdfDocument?.getPage(i)
			const annotations = await page?.getAnnotations()
			annotations.forEach(annotation => {
				if (annotation.fieldName) {
					fieldValues[annotation.fieldName] = annotation.fieldValue
				}
			})
		}
		return fieldValues
	}

	const getAllAnnotations = async () => {
		const annotationStorage =
			pdfViewerRef.current?.pdfDocument?.annotationStorage.getAll()
		const changedValues = {}

		for (const [id, value] of Object.entries(annotationStorage)) {
			const fieldName = fieldMapRef.current.get(id)
			changedValues[fieldName] = value.value
		}

		return changedValues
	}

	const handleAnnotationChange = async () => {
		const fieldValues = await getAllFieldValues()
		const changedAnnotations = await getAllAnnotations()
		const allAnnotations = {
			...fieldValues,
			...changedAnnotations,
		}

		onFormChange?.(allAnnotations)
	}

	const handleSandboxDispatch = (event: any) => {
		if (!event.detail.willCommit) return
		handleAnnotationChange()
	}

	useEffect(() => {
		if (!pdfViewerRef.current || !eventBusRef.current) return

		const handleEditorModeChange = (event: { mode: number }) => {
			if (!pdfViewerRef.current) return
			pdfViewerRef.current.annotationEditorMode = {
				mode: event.mode,
			}
		}

		eventBusRef.current.on('switchannotationeditormode', handleEditorModeChange)

		return () => {
			eventBusRef.current?.off(
				'switchannotationeditormode',
				handleEditorModeChange,
			)
		}
	}, [pdfViewerRef.current, eventBusRef.current])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		pdfViewerRef.current.currentScaleValue = scaleMode
	}, [scaleMode])

	const { findImageAnnotations, portals } = useCustomImageAnnotations(
		pdfViewerRef,
		handleAnnotationChange,
	)

	// When the user changes pages from the toolbar or keyboard navigation,
	// set the page on the viewer directly. The `pagechanging` event will update currentPage state.
	const handlePageChange = useCallback(
		(newPage: number, isAbsolute = false) => {
			if (!pdfViewerRef.current) return

			if (isAbsolute) {
				// For Home/End navigation, go directly to the target page
				pdfViewerRef.current.currentPageNumber = newPage
				return
			}

			const currentPageNum = pdfViewerRef.current.currentPageNumber
			const direction = newPage > currentPageNum ? 'next' : 'prev'

			const pagesToDisplay =
				pdfViewerRef.current.spreadMode === SpreadMode.NONE ? 1 : 2
			const isCoverAlone = pdfViewerRef.current.spreadMode === SpreadMode.EVEN

			let targetPage = currentPageNum
			if (pagesToDisplay === 1) {
				targetPage = Math.max(1, Math.min(newPage, pageCount))
			} else {
				if (direction === 'next') {
					targetPage =
						isCoverAlone && currentPageNum === 1
							? 2
							: Math.min(currentPageNum + 2, pageCount)
				} else {
					targetPage =
						isCoverAlone && currentPageNum <= 2
							? 1
							: Math.max(currentPageNum - 2, 1)
				}
			}

			pdfViewerRef.current.currentPageNumber = targetPage
		},
		[pageCount],
	)

	const handleZoomIn = () => {
		if (!pdfViewerRef.current) return
		const viewer = pdfViewerRef.current
		const newScale = viewer.currentScale * 1.1
		viewer.currentScale = Math.min(newScale, 10.0)
		setScale(viewer.currentScale)
	}

	const handleZoomOut = () => {
		if (!pdfViewerRef.current) return
		const viewer = pdfViewerRef.current
		const newScale = viewer.currentScale / 1.1
		viewer.currentScale = Math.max(newScale, 0.1)
		setScale(viewer.currentScale)
	}

	usePdfKeyboardNavigation({
		currentPage,
		pageCount,
		spreadMode,
		handlePageChange,
		handleZoomIn,
		handleZoomOut,
		setScaleMode,
		setShowOutline,
		showOutline,
	})

	useEffect(() => {
		if (!enableAnnotations) return
		if (isLoadingRef.current) return
		findImageAnnotations()
	}, [findImageAnnotations, isLoadingRef.current])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		eventBusRef.current.on('annotationchange', handleAnnotationChange)
		return () => {
			eventBusRef.current.off('annotationchange', handleAnnotationChange)
		}
	}, [pdfViewerRef.current, eventBusRef.current])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		pdfViewerRef.current.spreadMode = spreadMode
	}, [spreadMode])

	useEffect(() => {
		if (!containerRef.current || !pdfViewerRef.current) return

		const debouncedResize = debounce(() => {
			if (!pdfViewerRef.current) return
			requestAnimationFrame(() => {
				if (pdfViewerRef.current) {
					pdfViewerRef.current.currentScaleValue = scaleMode
				}
			})
		}, 100)

		const observer = new ResizeObserver(debouncedResize)
		observer.observe(containerRef.current)

		return () => {
			debouncedResize.cancel()
			observer.disconnect()
		}
	}, [containerRef.current, pdfViewerRef.current, scaleMode])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		const timer = setTimeout(() => {
			if (pdfViewerRef.current) {
				pdfViewerRef.current.currentScaleValue = scaleMode
			}
		}, RESCALE_DELAY)

		return () => clearTimeout(timer)
	}, [showOutline, scaleMode, outlineWidth])

	return (
		<div className='pdfViewer absolute inset-0 flex flex-col'>
			{isLoading && (
				<div className='absolute inset-0 flex items-center justify-center bg-white/50'>
					<div className='text-lg'>Loading PDF...</div>
				</div>
			)}
			{error && (
				<div className='absolute inset-0 flex items-center justify-center bg-white/50'>
					<div className='text-lg text-red-600'>{error.message}</div>
				</div>
			)}
			{showTools && (
				<PDFToolbar
					pageNumber={currentPage}
					pagesCount={pageCount}
					scale={scale}
					scaleMode={scaleMode}
					spreadMode={spreadMode}
					onPageChange={handlePageChange}
					onZoomIn={handleZoomIn}
					onZoomOut={handleZoomOut}
					onScaleModeChange={setScaleMode}
					onSpreadModeChange={setSpreadMode}
					onOutlineToggle={() => setShowOutline(!showOutline)}
					showOutline={showOutline}
					eventBus={eventBusRef.current}
					title={resource.metadata?.title}
					isGame={isGame}
				/>
			)}
			<div className='relative flex-1'>
				<Transition
					show={showOutline && !!outline?.length && showTools}
					enter={`transition-transform duration-${TRANSITION_DURATION} ease-out`}
					enterFrom='-translate-x-full'
					enterTo='translate-x-0'
					leave={`transition-transform duration-${TRANSITION_DURATION} ease-out`}
					leaveFrom='translate-x-0'
					leaveTo='-translate-x-full'
				>
					<div className='absolute inset-y-0 left-0 z-10'>
						<PDFOutline
							outline={outline}
							pdfViewer={pdfViewerRef.current}
							onPageChange={handlePageChange}
							currentPage={currentPage}
							onWidthChange={setOutlineWidth}
							onRequestHide={() => setShowOutline(false)}
							resourceId={resourceIdentifier}
							width={outlineWidth}
						/>
					</div>
				</Transition>
				<div
					ref={containerRef}
					className={`pdfContainer absolute inset-0 overflow-auto transition-[left] duration-${TRANSITION_DURATION} ease-out`}
					style={{ left: showOutline && showTools ? `${outlineWidth}px` : 0 }}
				>
					<div ref={viewerRef} className='pdfViewer' />
				</div>
			</div>
			{portals}
		</div>
	)
}
export default PDFViewer
