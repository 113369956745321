import React from 'react'

interface D6IconProps extends React.SVGProps<SVGSVGElement> {
	fill?: string
	stroke?: string
}

const D6Icon: React.FC<D6IconProps> = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	...props
}) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 595.28 595.28'
		{...props}
	>
		<path
			d='M276.38,44.79l-187.09,108.01c-13.16,7.6-21.26,21.63-21.26,36.82v216.03c0,15.19,8.1,29.23,21.26,36.82l187.09,108.01c13.16,7.6,29.36,7.6,42.52,0l187.08-108.01c13.16-7.6,21.26-21.63,21.26-36.82v-216.03c0-15.19-8.1-29.23-21.26-36.82l-187.09-108.01c-13.16-7.6-29.36-7.6-42.52,0Z'
			fill={fill}
			stroke={stroke}
			strokeWidth='22.11'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='79.07'
			y1='171.45'
			x2='297.64'
			y2='297.64'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='516.2'
			y1='171.45'
			x2='297.64'
			y2='297.64'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='297.64'
			y1='297.64'
			x2='297.64'
			y2='550.02'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default D6Icon
