import React from 'react'

interface D4IconProps extends React.SVGProps<SVGSVGElement> {
	fill?: string
	stroke?: string
}

const D4Icon: React.FC<D4IconProps> = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	...props
}) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 595.28 595.28'
		{...props}
	>
		<path
			d='M282.91,80.22L73,443.8c-6.55,11.34,1.64,25.51,14.73,25.51h419.82c13.09,0,21.28-14.17,14.73-25.51L312.37,80.22c-6.55-11.34-22.91-11.34-29.46,0Z'
			fill={fill}
			stroke={stroke}
			strokeWidth='22.11'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='297.64'
			y1='77.88'
			x2='297.64'
			y2='331.11'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='78.34'
			y1='457.72'
			x2='297.64'
			y2='331.11'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='516.94'
			y1='457.72'
			x2='297.64'
			y2='331.11'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default D4Icon
