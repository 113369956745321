import ObjectID from 'bson-objectid'
import { AnnotationMode } from 'pdfjs-dist'
import { FC, useEffect, useState } from 'react'
import { BookResource } from '../../../../shared/types/resources'
import { ICollection } from '../../../../shared/types/system'
import Button from '../FormComponents/Button'
import Dropdown from '../FormComponents/Dropdown'
import Input from '../FormComponents/Input'
import Label from '../FormComponents/Label'
import { PDFViewer } from '../resources/PDFViewer'
import { ScaleMode, SpreadMode } from '../resources/types/pdfViewerTypes'
import ModalWindow from '../window/ModalWindow'

interface PDFSheetConfigModalProps {
	onClose: () => void
	onSave: (collection: ICollection) => void
	initialValues?: ICollection
	resource: BookResource
}

const PDFSheetConfigModal: FC<PDFSheetConfigModalProps> = ({
	onClose,
	onSave,
	initialValues,
	resource,
}) => {
	const [isOpen, setIsOpen] = useState(true)

	const handleClose = () => {
		setIsOpen(false)
		setTimeout(onClose, 500)
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		const formData = new FormData(e.target as HTMLFormElement)
		const nameField = formData.get('nameField') as string
		const imageField = formData.get('tokenField') as string

		if (!nameField) {
			alert(
				'Please select a name field - this is required to identify the document in lists and windows.',
			)
			return
		}

		const collection: ICollection = {
			singularName: formData.get('singularName') as string,
			pluralName: formData.get('pluralName') as string,
			description: '',
			type: initialValues?.type || new ObjectID().toHexString(),
			hasEditMode: 'true',
			allowCreate: 'false',
			acceptTypes: [],
			windowSize: 'large',
			canAssumeAsCharacter: 'true',
			defaultAccess: 'private',
			thumbnailField: imageField ? [imageField] : [],
			fieldMappings: {
				name: nameField,
			},
		}

		onSave(collection)
		handleClose()
	}

	// Set initial values when editing
	useEffect(() => {
		if (initialValues) {
			const form = document.getElementById('pdf-config-form') as HTMLFormElement
			if (form) {
				form.singularName.value = initialValues.singularName
				form.pluralName.value = initialValues.pluralName
			}
		}
	}, [initialValues])

	// Only render PDF preview if we have a valid resource
	const canShowPreview = resource && resource.fileurl && resource._id

	// Get available form field names from the resource, filtered by type
	const formFields = resource?.metadata?.pdf?.formFields || []
	const textFields = formFields
		.filter(f => f.type === 'PDFTextField')
		.map(f => f.name)
	const imageFields = formFields
		.filter(f => f.type === 'PDFImageField')
		.map(f => f.name)

	return (
		<ModalWindow
			id='pdf-sheet-config'
			title={initialValues ? 'Edit PDF Sheet' : 'Create PDF Sheet'}
			onClose={handleClose}
			size='large'
			className='h-[800px]'
			bodyClassName='overflow-hidden'
			isOpen={isOpen}
			footerChildren={
				<div className='flex justify-end space-x-2'>
					<Button type='button' onClick={handleClose} className='mt-0'>
						Cancel
					</Button>
					<Button type='submit' form='pdf-config-form' className='mt-0'>
						{initialValues ? 'Update' : 'Save'}
					</Button>
				</div>
			}
		>
			<div className='flex h-full gap-8 p-4'>
				{/* Configuration Form */}
				<form
					id='pdf-config-form'
					onSubmit={handleSubmit}
					className='h-full w-3/5 space-y-6 overflow-auto'
				>
					<div className=' pt-4'>
						<h3 className='mb-4 text-xl font-medium text-gray-200'>
							Basic Settings
						</h3>

						<p className='mb-4 text-sm text-gray-400'>
							PDF Sheets create collections which represent a group of
							documents. Each document uses the same PDF template, but can have
							different values for each field.
						</p>

						<div className='space-y-2'>
							<div className='flex items-center justify-between space-x-2'>
								<Label className='mt-0' htmlFor='singularName' isOnDark>
									Name (Singular)
									<br />
									<div className='-mt-0.5 text-xs text-gray-500'>Required</div>
								</Label>
								<Input
									name='singularName'
									placeholder='E.g. "Character"'
									className='mb-0 w-2/3'
									inputClassName='py-2'
									isOnDark
									required
								/>
							</div>

							<div className='flex items-center justify-between space-x-2'>
								<Label className='mt-0' htmlFor='pluralName' isOnDark>
									Name (Plural)
									<br />
									<div className='-mt-0.5 text-xs text-gray-500'>Required</div>
								</Label>
								<Input
									name='pluralName'
									placeholder='E.g. "Characters"'
									className='mb-0 w-2/3'
									inputClassName='py-2'
									isOnDark
									required
								/>
							</div>
						</div>
					</div>

					{/* Field Mapping Section */}
					<div className='space-y-4'>
						<div className='pt-4'>
							<h3 className='mb-4 text-xl font-medium text-gray-200'>
								Field Mapping
							</h3>

							<p className='mb-4 text-sm text-gray-400'>
								To represent the sheet in lists and windows, we need to know
								which form fields to use for the name and portrait/token.
							</p>

							<div className='space-y-2'>
								<div className='flex items-center justify-between space-x-2'>
									<Label className='mt-0' htmlFor='nameField' isOnDark>
										Name
										<br />
										<div className='-mt-0.5 text-xs text-gray-500'>
											Required
										</div>
									</Label>
									<Dropdown
										name='nameField'
										defaultValue={initialValues?.fieldMappings?.name || ''}
										containerClassName='w-2/3 flex-none'
										className='mt-0'
										required
									>
										<option value=''>Select a name field (required)</option>
										{textFields.map(name => (
											<option key={name} value={name}>
												{name}
											</option>
										))}
									</Dropdown>
								</div>

								<div className='flex items-center justify-between space-x-2'>
									<Label className='mt-0' htmlFor='tokenField' isOnDark>
										Portrait/Token
									</Label>
									<Dropdown
										name='tokenField'
										defaultValue={initialValues?.thumbnailField?.[0] || ''}
										containerClassName='w-2/3 flex-none'
										className='mt-0'
									>
										<option value=''>None</option>
										{imageFields.map(name => (
											<option key={name} value={name}>
												{name}
											</option>
										))}
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
				</form>

				{canShowPreview ? (
					<div className='relative h-full w-2/5 overflow-hidden rounded-xl bg-gray-800'>
						<PDFViewer
							resource={resource}
							showTools={false}
							enableAnnotations={false}
							initialSpreadMode={SpreadMode.NONE}
							initialScaleMode={ScaleMode.PAGE_WIDTH}
							annotationMode={AnnotationMode.DISABLE}
						/>
					</div>
				) : (
					<div className='flex w-1/2 items-center justify-center rounded border border-gray-700'>
						<div className='text-gray-400'>Loading PDF preview...</div>
					</div>
				)}
			</div>
		</ModalWindow>
	)
}

export default PDFSheetConfigModal
