import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import DocumentsContext from '../contexts/documents'
import GameContext from '../contexts/game'
import { usePdfCollections } from '../hooks/usePdfCollections'
import DocumentWindow from './Library/DocumentWindow'
import Library from './Library/Library'
import MapWindow from './Library/MapEditor/MapWindow'
import PdfSheetWindow from './Library/PdfSheetWindow'
import SceneWindow from './Library/SceneEditor/SceneWindow'

const Sidebar = () => {
	const { game } = useContext(GameContext)
	const { openDocuments, dispatchDocuments } = useContext(DocumentsContext)
	const form = useForm()
	const pdfCollections = usePdfCollections()

	const handleDocumentClose = (id: string) => {
		dispatchDocuments({
			type: 'CLOSE_DOCUMENT',
			payload: { documentId: id },
		})
	}

	const isPdfDocument = (documentId: string) => {
		const document = game.documents.byId[documentId]
		return pdfCollections.some(collection => collection.type === document.type)
	}

	return (
		<>
			<FormProvider {...form}>
				<Library />
			</FormProvider>

			{openDocuments.map((documentId: string) => {
				const document = game.documents.byId[documentId]
				const { type } = document

				if (type === 'scene') {
					return (
						<SceneWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				} else if (type === 'map') {
					return (
						<MapWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				} else if (isPdfDocument(documentId)) {
					return (
						<PdfSheetWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				} else {
					return (
						<DocumentWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				}
			})}
		</>
	)
}

export default Sidebar
