import React from 'react'
import { PdfViewerAction, PdfViewerState } from '../../../shared/types/book'

export type BookDispatch = React.Dispatch<PdfViewerAction>

const PdfViewerContext = React.createContext<{
	bookState: PdfViewerState
	bookDispatch: BookDispatch
} | null>(null)

export default PdfViewerContext
