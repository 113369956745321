import { useMutation } from '@apollo/client'
import { getDocument } from 'pdfjs-dist'
import { useContext } from 'react'
import { ResourceType } from '../../../shared/types/resources'
import usePdfTextExtractor from '../components/resources/hooks/usePdfTextExtractor'
import { ResourceContext } from '../contexts/resources'
import { UPDATE_RESOURCE } from '../graphql/resources'
import { filterValidCategories } from '../utils/bookCategories'
import useAiMetadataExtractor from './useAiMetadataExtractor'
import useApi from './useApi'
import { usePdfFormExtractor } from './usePdfFormExtractor'
import { usePdfTechnicalExtractor } from './usePdfTechnicalExtractor'
import useResourceUpload from './useResourceUpload'
import useSummarizeText from './useSummarizeText'

interface ProcessBookOptions {
	generateEmbeddings?: boolean
	generateSummary?: boolean
	skipAIMetadata?: boolean
	resourceType?: ResourceType
	uploadResult?: any
}

const useProcessBook = () => {
	const { dispatchResource } = useContext(ResourceContext)
	const uploadResource = useResourceUpload()
	const { request } = useApi()
	const { extractText } = usePdfTextExtractor()
	const summarizeText = useSummarizeText()
	const { extractMetadataWithAI } = useAiMetadataExtractor()
	const { extractPdfMetadata } = usePdfTechnicalExtractor()
	const { extractFormFields } = usePdfFormExtractor()
	const [updateResource] = useMutation(UPDATE_RESOURCE)

	const generateEmbeddings = async (
		text: string,
		bookId: string,
		bookTitle: string,
	): Promise<void> => {
		const token = localStorage.getItem('token')
		const url = '/api/generate-embeddings'
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ text, bookId, bookTitle }),
		}

		request(url, options).catch(error => {
			console.warn('Failed to generate embeddings:', error)
		})
	}

	const processBook = async (
		file: File,
		options: ProcessBookOptions = { generateSummary: false },
		onProgress?: (percent: number) => void,
	) => {
		console.log('ProcessBook: Starting processing', {
			fileName: file.name,
			fileSize: file.size,
			options,
		})

		// Start both upload and processing concurrently
		const uploadPromise = uploadResource(file, options.resourceType, onProgress)
		let pdf: any

		interface ProcessMetadata {
			title: string
			description: string
			categories: string[]
			game: string
			ruleSystem: string
			authors: string[]
			contributingArtists: string[]
			publisher: string
			year: string
			edition: string
			isbn: string
			pageCount: number
			language: string
			copyright: {
				year: string
				owner: string
			}
			series: string
			seriesNumber: number
			relatedBooks: string[]
			requiredBooks: string[]
			tags: string[]
			contentWarnings: string[]
			playerCount: {
				min: number
				max: number
			}
			recommendedAgeRange: {
				min: number
				max: number
			}
			playTime: {
				min: number
				max: number
			}
			tableOfContents: Array<{
				chapterTitle: string
				pageNumber: string
			}>
			errata: Array<{
				version: string
				url: string
				date: string
			}>
			pdf: {
				pageCount: number
				dimensions: {
					width: number
					height: number
					widthInches: number
					heightInches: number
					widthMm: number
					heightMm: number
				}
				hasOutline: boolean
				pageSizes: Array<{
					width: number
					height: number
				}>
				pageLabels: any[]
				formFields: any[]
			}
		}

		const processPromise = async () => {
			try {
				// Start processing immediately without waiting for upload
				console.log('ProcessBook: Loading PDF...')
				const arrayBuffer = await file.arrayBuffer()
				pdf = await getDocument(arrayBuffer).promise
				console.log('ProcessBook: PDF loaded successfully')

				// Extract all PDF data in parallel
				console.log('ProcessBook: Starting parallel data extraction...')
				const [extractedText, pdfMetadata, formFields] = await Promise.all([
					extractText(file).catch(error => {
						console.warn('Failed to extract text from PDF:', error)
						return ''
					}),
					extractPdfMetadata(pdf),
					extractFormFields(pdf),
				])
				console.log('ProcessBook: Data extraction complete:', {
					textLength: extractedText.length,
					textExtractionFailed: extractedText === '',
					pdfMetadata: {
						pageCount: pdfMetadata?.pageCount,
						hasOutline: pdfMetadata?.hasOutline,
						pageSizesCount: pdfMetadata?.pageSizes?.length,
						pageLabelsCount: pdfMetadata?.pageLabels?.length,
						firstPageDimensions: pdfMetadata?.pageSizes?.[0],
					},
					formFieldsCount: formFields?.length,
				})

				// Initialize metadata with default values for all fields
				const metadata: ProcessMetadata = {
					title: file.name.replace(/\.[^/.]+$/, ''), // Remove file extension
					description: '',
					categories: [],
					game: '',
					ruleSystem: '',
					authors: [],
					contributingArtists: [],
					publisher: '',
					year: '',
					edition: '',
					isbn: '',
					pageCount: pdfMetadata?.pageCount || 0,
					language: 'en',
					copyright: {
						year: '',
						owner: '',
					},
					series: '',
					seriesNumber: 0,
					relatedBooks: [],
					requiredBooks: [],
					tags: [],
					contentWarnings: [],
					playerCount: {
						min: 1,
						max: 99,
					},
					recommendedAgeRange: {
						min: 0,
						max: 99,
					},
					playTime: {
						min: 0,
						max: 0,
					},
					tableOfContents: [],
					errata: [],
					pdf: {
						pageCount: pdfMetadata?.pageCount || 0,
						dimensions: pdfMetadata?.pageSizes?.[0]
							? {
									width: pdfMetadata.pageSizes[0].width,
									height: pdfMetadata.pageSizes[0].height,
									widthInches: pdfMetadata.pageSizes[0].width / 72,
									heightInches: pdfMetadata.pageSizes[0].height / 72,
									widthMm: (pdfMetadata.pageSizes[0].width / 72) * 25.4,
									heightMm: (pdfMetadata.pageSizes[0].height / 72) * 25.4,
							  }
							: {
									width: 0,
									height: 0,
									widthInches: 0,
									heightInches: 0,
									widthMm: 0,
									heightMm: 0,
							  },
						hasOutline: pdfMetadata?.hasOutline || false,
						pageSizes: pdfMetadata?.pageSizes || [],
						pageLabels: pdfMetadata?.pageLabels || [],
						formFields: formFields || [],
					},
				}
				console.log('ProcessBook: Initial metadata created:', metadata)

				// Start AI operations immediately after text extraction
				const aiPromises = []

				if (!options.skipAIMetadata && extractedText) {
					aiPromises.push(extractMetadataWithAI(extractedText, null))
				} else {
					aiPromises.push(Promise.resolve(null))
				}

				if (options.generateSummary && extractedText) {
					aiPromises.push(summarizeText(extractedText, file.name))
				} else {
					aiPromises.push(Promise.resolve(''))
				}

				// Run AI operations concurrently
				const [aiMetadata, summary] = await Promise.all(aiPromises)
				console.log('ProcessBook: AI operations complete:', {
					hasAiMetadata: !!aiMetadata,
					summaryLength: summary?.length,
					textWasExtracted: !!extractedText,
				})

				const finalMetadata = aiMetadata
					? {
							...metadata,
							...aiMetadata,
							// Clean categories from AI response
							categories: aiMetadata.categories
								? filterValidCategories(aiMetadata.categories)
								: [],
							pdf: metadata.pdf, // Ensure PDF metadata is not overwritten by AI metadata
							// Ensure any missing fields from AI metadata are filled with defaults
							playTime: {
								min: aiMetadata.playTime?.min ?? 0,
								max: aiMetadata.playTime?.max ?? 0,
							},
							playerCount: {
								min: aiMetadata.playerCount?.min ?? 1,
								max: aiMetadata.playerCount?.max ?? 99,
							},
							recommendedAgeRange: {
								min: aiMetadata.recommendedAgeRange?.min ?? 0,
								max: aiMetadata.recommendedAgeRange?.max ?? 99,
							},
					  }
					: metadata

				console.log('ProcessBook: Final metadata:', finalMetadata)

				return {
					extractedText,
					metadata: finalMetadata,
					pdfMetadata,
					formFields,
					summary,
				}
			} catch (error) {
				console.error('Error during processing:', error)
				throw error
			}
		}

		try {
			// Run upload and processing concurrently
			const [resource, processResult] = await Promise.all([
				uploadPromise,
				processPromise(),
			])

			// Generate embeddings only if explicitly enabled and text was extracted
			if (options.generateEmbeddings && processResult.extractedText) {
				generateEmbeddings(
					processResult.extractedText,
					resource._id,
					resource.name,
				)
			}

			// Use the results from both operations
			const resourceInput = {
				_id: resource._id,
				name: resource.name,
				fileurl: resource.fileurl,
				filesize: resource.filesize,
				filetype: resource.filetype,
				creator: resource.creator,
				thumbnailurl: resource.thumbnailurl,
				resourceType: options.resourceType || 'book',
				metadata: processResult.metadata,
				usedInGames: resource.usedInGames,
			}

			console.log('Updating resource with:', {
				resourceId: resourceInput._id,
				resourceInput,
			})

			const response = await updateResource({
				variables: { resourceInput },
			})

			if (response.data?.updateResource) {
				const updatedResource = response.data.updateResource
				dispatchResource({
					type: 'UPDATE_RESOURCE',
					payload: { resource: updatedResource },
				})

				console.log('ProcessBook: Processing complete for', file.name)

				return {
					resource: updatedResource,
					summary: processResult.summary,
				}
			}

			throw new Error('Failed to update resource')
		} catch (error) {
			console.error('Error in processBook:', error)
			throw error
		} finally {
			// Ensure cleanup
			if (pdf) {
				pdf.destroy()
			}
		}
	}

	return { processBook }
}

export default useProcessBook
