import { Loader2 } from 'lucide-react'
import { AnnotationMode } from 'pdfjs-dist'
import { useMemo } from 'react'
import { IPDFSheetDocument } from '../../../../shared/types/document'
import { useGame } from '../../hooks/useGame'
import useOrigin from '../../hooks/UseOrigin'
import { usePdfSheet } from '../../hooks/usePdfSheet'
import PDFViewer from '../resources/PDFViewer'
import { ScaleMode, SpreadMode } from '../resources/types/pdfViewerTypes'
import Window from '../window/Window'
import PopoutButton from './PopoutButton'

interface PdfSheetWindowProps {
	documentId: string
	onClose: () => void
}

const PdfSheetWindow = ({ documentId, onClose }: PdfSheetWindowProps) => {
	const { game } = useGame()
	const { origin } = useOrigin()
	const document = game.documents.byId[documentId] as IPDFSheetDocument

	const { pdfSheet, loading, error, resource, handleFormChange } =
		usePdfSheet(document)

	const handlePop = () => {
		const url = new URL(`${origin}/sheet/${game._id}/${documentId}`)

		// Retrieve development mode settings using the game-specific key
		const isDevelopmentModeActive = JSON.parse(
			localStorage.getItem(`isDevelopmentModeActive_${game._id}`) || 'false',
		)
		const developmentOrigin = localStorage.getItem(
			`developmentOrigin_${game._id}`,
		)

		if (isDevelopmentModeActive && developmentOrigin) {
			url.searchParams.append(
				`developmentOrigin_${game._id}`,
				developmentOrigin,
			)
		}

		window.open(url.toString(), '_blank', 'width=800,height=600')
		onClose()
	}

	const windowProps = useMemo(
		() => ({
			documentId,
			title: document.values.name,
			onClose,
			size: pdfSheet?.collection.windowSize || 'large',
			bodyClassName: 'p-0 overflow-hidden',
			headerChildren: <PopoutButton handlePop={handlePop} />,
		}),
		[
			documentId,
			document.values.name,
			onClose,
			pdfSheet?.collection.windowSize,
		],
	)

	if (!pdfSheet?.resourceId) {
		return (
			<Window {...windowProps}>
				<div className='p-2 text-red-200'>
					Error: No PDF sheet resource found for document type: {document.type}
				</div>
			</Window>
		)
	}

	if (loading) {
		return (
			<Window {...windowProps}>
				<div className='flex items-center gap-2 p-2'>
					<Loader2 className='h-4 w-4 animate-spin' />
					<span className='text-gray-400'>Loading...</span>
				</div>
			</Window>
		)
	}

	if (error || !resource) {
		return (
			<Window {...windowProps}>
				<div className='p-2 text-red-200'>
					Error loading PDF sheet: {error?.message || 'Resource not found'}
				</div>
			</Window>
		)
	}

	return (
		<Window {...windowProps}>
			<PDFViewer
				resource={resource}
				isGame={true}
				showTools={false}
				formValues={document.values.formValues}
				annotationMode={AnnotationMode.ENABLE_FORMS}
				onFormChange={handleFormChange}
				initialSpreadMode={SpreadMode.NONE}
				initialScaleMode={ScaleMode.PAGE_WIDTH}
			/>
		</Window>
	)
}

export default PdfSheetWindow
