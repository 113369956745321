import { useQuery } from '@apollo/client'
import { useEffect, useMemo, useRef } from 'react'
import { IPDFSheetDocument } from '../../../shared/types/document'
import { PdfSheet } from '../../../shared/types/pdfSheet'
import { BookResource } from '../../../shared/types/resources'
import { GET_RESOURCE } from '../graphql/resources'
import { useGame } from './useGame'

interface UsePdfSheetResult {
	pdfSheet: PdfSheet | undefined
	loading: boolean
	error: any
	resource: BookResource | undefined
	handleFormChange: (values: Record<string, any>) => void
}

export const usePdfSheet = (document: IPDFSheetDocument): UsePdfSheetResult => {
	const { game, dispatch } = useGame()

	const pdfSheet = useMemo(
		() =>
			Object.values(game.pdfSheets?.byId ?? {}).find(
				(sheet): sheet is PdfSheet => sheet?.collection?.type === document.type,
			),
		[game.pdfSheets?.byId, document.type],
	)

	const { fieldMappings } = pdfSheet?.collection || {}

	const lastDocumentRef = useRef(document)
	useEffect(() => {
		lastDocumentRef.current = document
	}, [document])

	const { loading, error, data } = useQuery<{ getResource: BookResource }>(
		GET_RESOURCE,
		{
			variables: { resourceId: pdfSheet?.resourceId },
			skip: !pdfSheet?.resourceId,
		},
	)

	const handleFormChange = (values: Record<string, any>) => {
		if (!values || Object.keys(values).length === 0) {
			return
		}

		const formValues = { ...values }
		let name = document.values.name
		let token = document.values.token

		if (fieldMappings?.name && values[fieldMappings.name]) {
			name = values[fieldMappings.name]
		}

		// Handle token/portrait mapping
		const tokenField = pdfSheet?.collection?.thumbnailField?.[0]
		if (tokenField && values[tokenField]) {
			token = values[tokenField]
		}

		if (!lastDocumentRef.current) {
			console.warn('[usePdfSheet] Document no longer exists:', document._id)
			return
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: {
					_id: lastDocumentRef.current._id,
					type: lastDocumentRef.current.type,
					creator: lastDocumentRef.current.creator,
					access: lastDocumentRef.current.access,
					accessList: lastDocumentRef.current.accessList,
					version: lastDocumentRef.current.version + 1,
					values: {
						...lastDocumentRef.current.values,
						name,
						token,
						formValues,
					},
					parentId: lastDocumentRef.current.parentId,
				},
			},
		})
	}

	return {
		pdfSheet,
		loading,
		error,
		resource: data?.getResource,
		handleFormChange,
	}
}
