import Color from 'color'
import { User } from 'lucide-react'
import { useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useGetUserSettings from '../hooks/useGetUserSettings'
import useTheme from '../hooks/useTheme'
import { darkenColor } from '../utils/colors'

interface AvatarProps {
	color?: string
	src?: string
	className?: string
	style?: React.CSSProperties
	userId?: string
	userName?: string
	noBorder?: boolean
	size?: 'sm' | 'md' | 'lg' | 'xl'
}

const sizeMap = {
	sm: {
		height: 'h-7',
		textSize: 'text-[10px]',
		dimensions: '1.75rem',
		iconSize: 'h-4 w-4',
		borderWidth: 2,
	},
	md: {
		height: 'h-9',
		textSize: 'text-[13px]',
		dimensions: '2.25rem',
		iconSize: 'h-5 w-5',
		borderWidth: 1.5,
	},
	lg: {
		height: 'h-12',
		textSize: 'text-[14px]',
		dimensions: '3rem',
		iconSize: 'h-5 w-5',
		borderWidth: 3,
	},
	xl: {
		height: 'h-20',
		textSize: 'text-[16px]',
		dimensions: '5rem',
		iconSize: 'h-6 w-6',
		borderWidth: 3,
	},
} as const

const Avatar: React.FC<AvatarProps> = ({
	color = '#fff',
	src,
	className,
	style,
	userId,
	userName,
	noBorder,
	size = 'sm',
}) => {
	const { primary } = useTheme()
	const [imageError, setImageError] = useState(false)
	const [imageLoaded, setImageLoaded] = useState(false)
	const lighterColor = useMemo(() => Color(color).string(), [color])
	const backgroundColor = useMemo(() => darkenColor(color), [color])
	const { userSettings } = useGetUserSettings(userId)
	const isGM = userSettings?.role === 'gm'

	// Image preloading with absolute URL
	useMemo(() => {
		if (src) {
			const absoluteUrl = new URL(src, window.location.href).href
			const img = new Image()
			img.onload = () => {
				setImageError(false)
				setImageLoaded(true)
			}
			img.onerror = () => {
				setImageError(true)
				setImageLoaded(false)
			}
			img.src = absoluteUrl

			return () => {
				img.onload = null
				img.onerror = null
			}
		} else {
			setImageLoaded(false)
			setImageError(false)
		}
	}, [src])

	const getInitials = (name: string) => {
		const nameParts = name.split(' ')
		if (nameParts.length > 1) {
			return (nameParts[0][0] + nameParts[1][0]).toUpperCase()
		} else {
			return name.substring(0, 2).toUpperCase()
		}
	}

	const avatarContent = useMemo(() => {
		if (src && !imageError && imageLoaded) {
			return null
		}

		let content: string | JSX.Element

		if (userName) {
			content = getInitials(userName)
		} else if (isGM) {
			content = 'GM'
		} else {
			content = (
				<User
					className={`${sizeMap[size].iconSize} text-${primary}`}
					aria-hidden='true'
				/>
			)
		}

		return (
			<div
				className={twMerge('font-bold tracking-wider', sizeMap[size].textSize)}
				style={{
					color: lighterColor,
				}}
			>
				{content}
			</div>
		)
	}, [
		src,
		userName,
		isGM,
		primary,
		size,
		lighterColor,
		imageError,
		imageLoaded,
	])

	return (
		<div
			className={twMerge(
				`relative flex aspect-square flex-none place-items-center justify-center overflow-hidden rounded-full bg-gray-50 text-center leading-10 text-gray-600`,
				sizeMap[size].height,
				className,
			)}
			style={{
				backgroundColor,
				backgroundImage:
					src && !imageError && imageLoaded
						? `url("${new URL(src, window.location.href).href}")`
						: undefined,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				borderWidth: noBorder ? 0 : sizeMap[size].borderWidth,
				borderColor: noBorder ? undefined : color,
				minWidth: sizeMap[size].dimensions,
				minHeight: sizeMap[size].dimensions,
				...style,
			}}
		>
			{avatarContent}
		</div>
	)
}

export default Avatar
