import { PanelLeft } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useQueue } from '../../../../contexts/queue'
import Popover from '../../../Popover'
import ToolButton from '../../../interface/toolbar/ToolButton'
import UploadProgress from '../../../resources/UploadProgress'
import AddBookButton from './AddBookButton'

interface Props {
	selectMode: boolean
	setSelectMode: (selectMode: boolean) => void
	onToggleSidebar: () => void
	sidebarOpen: boolean
	containerRef: React.RefObject<HTMLDivElement>
	totalBooks: number
	filteredBooks: number
}

const BookHeader = ({
	selectMode,
	setSelectMode,
	onToggleSidebar,
	sidebarOpen,
	containerRef,
	totalBooks,
	filteredBooks,
}: Props) => {
	const { queueLength, items } = useQueue()
	const [showProgress, setShowProgress] = useState(false)
	const statusRef = useRef<HTMLDivElement>(null)

	const handleTitleClick = () => {
		if (!containerRef.current) return

		const rect = containerRef.current.getBoundingClientRect()
		const isAtTop = rect.top <= 0

		if (isAtTop) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		} else {
			containerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	// Hide popover when queue is empty
	useEffect(() => {
		const hasActiveItems = Object.values(items).some(
			item => item.status === 'uploading' || item.status === 'processing',
		)
		if (!hasActiveItems && queueLength === 0) {
			setShowProgress(false)
		}
	}, [items, queueLength])

	const calculateOverallProgress = () => {
		const activeItems = Object.values(items).filter(
			item => item.status === 'uploading' || item.status === 'processing',
		)

		if (activeItems.length === 0) return 0

		const totalProgress = activeItems.reduce((acc, item) => {
			const progress =
				item.status === 'uploading'
					? item.uploadProgress ?? 0
					: item.processingProgress ?? 0
			return acc + progress
		}, 0)

		return Math.round(totalProgress / activeItems.length)
	}

	const getStatusText = () => {
		const uploadingCount = Object.values(items).filter(
			item => item.status === 'uploading',
		).length
		const processingCount = Object.values(items).filter(
			item => item.status === 'processing',
		).length
		const overallProgress = calculateOverallProgress()
		const totalCount = Object.keys(items).length

		if (uploadingCount > 0) {
			return (
				<div className='flex items-center gap-2 text-sm text-gray-500'>
					<div className='flex items-center gap-2'>
						<div className='h-3 w-3 animate-spin rounded-full border-2 border-blue-500 border-t-transparent' />
						<span>
							Uploading {uploadingCount} of {totalCount}
						</span>
					</div>
					<div className='ml-2 h-1.5 w-24 rounded-full bg-gray-700/50'>
						<div
							className='h-1.5 rounded-full bg-blue-500 transition-all duration-300'
							style={{ width: `${overallProgress}%` }}
						/>
					</div>
				</div>
			)
		}

		if (processingCount > 0) {
			return (
				<div className='flex items-center gap-2 text-sm text-gray-500'>
					<div className='flex items-center gap-2'>
						<div className='h-3 w-3 animate-spin rounded-full border-2 border-green-500 border-t-transparent' />
						<span>
							Processing {processingCount} of {totalCount}
						</span>
					</div>
					<div className='ml-2 h-1.5 w-24 rounded-full bg-gray-700/50'>
						<div
							className='h-1.5 rounded-full bg-green-500 transition-all duration-300'
							style={{ width: `${overallProgress}%` }}
						/>
					</div>
				</div>
			)
		}

		if (queueLength > 0) {
			return (
				<div className='flex items-center gap-2 text-sm text-gray-500'>
					<span>
						{queueLength} file{queueLength !== 1 ? 's' : ''} queued
					</span>
					<div className='ml-2 h-1.5 w-24 rounded-full bg-gray-700/50'>
						<div
							className='h-1.5 rounded-full bg-gray-500 transition-all duration-300'
							style={{ width: `${overallProgress}%` }}
						/>
					</div>
				</div>
			)
		}
		return null
	}

	const statusText = getStatusText()

	return (
		<div className='flex items-center justify-between'>
			<div className='flex items-center gap-4'>
				<ToolButton
					onClick={onToggleSidebar}
					tooltip='Toggle Sidebar'
					isOpen={sidebarOpen}
					className='text-white hover:bg-gray-700'
				>
					<PanelLeft />
				</ToolButton>
				<div className='flex flex-col items-start'>
					<button
						onClick={handleTitleClick}
						className='text-lg font-medium text-white hover:text-gray-300'
					>
						Books
					</button>
					{filteredBooks < totalBooks ? (
						<span className='text-xs text-gray-500'>
							Showing {filteredBooks} of {totalBooks} books
						</span>
					) : (
						<span className='text-xs text-gray-500'>{totalBooks} books</span>
					)}
				</div>
				{statusText && (
					<div
						ref={statusRef}
						onClick={() => setShowProgress(prev => !prev)}
						className='cursor-pointer hover:text-gray-400'
					>
						{statusText}
					</div>
				)}
				<Popover
					id='upload-progress'
					open={showProgress}
					anchor={statusRef}
					onClose={() => setShowProgress(false)}
					placement='bottom-start'
					className='!p-0'
				>
					<UploadProgress />
				</Popover>
			</div>
			<div className='flex items-center gap-4'>
				<AddBookButton />
				{!selectMode && (
					<button
						onClick={() => setSelectMode(true)}
						className='text-sm text-gray-500 hover:text-gray-700'
					>
						Select
					</button>
				)}
			</div>
		</div>
	)
}

export default BookHeader
