import { useCallback, useState } from 'react'
import { IDocuments } from '../../../shared/types/document'

interface UseDocumentDragProps {
	documents: IDocuments
	dispatch: (action: any) => void
}

export const useDocumentDrag = ({
	documents,
	dispatch,
}: UseDocumentDragProps) => {
	const [dragIndex, setDragIndex] = useState(-1)
	const [hoverIndex, setHoverIndex] = useState(-1)

	const moveItem = useCallback(
		({ id, targetId, parentId, before = true }) => {
			const documentIndex = documents.allIds.indexOf(id)
			const targetIndex = documents.allIds.indexOf(targetId)

			if (documentIndex < 0 || targetIndex < 0) {
				console.error('Document or target not found!')
				return
			}

			const newAllIds = [...documents.allIds]
			newAllIds.splice(documentIndex, 1)
			const newIndex = before ? targetIndex : targetIndex + 1
			newAllIds.splice(newIndex, 0, id)

			dispatch({
				type: 'REORDER_DOCUMENTS',
				payload: {
					documentIds: newAllIds,
					documentId: id,
					parentId,
				},
			})
		},
		[documents.allIds, dispatch],
	)

	const hoverItem = useCallback(
		(dragId: string, hoverId: string, before?: 'up' | 'down') => {
			const dragIndex = documents.allIds.findIndex(id => id === dragId)
			const hoverIndex = documents.allIds.findIndex(id => id === hoverId)

			setDragIndex(dragIndex)
			setHoverIndex(before === 'up' ? hoverIndex : hoverIndex + 1)
		},
		[documents.allIds],
	)

	return {
		dragIndex,
		hoverIndex,
		moveItem,
		hoverItem,
	}
}
