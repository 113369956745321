import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, ReactNode } from 'react'

interface SlideoverPanelProps {
	open: boolean
	onClose: () => void
	title: string
	children: ReactNode
}

export default function SlideoverPanel({
	open,
	onClose,
	title,
	children,
}: SlideoverPanelProps) {
	const handleClose = () => {
		// The parent will handle the actual state change
		onClose()
	}

	return (
		<Transition
			appear
			show={open}
			as={Fragment}
			afterLeave={() => handleClose()}
		>
			<Dialog as='div' className='relative z-10' onClose={() => handleClose()}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-hidden'>
					<div className='absolute inset-0 overflow-hidden'>
						<div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
							<Transition.Child
								as={Fragment}
								enter='transform transition ease-in-out duration-500'
								enterFrom='translate-x-full'
								enterTo='translate-x-0'
								leave='transform transition ease-in-out duration-500'
								leaveFrom='translate-x-0'
								leaveTo='translate-x-full'
							>
								<Dialog.Panel className='pointer-events-auto relative w-screen max-w-2xl transform transition-all'>
									<div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
										<div className='px-4 sm:px-6'>
											<div className='flex items-start justify-between'>
												<Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
													{title}
												</Dialog.Title>
												<div className='ml-3 flex h-7 items-center'>
													<button
														type='button'
														className='rounded-md bg-white text-gray-400 hover:text-gray-500'
														onClick={() => handleClose()}
													>
														<span className='sr-only'>Close panel</span>
														<XMarkIcon className='h-6 w-6' aria-hidden='true' />
													</button>
												</div>
											</div>
										</div>
										<div className='relative mt-6 flex-1 px-4 sm:px-6'>
											{children}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
