import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { IBook } from '../../../../../shared/types/book'
import useHandleShareBookWith from '../../../hooks/useHandleShareBookWith'
import useItemFilter from '../../../hooks/UseItemFilter'
import useUser from '../../../hooks/useUser'
import RadioItem from '../../dropdownmenu/RadioItem'
import SubMenuContent from '../../dropdownmenu/SubMenuContent'
import SubMenuTrigger from '../../dropdownmenu/SubMenuTrigger'

type Props = {
	book: IBook
}

const Permissions = ({ book }: Props) => {
	const { isGM } = useUser()
	const { access } = book
	const { checkAccess } = useItemFilter()
	const hasAccess = checkAccess(book)
	const { handleSharePublic, handleSharePrivate } = useHandleShareBookWith()

	const handleAccessChange = (value: string) => {
		if (value === 'private') {
			handleSharePrivate(book._id)
		} else {
			handleSharePublic(book._id)
		}
	}

	if (!hasAccess && !isGM) return null

	return (
		<>
			<DropdownMenu.Sub>
				<SubMenuTrigger>Share</SubMenuTrigger>

				<SubMenuContent>
					<DropdownMenu.RadioGroup
						value={access}
						onValueChange={handleAccessChange}
					>
						<RadioItem value={'private'} disabled={!isGM}>
							Private
						</RadioItem>
						<RadioItem value={'public'}>Public</RadioItem>
					</DropdownMenu.RadioGroup>
				</SubMenuContent>
			</DropdownMenu.Sub>
		</>
	)
}

export default Permissions
