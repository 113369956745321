import { FC } from 'react'

interface BookSelectionToolbarProps {
	selectedIds: string[]
	onAddToGame: () => void
	onDelete: () => void
	onSelectAll: () => void
	onDeselectAll: () => void
	onDone: () => void
}

const BookSelectionToolbar: FC<BookSelectionToolbarProps> = ({
	selectedIds,
	onAddToGame,
	onDelete,
	onSelectAll,
	onDeselectAll,
	onDone,
}) => {
	return (
		<div className='flex h-10 items-center justify-between'>
			<div className='space-x-4'>
				<button className='mr-8' onClick={onDone}>
					Done
				</button>
				<button onClick={onSelectAll}>Select All</button>
				<button onClick={onDeselectAll}>Select None</button>
			</div>

			<div className='space-x-4'>
				<button onClick={onAddToGame} disabled={selectedIds.length === 0}>
					Add to a Game...
				</button>
				<button onClick={onDelete} disabled={selectedIds.length === 0}>
					Delete
				</button>
			</div>
		</div>
	)
}

export default BookSelectionToolbar
