import { PdfViewerState } from '../../../shared/types/book'

export const getInitialState = (id: string): PdfViewerState => ({
	resource: null,
	name: 'Loading...',
	currentPage: 1,
	numPages: null,
	renderedPages: [],
	onRenderSuccess: () => {},
	pagesToDisplay: parseInt(
		localStorage.getItem(`${id}_pagesToDisplay`) || '2',
		10,
	),
	isCoverAlone: JSON.parse(
		localStorage.getItem(`${id}_isCoverAlone`) || 'false',
	),
	searchText: '',
	fitWidth: false,
	fitPage: true,
	isOutlineVisible: JSON.parse(
		localStorage.getItem(`${id}_outlineVisible`) || 'false',
	),
	isThumbnailsVisible: JSON.parse(
		localStorage.getItem(`${id}_thumbnailVisible`) || 'false',
	),
	loading: [],
	zoomLevel: parseFloat(localStorage.getItem(`${id}_zoomLevel`) || '1'),
	isReady: false,
	isLoading: false,
})
