import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { Books } from '../../../../shared/types/book'
import { IDocuments } from '../../../../shared/types/document'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import { useDocumentDrag } from '../../hooks/useDocumentDrag'
import { useGame } from '../../hooks/useGame'
import useRemoteRequestToOpenDocument from '../../hooks/useRemoteRequestToOpenDocument'
import useUser from '../../hooks/useUser'
import Book from '../books/Book'
import Panel from '../interface/Panel'
import SectionDivider from '../SectionDivider'
import ListMessage from '../views/ListMessage'
import AddDocumentButton from './AddDocumentButton'
import DocumentList from './DocumentList'
import DocumentTypeList from './DocumentTypeList'
import useUnpop from './useUnpop'

export type THoverItem = (
	dragIndexPath: number[],
	hoverIndexPath: number[],
) => void

export type TDragItem = {
	id: string
	indexPath: number[]
	children?: TDragItem[]
}

interface DocumentGroup {
	[key: string]: any[]
}

const groupDocumentsByType = (
	documents: any,
	collectionTypes: any[],
): DocumentGroup => {
	const groups: DocumentGroup = {}
	collectionTypes.forEach(type => {
		groups[type.type] = []
	})

	documents.allIds.forEach((id: string) => {
		const doc = documents.byId[id]
		if (groups[doc.type]) {
			groups[doc.type].push(doc)
		}
	})

	return groups
}

const Library = () => {
	const { game, dispatch } = useGame()
	const { _id: gameId, documents, books } = game
	const { control, setValue } = useFormContext()
	const { dragIndex, hoverIndex, moveItem, hoverItem } = useDocumentDrag({
		documents,
		dispatch,
	})
	const { userId, role } = useUser()
	const { showLibrary } = useUser()
	const collectionTypes = useCollectionTypes()
	useUnpop()
	useRemoteRequestToOpenDocument()
	const [showFilters] = useState(true)

	const storageKey = `selectedType_${gameId}`
	const storedType = localStorage.getItem(storageKey)

	useEffect(() => {
		if (storedType) {
			setValue('selection', storedType)
		}
	}, [setValue, storedType])

	const selectedType = useWatch({
		control,
		name: 'selection',
		defaultValue: storedType || 'All',
	})

	useEffect(() => {
		localStorage.setItem(storageKey, selectedType)
	}, [selectedType, storageKey])

	const hasAccess = useCallback(
		(items: IDocuments | Books) => {
			const isGM = role === 'gm'
			return items.allIds.some(itemId => {
				const item = items.byId[itemId]
				const { access, accessList } = item
				const isPublic = access === 'public'
				return isGM || isPublic || accessList?.includes(userId)
			})
		},
		[role, userId],
	)

	const hasAccessToDocuments = useMemo(
		() => hasAccess(documents),
		[documents, hasAccess],
	)

	const hasAccessToBooks = useMemo(() => hasAccess(books), [books, hasAccess])

	const header = useMemo(
		() => (
			<div className='flex flex-col gap-3'>
				<SectionDivider
					label='Documents'
					children={
						<>
							<AddDocumentButton />
						</>
					}
				/>

				{showFilters && <DocumentTypeList />}
			</div>
		),
		[showFilters],
	)

	const groupedDocuments = useMemo(
		() => groupDocumentsByType(documents, collectionTypes),
		[documents, collectionTypes],
	)

	// Fixed width to 312px, two columns, stable height
	const booksList = useMemo(
		() => (
			<div style={{ width: '312px' }}>
				<ul
					className={twMerge('grid grid-cols-2 gap-2')}
					style={{
						gridAutoRows: '200px', // Stable height for each row
					}}
				>
					{!hasAccessToBooks ? (
						<ListMessage title='No Books' />
					) : (
						books.allIds.map(bookId => (
							<li key={bookId} className='relative flex flex-col justify-end'>
								<Book bookId={bookId} />
							</li>
						))
					)}
				</ul>
			</div>
		),
		[books.allIds, hasAccessToBooks],
	)

	if (!showLibrary) return null

	return (
		<Panel header={header}>
			{selectedType === 'Books' && hasAccessToBooks ? (
				booksList
			) : (
				<>
					{!hasAccessToDocuments ? (
						<ListMessage title='No Documents' />
					) : (
						<DocumentList
							selectedType={selectedType}
							groupedDocuments={groupedDocuments}
							collectionTypes={collectionTypes}
							dragIndex={dragIndex}
							hoverIndex={hoverIndex}
							hoverItem={hoverItem}
							moveItem={moveItem}
						/>
					)}
				</>
			)}
		</Panel>
	)
}

export default Library
