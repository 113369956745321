import { FC, useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import AuthContext from '../../contexts/auth'
import GameContext from '../../contexts/game'
import GameCard from '../pages/elements/GameCard'
import SectionDivider from '../SectionDivider'
import SidebarModalWindow from '../window/SidebarModalWindow'
import CoverImage from './CoverImage'
import DevelopmentMode from './DevelopmentMode'
import Logo from './Logo'
import SettingsGameName from './SettingsGameName'
import SettingsPDFSheets from './SettingsPDFSheets'
import SystemInfo from './SystemInfo'
import SystemLoader from './SystemLoader'
import SystemReset from './SystemReset'

interface ISettings {
	onClose: () => void
}

const Settings: FC<ISettings> = ({ onClose }) => {
	const { game } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const [enableClickOutside, setEnableClickOutside] = useState(true)
	const [isOpen, setIsOpen] = useState(true)
	const isAdmin = authState.userDetails.isAdmin

	const methods = useForm({
		defaultValues: {
			title: game.title,
			description: game.description,
		},
	})

	const handleClose = () => {
		if (methods.formState.isDirty) {
			const confirmClose = window.confirm(
				'You have unsaved changes. Are you sure you want to close?',
			)
			if (!confirmClose) {
				return
			}
		}
		setIsOpen(false)
	}

	// Handle actual unmounting after transition
	const handleTransitionEnd = () => {
		if (!isOpen) {
			onClose()
		}
	}

	const tabs = [
		{
			id: 'game',
			label: 'Game',
			content: (
				<div>
					{/* <SectionDivider className='mt-4' label='Game Card Preview' /> */}
					<GameCard className='bg-gray-800' game={game} />

					<SectionDivider className='mt-4' label='Game Information' />
					<SettingsGameName />
					<CoverImage />
					<Logo />
				</div>
			),
		},
		{
			id: 'sheets',
			label: 'Sheets',
			content: (
				<div>
					<SectionDivider className='mt-4' label='PDF Sheets' />
					<SettingsPDFSheets setEnableClickOutside={setEnableClickOutside} />
				</div>
			),
		},
		...(isAdmin
			? [
					{
						id: 'advanced',
						label: 'Advanced',
						content: (
							<div>
								<SectionDivider label='System Loader (Advanced)' />
								<SystemLoader onClose={onClose} />
								<SystemInfo />

								<SectionDivider
									className='mt-4'
									label='Development Mode (Advanced)'
								/>
								<DevelopmentMode
									setEnableClickOutside={setEnableClickOutside}
								/>

								<SectionDivider className='mt-4 mb-4' label='Danger Zone' />
								<SystemReset />
							</div>
						),
					},
			  ]
			: []),
	]

	return (
		<FormProvider {...methods}>
			<SidebarModalWindow
				id='settings'
				title='Game Settings'
				onClose={handleClose}
				size='large'
				enableClickOutside={enableClickOutside}
				tabs={tabs}
				isOpen={isOpen}
				onTransitionEnd={handleTransitionEnd}
			/>
		</FormProvider>
	)
}

export default Settings
