import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import {
	BookMetadata,
	IResource,
	ResourceType,
} from '../../../shared/types/resources'
import usePdfThumbnail from '../components/resources/hooks/usePdfThumbnail'
import AuthContext from '../contexts/auth'
import { ResourceContext } from '../contexts/resources'
import { CREATE_RESOURCE } from '../graphql/resources'
import useFileUpload, { FileUploadResult } from './useFileUpload'

const emptyBookMetadata: BookMetadata = {
	title: '',
	description: '',
	tags: [],
	categories: [],
	game: '',
	ruleSystem: '',
	authors: [],
	contributingArtists: [],
	publisher: '',
	year: '',
	edition: '',
	isbn: '',
	pageCount: 0,
	language: '',
	copyright: {
		year: '',
		owner: '',
	},
	series: '',
	seriesNumber: 0,
	relatedBooks: [],
	requiredBooks: [],
	contentWarnings: [],
	playerCount: {
		min: 0,
		max: 0,
	},
	recommendedAgeRange: {
		min: 0,
		max: 0,
	},
	playTime: {
		min: 0,
		max: 0,
	},
	tableOfContents: [],
	errata: [],
}

const emptyImageMetadata = {
	width: 0,
	height: 0,
	description: '',
	tags: [],
}

const emptyVideoMetadata = {
	width: 0,
	height: 0,
	duration: 0,
	description: '',
	tags: [],
}

const emptyOtherMetadata = {
	description: '',
	tags: [],
}

const useResourceUpload = () => {
	const { dispatchResource } = useContext(ResourceContext)
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const fileUpload = useFileUpload()
	const { createThumbnail } = usePdfThumbnail()
	const [createResourceMutation] = useMutation(CREATE_RESOURCE, {
		onError: error => {
			console.error('Error creating resource', error)
		},
	})

	const getResourceType = (fileType: string): ResourceType => {
		if (fileType.startsWith('image/')) return 'image'
		if (fileType.startsWith('video/')) return 'video'
		if (fileType === 'application/pdf') return 'book'
		return 'other'
	}

	const handleFileUpload = async (
		file: File,
		givenResourceType?: ResourceType,
		onProgress?: (percent: number) => void,
	) => {
		try {
			console.log('ResourceUpload: Starting upload', {
				fileName: file.name,
				fileSize: file.size,
				fileType: file.type,
				givenResourceType,
			})

			if (!userId) {
				throw new Error('User not authenticated')
			}

			// Determine resource type first
			const resourceType = givenResourceType || getResourceType(file.type)
			console.log('ResourceUpload: Determined resource type:', resourceType)

			// Upload the main file first
			console.log('ResourceUpload: Uploading main file...')
			const uploadResult: FileUploadResult = await fileUpload(file, onProgress)
			console.log(
				'ResourceUpload: Main file uploaded successfully',
				uploadResult,
			)

			// Only create thumbnail for PDFs, use the file itself for images
			let thumbnailUpload
			if (resourceType === 'book' || resourceType === 'sheet') {
				console.log('ResourceUpload: Creating PDF thumbnail...')
				const thumbnailFile = await createThumbnail(file)
				thumbnailUpload = await fileUpload(thumbnailFile)
				console.log(
					'ResourceUpload: PDF thumbnail created and uploaded',
					thumbnailUpload,
				)
			} else if (resourceType === 'image') {
				thumbnailUpload = uploadResult
				console.log('ResourceUpload: Using image as its own thumbnail')
			} else {
				thumbnailUpload = { fileurl: '' }
				console.log(
					'ResourceUpload: No thumbnail needed for this resource type',
				)
			}

			// Handle metadata based on resource type
			let metadata = {}

			switch (resourceType) {
				case 'image': {
					const img = new Image()
					img.src = URL.createObjectURL(file)
					await new Promise(resolve => (img.onload = resolve))
					metadata = {
						...emptyImageMetadata,
						width: img.width,
						height: img.height,
					}
					break
				}
				case 'book':
				case 'sheet':
					metadata = {
						...emptyBookMetadata,
						title: file.name.replace(/\.[^/.]+$/, ''),
					}
					break
				case 'video': {
					const video = document.createElement('video')
					video.preload = 'metadata'
					video.src = URL.createObjectURL(file)
					await new Promise(resolve => (video.onloadedmetadata = resolve))
					metadata = {
						...emptyVideoMetadata,
						width: video.videoWidth,
						height: video.videoHeight,
						duration: Math.round(video.duration),
					}
					break
				}
				case 'other':
					metadata = emptyOtherMetadata
					break
				default:
					throw new Error(`Unsupported resource type: ${resourceType}`)
			}

			const resourceInput = {
				name: file.name,
				fileurl: uploadResult.fileurl,
				filesize: file.size,
				filetype: file.type,
				thumbnailurl: thumbnailUpload.fileurl,
				resourceType,
				metadata,
				creator: userId,
			}

			console.log('Creating resource with input:', resourceInput)
			const response = await createResourceMutation({
				variables: { resourceInput },
			}).catch(error => {
				console.error('GraphQL mutation failed:', error)
				throw error
			})

			const resource: IResource = response.data.createResource
			console.log('Resource created successfully:', resource)

			dispatchResource({
				type: 'ADD_RESOURCE',
				payload: { resource },
			})

			console.log('ResourceUpload: Upload complete for', file.name)

			return resource
		} catch (error) {
			console.error('ResourceUpload: Error in upload process:', {
				error,
				errorMessage: error.message,
				errorStack: error.stack,
				fileName: file.name,
			})
			throw error
		}
	}

	return handleFileUpload
}

export default useResourceUpload
