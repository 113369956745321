interface PDFPageInfoProps {
	pageNumber: number
	pagesCount: number
	pageLabels?: Array<{ physicalPage: number; pageLabel: string }>
	title?: string
}

const PDFPageInfo = ({
	pageNumber,
	pagesCount,
	pageLabels = [],
	title,
}: PDFPageInfoProps) => {
	const formatPageLabel = (label: string) => {
		// If it's a number, format as "Page X"
		if (!isNaN(parseInt(label))) {
			return `Page ${label}`
		}
		// If it contains a number at the end after some text, format the number part
		const match = label.match(/^(.+?)(\d+)$/)
		if (match) {
			return `${match[1]}Page ${match[2]}`
		}
		// Otherwise return as is
		return label
	}

	const getCurrentPageLabel = () => {
		if (!pageLabels.length) return `Page ${pageNumber}`

		const label =
			pageLabels.find(p => p.physicalPage === pageNumber)?.pageLabel ||
			String(pageNumber)
		return formatPageLabel(label)
	}

	return (
		<div>
			{title && (
				<div className='line-clamp-1 text-ellipsis text-sm font-semibold'>
					{title}
				</div>
			)}
			<div className='text-xs text-gray-500'>
				{getCurrentPageLabel()} of {pagesCount}
			</div>
		</div>
	)
}

export default PDFPageInfo
