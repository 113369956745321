import {
	PDFButton,
	PDFDocument,
	PDFDropdown,
	PDFRadioGroup,
	PDFTextField,
} from 'pdf-lib'
import { PDFDocumentProxy } from 'pdfjs-dist'
import { PdfFormField, PdfFormFieldType } from '../../../shared/types/pdf'

export const usePdfFormExtractor = () => {
	const extractFormFields = async (
		pdf: PDFDocumentProxy,
	): Promise<PdfFormField[]> => {
		try {
			const pdfData = await pdf.getData()
			const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true })
			const form = pdfDoc.getForm()
			const fields = form.getFields()

			const extractedFields = await Promise.all(
				fields.map(async field => {
					const widget = field.acroField.getWidgets()[0]
					const page =
						pdfDoc.getPages().findIndex(p => p.ref === widget.P()) + 1
					const rect = widget.getRectangle()

					let fieldType: PdfFormFieldType
					if (
						field instanceof PDFTextField ||
						field.acroField.constructor.name.includes('AcroText')
					) {
						fieldType = 'PDFTextField'
					} else if (
						field instanceof PDFDropdown ||
						field.acroField.constructor.name.includes('ComboBox') ||
						field.acroField.constructor.name.includes('ListBox')
					) {
						fieldType = 'PDFDropdown'
					} else if (
						field instanceof PDFButton ||
						field.acroField.constructor.name.includes('CheckBox') ||
						field.acroField.constructor.name.includes('AcroCheckBox')
					) {
						fieldType = 'PDFCheckBox'
					} else if (field instanceof PDFRadioGroup) {
						fieldType = 'PDFRadioGroup'
					} else {
						console.warn('Unknown field type:', field.constructor.name)
						return null
					}

					const baseField: PdfFormField = {
						name: field.getName(),
						type: fieldType,
						page,
						rect: {
							x: rect.x,
							y: rect.y,
							width: rect.width,
							height: rect.height,
						},
						readOnly: field.acroField.hasFlag(0),
						required: field.acroField.hasFlag(1),
						noExport: field.acroField.hasFlag(3),
					}

					if (field instanceof PDFButton) {
						const widgets = field.acroField.getWidgets()
						if (widgets.length > 0) {
							const widget = widgets[0]
							const normalAppearance = widget.getAppearanceState()
							const hasAppearance = normalAppearance !== null

							if (hasAppearance) {
								return {
									...baseField,
									type: 'PDFImageField' as const,
									isImageButton: true,
								}
							}
						}
						return null
					}

					if (field instanceof PDFTextField) {
						if (field.acroField.hasFlag(21)) {
							return null
						}
						return {
							...baseField,
							maxLength: field.getMaxLength(),
							multiline: field.acroField.hasFlag(13),
							isPassword: field.acroField.hasFlag(14),
							isRichText: field.acroField.hasFlag(25),
							isScrollable: field.acroField.hasFlag(24),
							isCombed: field.acroField.hasFlag(28),
						}
					}

					if (field instanceof PDFDropdown) {
						return {
							...baseField,
							options: field.getOptions(),
							isMultiSelect: field.acroField.hasFlag(22),
							isEditable: field.acroField.hasFlag(19),
							isSorted: field.acroField.hasFlag(20),
							isSpellChecked: !field.acroField.hasFlag(23),
							selectOnClick: field.acroField.hasFlag(27),
						}
					}

					if (field instanceof PDFRadioGroup) {
						return {
							...baseField,
							options: field.getOptions(),
							exportValue: field.getOptions()[0],
						}
					}

					return baseField
				}),
			)

			return extractedFields.filter(
				(field): field is PdfFormField => field !== null,
			)
		} catch (error) {
			console.error('Error extracting form fields:', error)
			return []
		}
	}

	return {
		extractFormFields,
	}
}
