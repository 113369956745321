import React from 'react'
import BookItem from '../../../books/BookItem'
import { BooksResources } from '../BooksPage'

interface BookListItemsProps {
	resources: BooksResources
	selectMode?: boolean
	selectedIds?: string[]
	onSelect?: (id: string, selected: boolean) => void
}

const BookListItems: React.FC<BookListItemsProps> = ({
	resources,
	selectMode,
	selectedIds = [],
	onSelect,
}) => (
	<ul className='grid grid-cols-2 gap-8 transition-all duration-500 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8'>
		{Object.entries(resources).map(([id, resource]) => (
			<div key={id} className='flex items-end'>
				<BookItem
					resource={resource}
					selectMode={selectMode}
					selected={selectedIds.includes(resource._id)}
					onSelect={selected => onSelect && onSelect(resource._id, selected)}
					className='text-white'
				/>
			</div>
		))}
	</ul>
)

export default BookListItems
