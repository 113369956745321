import React from 'react'

interface D20IconProps extends React.SVGProps<SVGSVGElement> {
	fill?: string
	stroke?: string
}

const D20Icon: React.FC<D20IconProps> = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	...props
}) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 595.28 595.28'
		{...props}
	>
		<path
			d='M53.68,413.94v-232.6c0-15.19,8.11-29.23,21.26-36.82L276.38,28.22c13.16-7.6,29.36-7.6,42.52,0l201.44,116.3c13.16,7.6,21.26,21.63,21.26,36.82v232.6c0,15.19-8.1,29.23-21.26,36.82l-201.44,116.3c-13.16,7.6-29.36,7.6-42.52,0l-201.44-116.3c-13.16-7.6-21.26-21.63-21.26-36.82Z'
			fill={fill}
			stroke={stroke}
			strokeWidth='22.11'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<g>
			<path
				d='M64.72,163.16l89.21,217.44,143.7,185.98,143.7-185.98,89.22-217.44-232.92-31.46-232.92,31.46ZM441.34,380.6H153.94s143.7-248.9,143.7-248.9l143.7,248.9Z'
				fill='none'
				stroke={stroke}
				strokeWidth='10.69'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='530.55'
				y1='432.11'
				x2='441.34'
				y2='380.6'
				stroke={stroke}
				strokeWidth='10.69'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='153.94'
				y1='380.6'
				x2='64.72'
				y2='432.11'
				stroke={stroke}
				strokeWidth='10.69'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<line
				x1='297.64'
				y1='28.69'
				x2='297.64'
				y2='131.71'
				stroke={stroke}
				strokeWidth='10.69'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
	</svg>
)

export default D20Icon
