import { Dialog, Transition } from '@headlessui/react'
import { Fragment, ReactNode, useRef } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'
import CloseButton from '../FormComponents/CloseButton'
import { INTERFACE_DROPSHADOW } from '../interface/constants'
import DialogBody from './Body'
import DialogFooter from './Footer'
import DialogHeader from './Header'

type ModalSize = 'small' | 'medium' | 'large' | 'full'

interface Dimensions {
	width: React.CSSProperties['width']
	maxWidth?: React.CSSProperties['maxWidth']
}

export interface IModal {
	id: string
	title: string
	children: React.ReactNode
	className?: string
	bodyClassName?: string
	innerBodyClassName?: string
	headerChildren?: React.ReactNode
	footerChildren?: React.ReactNode
	size?: ModalSize
	headerIcon?: ReactNode
	enableClickOutside?: boolean
	dynamicHeight?: boolean
	maxHeight?: string | number
	onClose?: () => void
	isOpen: boolean
	onTransitionEnd?: () => void
}

const ModalWindow = ({
	id,
	title,
	children,
	className,
	bodyClassName = '',
	innerBodyClassName = '',
	headerChildren,
	footerChildren,
	size = 'small',
	headerIcon = '',
	enableClickOutside = true,
	dynamicHeight = false,
	maxHeight,
	onClose = () => {},
	isOpen,
	onTransitionEnd,
}: IModal) => {
	const minW = 320
	const sizeMap: Record<ModalSize, Dimensions> = {
		small: { width: minW, maxWidth: '100%' },
		medium: { width: minW * 1.5, maxWidth: '100%' },
		large: { width: minW * 2.5, maxWidth: '100%' },
		full: { width: 'calc(100vw - 50px)' },
	}

	const modalRef = useRef<HTMLDivElement>(null)

	const handleClose = () => {
		onClose()
	}

	const passedHeaderChildren = () => {
		if (headerChildren) {
			return headerChildren
		} else {
			return <CloseButton onClick={handleClose} />
		}
	}

	const calculateDimensions = () => {
		const { width, maxWidth } = sizeMap[size]
		return {
			width,
			maxWidth,
			maxHeight: maxHeight || 'calc(100vh - 80px)',
			minHeight: dynamicHeight ? 200 : undefined,
		}
	}

	return createPortal(
		<Transition show={isOpen} as={Fragment} appear afterLeave={onTransitionEnd}>
			<Dialog
				as='div'
				className='fixed inset-0 z-[1000] overflow-y-auto'
				onClose={enableClickOutside ? handleClose : () => {}}
			>
				<div className='flex min-h-screen items-center justify-center'>
					<Transition
						as={Fragment}
						show={isOpen}
						enter='transition ease-out duration-500'
						enterFrom='transform opacity-0'
						enterTo='transform opacity-100'
						leave='transition ease-in duration-300'
						leaveFrom='transform opacity-100'
						leaveTo='transform opacity-0'
					>
						<div className='fixed inset-0 bg-black/70' />
					</Transition>

					<Transition
						as={Fragment}
						show={isOpen}
						enter='transition ease-out duration-700'
						enterFrom='transform opacity-0 scale-95 translate-y-8'
						enterTo='transform opacity-100 scale-100 translate-y-0'
						leave='transition ease-in duration-500'
						leaveFrom='transform opacity-100 scale-100 translate-y-0'
						leaveTo='transform opacity-0 scale-95 translate-y-8'
					>
						<div
							ref={modalRef}
							className={twMerge(
								'relative flex transform flex-col overflow-hidden rounded-2xl bg-gray-900 transition-all',
								dynamicHeight ? '' : 'h-full',
								className,
							)}
							style={{
								...calculateDimensions(),
								touchAction: 'none',
								boxShadow: INTERFACE_DROPSHADOW,
							}}
						>
							<DialogHeader title={title} headerIcon={headerIcon} titleId={id}>
								{passedHeaderChildren()}
							</DialogHeader>
							<DialogBody
								className={bodyClassName}
								innerClassName={innerBodyClassName}
							>
								{children}
							</DialogBody>
							{footerChildren && <DialogFooter>{footerChildren}</DialogFooter>}
						</div>
					</Transition>
				</div>
			</Dialog>
		</Transition>,
		document.body,
	)
}

export default ModalWindow
