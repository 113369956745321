import { PDFDocumentProxy } from 'pdfjs-dist'
import { PdfMetadata } from '../../../shared/types/pdf'

export const usePdfTechnicalExtractor = () => {
	const extractPdfMetadata = async (
		pdf: PDFDocumentProxy,
	): Promise<PdfMetadata> => {
		try {
			const metadata: PdfMetadata = {
				pageCount: 0,
				pageSizes: [],
			}

			// Get page count
			if (typeof pdf.numPages !== 'number') {
				throw new Error('Invalid PDF: page count is not a number')
			}

			metadata.pageCount = pdf.numPages

			// Get all page sizes first
			metadata.pageSizes = []
			for (let i = 1; i <= pdf.numPages; i++) {
				const page = await pdf.getPage(i)
				const pageViewport = page.getViewport({ scale: 1 })
				metadata.pageSizes.push({
					width: pageViewport.width,
					height: pageViewport.height,
				})
			}

			// Use second page dimensions for the main dimensions
			// (first page is often the cover; and the dimensions are often wrong)
			const firstPageSize = metadata.pageSizes[1]
			if (firstPageSize) {
				// PDF points are 1/72 of an inch
				const POINTS_PER_INCH = 72
				const MM_PER_INCH = 25.4

				metadata.dimensions = {
					width: firstPageSize.width,
					height: firstPageSize.height,
					widthInches: Number(
						(firstPageSize.width / POINTS_PER_INCH).toFixed(2),
					),
					heightInches: Number(
						(firstPageSize.height / POINTS_PER_INCH).toFixed(2),
					),
					widthMm: Number(
						((firstPageSize.width / POINTS_PER_INCH) * MM_PER_INCH).toFixed(2),
					),
					heightMm: Number(
						((firstPageSize.height / POINTS_PER_INCH) * MM_PER_INCH).toFixed(2),
					),
				}
			}

			// Check for outline
			try {
				const outline = await pdf.getOutline()
				metadata.hasOutline = Boolean(outline && outline.length > 0)
			} catch (error) {
				metadata.hasOutline = false
			}

			// Get page labels
			try {
				const labels = await pdf.getPageLabels()
				metadata.pageLabels = Array.from({ length: pdf.numPages }, (_, i) => ({
					physicalPage: i + 1,
					pageLabel: labels?.[i] || String(i + 1),
				}))
			} catch (error) {
				metadata.pageLabels = Array.from({ length: pdf.numPages }, (_, i) => ({
					physicalPage: i + 1,
					pageLabel: String(i + 1),
				}))
			}

			return metadata
		} catch (error) {
			console.error('Error extracting PDF metadata:', error)
			return {
				pageCount: 0,
				pageSizes: [],
			}
		}
	}

	return {
		extractPdfMetadata,
	}
}
