import { gql } from '@apollo/client'

const RESOURCE_FIELDS = gql`
	fragment ResourceFields on BaseResource {
		_id
		name
		fileurl
		filesize
		filetype
		creator
		createdAt
		updatedAt
		usedInGames
		thumbnailurl
		resourceType
		... on BookResource {
			metadata {
				title
				description
				tags
				categories
				game
				ruleSystem
				authors
				contributingArtists
				publisher
				year
				edition
				isbn
				pageCount
				language
				series
				seriesNumber
				relatedBooks
				requiredBooks
				contentWarnings
				recommendedAgeRange {
					min
					max
				}
				playTime {
					min
					max
				}
				tableOfContents {
					chapterTitle
					pageNumber
				}
				errata {
					version
					url
					date
				}
				pdf {
					pageCount
					dimensions {
						width
						height
						widthInches
						heightInches
						widthMm
						heightMm
					}
					hasOutline
					pageSizes {
						width
						height
					}
					pageLabels {
						physicalPage
						pageLabel
					}
					formFields {
						name
						type
						page
						rect {
							x
							y
							width
							height
						}
						options
						maxLength
						readOnly
						required
						noExport
						multiline
						isPassword
						isRichText
						isScrollable
						isCombed
						isMultiSelect
						isEditable
						isSorted
						isSpellChecked
						selectOnClick
						fileTypes
						isImageButton
					}
				}
			}
		}
		... on SheetResource {
			metadata {
				title
				description
				tags
				categories
				game
				ruleSystem
				authors
				contributingArtists
				publisher
				year
				edition
				isbn
				pageCount
				language
				pdf {
					pageCount
					dimensions {
						width
						height
						widthInches
						heightInches
						widthMm
						heightMm
					}
					hasOutline
					pageSizes {
						width
						height
					}
					pageLabels {
						physicalPage
						pageLabel
					}
					formFields {
						name
						type
						page
						rect {
							x
							y
							width
							height
						}
						options
						maxLength
						readOnly
						required
						noExport
						multiline
						isPassword
						isRichText
						isScrollable
						isCombed
						isMultiSelect
						isEditable
						isSorted
						isSpellChecked
						selectOnClick
						fileTypes
						isImageButton
					}
				}
			}
		}
		... on ImageResource {
			metadata {
				description
				tags
				width
				height
			}
		}
		... on VideoResource {
			metadata {
				description
				tags
				width
				height
				duration
			}
		}
		... on OtherResource {
			metadata {
				description
				tags
			}
		}
	}
`

export const CREATE_RESOURCE = gql`
	mutation CreateResource($resourceInput: ResourceInput!) {
		createResource(resourceInput: $resourceInput) {
			...ResourceFields
		}
	}
	${RESOURCE_FIELDS}
`

export const GET_RESOURCE = gql`
	query GetResource($resourceId: ID!) {
		getResource(resourceId: $resourceId) {
			__typename
			... on BaseResource {
				_id
				name
				fileurl
				filesize
				filetype
				creator
				createdAt
				updatedAt
				usedInGames
				thumbnailurl
				resourceType
			}
			... on BookResource {
				_id
				name
				fileurl
				filesize
				filetype
				creator
				createdAt
				updatedAt
				usedInGames
				thumbnailurl
				resourceType
				metadata {
					title
					description
					categories
					pdf {
						pageCount
						dimensions {
							width
							height
							widthInches
							heightInches
							widthMm
							heightMm
						}
						hasOutline
						pageSizes {
							width
							height
						}
						pageLabels {
							physicalPage
							pageLabel
						}
						formFields {
							name
							type
							page
							rect {
								x
								y
								width
								height
							}
							options
							maxLength
							readOnly
							required
							noExport
							multiline
							isPassword
							isRichText
							isScrollable
							isCombed
							isMultiSelect
							isEditable
							isSorted
							isSpellChecked
							selectOnClick
							fileTypes
							isImageButton
						}
					}
				}
			}
			... on SheetResource {
				_id
				name
				fileurl
				filesize
				filetype
				creator
				createdAt
				updatedAt
				usedInGames
				thumbnailurl
				resourceType
				metadata {
					title
					description
					pdf {
						pageCount
						dimensions {
							width
							height
							widthInches
							heightInches
							widthMm
							heightMm
						}
						hasOutline
						pageSizes {
							width
							height
						}
						pageLabels {
							physicalPage
							pageLabel
						}
						formFields {
							name
							type
							page
							rect {
								x
								y
								width
								height
							}
							options
							maxLength
							readOnly
							required
							noExport
							multiline
							isPassword
							isRichText
							isScrollable
							isCombed
							isMultiSelect
							isEditable
							isSorted
							isSpellChecked
							selectOnClick
							fileTypes
							isImageButton
						}
					}
				}
			}
		}
	}
`

export const GET_RESOURCES_BY_USER = gql`
	query GetResourcesByUser($userId: ID!) {
		getResourcesByUser(userId: $userId) {
			...ResourceFields
		}
	}
	${RESOURCE_FIELDS}
`

export const GET_RESOURCES_BY_GAME = gql`
	query GetResourcesByGame($gameId: ID!) {
		getResourcesByGame(gameId: $gameId) {
			...ResourceFields
		}
	}
	${RESOURCE_FIELDS}
`

export const UPDATE_RESOURCE = gql`
	mutation UpdateResource($resourceInput: ResourceInput!) {
		updateResource(resourceInput: $resourceInput) {
			...ResourceFields
		}
	}
	${RESOURCE_FIELDS}
`

export const DELETE_RESOURCE = gql`
	mutation DeleteResource($resourceId: ID!) {
		deleteResource(resourceId: $resourceId) {
			...ResourceFields
		}
	}
	${RESOURCE_FIELDS}
`

export const DELETE_RESOURCES = gql`
	mutation DeleteResources($resourceIds: [ID!]!) {
		deleteResources(resourceIds: $resourceIds) {
			...ResourceFields
		}
	}
	${RESOURCE_FIELDS}
`

export const REMOVE_GAME_FROM_RESOURCES = gql`
	mutation RemoveGameFromResources($resourceIds: [ID!]!, $gameId: ID!) {
		removeGameFromResources(resourceIds: $resourceIds, gameId: $gameId) {
			...ResourceFields
		}
	}
	${RESOURCE_FIELDS}
`
