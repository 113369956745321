import { PDFDocument } from 'pdf-lib'
import { useCallback } from 'react'

export const usePdfModifier = () => {
	const modifyPdfWithFormData = useCallback(
		async (
			pdfUrl: string,
			formValues: Record<string, any> | undefined | null,
		) => {
			try {
				// If no form values, return original URL
				if (!formValues) {
					return pdfUrl
				}

				console.log('[usePdfModifier] Form values:', formValues)

				// Fetch the PDF
				const pdfResponse = await fetch(pdfUrl)
				const pdfBytes = await pdfResponse.arrayBuffer()

				// Load the PDF document
				const pdfDoc = await PDFDocument.load(pdfBytes)
				const form = pdfDoc.getForm()

				// Set form values
				for (const [name, value] of Object.entries(formValues)) {
					try {
						// Try text field
						const textField = form.getTextField(name)
						if (textField) {
							textField.setText(String(value))
							continue
						}
					} catch (e) {
						// Not a text field, continue to next type
					}

					try {
						// Try checkbox
						const checkBox = form.getCheckBox(name)
						if (checkBox) {
							if (value === true) {
								checkBox.check()
							} else if (value === false) {
								checkBox.uncheck()
							}
							continue
						}
					} catch (e) {
						// Not a checkbox, continue to next type
					}

					try {
						// Try dropdown
						const dropdown = form.getDropdown(name)
						if (dropdown) {
							if (Array.isArray(value)) {
								dropdown.setOptions(value)
							} else {
								dropdown.select(String(value))
							}
							continue
						}
					} catch (e) {
						// Not a dropdown, continue to next type
					}

					try {
						// Try button
						const button = form.getButton(name)
						if (button) {
							// Access the underlying dictionary to set the value
							const dict = button.acroField.dict
							const context = pdfDoc.context
							dict.set(context.obj('V'), context.obj(String(value)))
							continue
						}
					} catch (e) {
						// Not a button
						console.warn(
							`Could not set field ${name}: No matching field type found`,
						)
					}
				}

				// Save with flags to ensure form fields are flattened into the document
				const modifiedPdfBytes = await pdfDoc.save({
					updateFieldAppearances: true,
				})

				// Create a new URL for the modified PDF
				const modifiedPdfUrl = URL.createObjectURL(
					new Blob([modifiedPdfBytes], { type: 'application/pdf' }),
				)

				return modifiedPdfUrl
			} catch (error) {
				console.error('Error modifying PDF:', error)
				return pdfUrl
			}
		},
		[],
	)

	return { modifyPdfWithFormData }
}
