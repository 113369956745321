import produce, { Draft } from 'immer'
import {
	PdfViewerAction,
	PdfViewerState,
	Reducer,
} from '../../../shared/types/book'

const reducer: Reducer = produce(
	(draft: Draft<PdfViewerState>, action: PdfViewerAction) => {
		switch (action.type) {
			case 'SET_RESOURCE':
				draft.resource = action.payload
				break
			case 'SET_NAME':
				draft.name = action.payload
				break
			case 'SET_CURRENT_PAGE':
				draft.currentPage = action.payload
				break
			case 'SET_NUM_PAGES':
				draft.numPages = action.payload
				break
			case 'SET_RENDERED_PAGES': {
				const positivePages = action.payload.filter(page => page > 0)
				const newPagesSet = new Set([...draft.renderedPages, ...positivePages])
				draft.renderedPages = Array.from(newPagesSet)
				break
			}
			case 'CLEAR_RENDERED_PAGES':
				draft.renderedPages = []
				break
			case 'SET_ON_RENDER_SUCCESS':
				draft.onRenderSuccess = action.payload
				break
			case 'SET_PAGES_TO_DISPLAY':
				draft.pagesToDisplay =
					action.payload !== undefined
						? action.payload
						: draft.pagesToDisplay === 1
						? 2
						: 1
				break
			case 'SET_IS_COVER_ALONE':
				draft.isCoverAlone = action.payload
				break
			case 'SET_SEARCH_TEXT':
				draft.searchText = action.payload
				break
			case 'SET_FIT_WIDTH':
				draft.fitWidth = action.payload
				draft.fitPage = false
				break
			case 'SET_FIT_PAGE':
				draft.fitPage = action.payload
				draft.fitWidth = false
				break
			case 'SET_OUTLINE_VISIBLE':
				draft.isOutlineVisible = action.payload
				if (action.payload) {
					draft.isThumbnailsVisible = false
				}
				break
			case 'SET_THUMBNAILS_VISIBLE':
				draft.isThumbnailsVisible = action.payload
				if (action.payload) {
					draft.isOutlineVisible = false
				}
				break
			case 'ADD_LOADING':
				if (!draft.loading.includes(action.payload)) {
					draft.loading.push(action.payload)
				}
				break
			case 'REMOVE_LOADING':
				draft.loading = draft.loading.filter(page => page !== action.payload)
				break
			case 'SET_ZOOM_LEVEL':
				draft.zoomLevel = action.payload
				draft.fitWidth = false
				draft.fitPage = false
				break
			case 'SET_IS_READY':
				draft.isReady = action.payload
				break
			case 'SET_DISPLAY_OPTIONS':
				draft.fitWidth = action.payload.fitWidth
				draft.fitPage = action.payload.fitPage
				draft.pagesToDisplay = action.payload.pagesToDisplay
				draft.isCoverAlone = action.payload.isCoverAlone
				break
			case 'SET_LOADING':
				draft.isLoading = action.payload
				break
			default:
				break
		}
		return draft
	},
)

export default reducer
