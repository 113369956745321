import { gql } from '@apollo/client'

export const GAMES_BY_USER = gql`
	# Get the created and joined games of a single user
	query GamesByUser {
		gamesByUser {
			_id
			name
			email
			avatar
			createdGames {
				_id
				title
				description
				coverImage
				logo
				hideLogo
				system {
					name
					logoPath
					coverPath
				}
				assets {
					byId
				}
				creator {
					_id
				}
			}
			joinedGames {
				_id
				title
				description
				coverImage
				logo
				system {
					name
					logoPath
					coverPath
				}
				assets {
					byId
				}
				creator {
					_id
				}
			}
		}
	}
`

export const CREATE_GAME = gql`
	mutation CreateGame($createGameInput: CreateGameInput!) {
		createGame(createGameInput: $createGameInput) {
			_id
			title
			description
			coverImage
			logo
			hideLogo
			creator {
				_id
				name
			}
			log {
				byId
				allIds
			}
			users
			userSettings
			assets {
				byId
				allIds
			}
			documents {
				byId
				allIds
			}

			books {
				byId
				allIds
			}
			drawing {
				size
				color
			}
			flags {
				maps
			}
			theme {
				primary
				secondary
			}
			syrinscape {
				sessionId
				volume
				show
				savedSoundsets
			}
			inviteToken
		}
	}
`

export const DELETE_GAME = gql`
	mutation DeleteGame($gameId: ID!) {
		deleteGame(gameId: $gameId) {
			_id
			title
		}
	}
`

export const ADD_PLAYER = gql`
	mutation AddPlayer($email: String!, $gameId: ID!) {
		addPlayer(email: $email, gameId: $gameId)
	}
`

export const ADD_PLAYER_WITH_TOKEN = gql`
	mutation AddPlayerWithToken(
		$email: String!
		$gameId: ID!
		$inviteToken: String!
	) {
		addPlayerWithToken(
			email: $email
			gameId: $gameId
			inviteToken: $inviteToken
		)
	}
`

export const REMOVE_PLAYER = gql`
	mutation RemovePlayer($userId: String!, $gameId: ID!) {
		removePlayer(userId: $userId, gameId: $gameId)
	}
`

export const GAME_USERS = gql`
	query gameUsers($gameId: ID!) {
		gameUsers(gameId: $gameId) {
			_id
			name
			email
			avatar
		}
	}
`

export const ADD_ASSET = gql`
	mutation AddAsset($gameId: ID!, $asset: AssetInput!) {
		addAsset(gameId: $gameId, asset: $asset) {
			gameId
			asset {
				_id
				name
				fileurl
				filesize
				filetype
				width
				height
				creator
			}
		}
	}
`

export const GET_ASSET = gql`
	query getAsset($gameId: ID!, $assetId: ID!) {
		getAsset(gameId: $gameId, assetId: $assetId) {
			_id
			name
			fileurl
			filesize
			filetype
			width
			height
			creator
		}
	}
`

export const REMOVE_ASSET = gql`
	mutation removeAsset($gameId: ID!, $assetId: ID!) {
		removeAsset(gameId: $gameId, assetId: $assetId)
	}
`

export const HAS_ACCESS_TO_GAME = gql`
	query hasAccessToGame($gameId: ID!) {
		hasAccessToGame(gameId: $gameId)
	}
`

export const UPDATE_LOGO = gql`
	mutation UpdateLogo($gameId: ID!, $assetId: ID!) {
		updateLogo(gameId: $gameId, assetId: $assetId) {
			_id
		}
	}
`

export const LEAVE_GAME = gql`
	mutation LeaveGame($gameId: ID!, $userId: ID!) {
		leaveGame(gameId: $gameId, userId: $userId)
	}
`

export const SAVE_GAME_SYSTEM = gql`
	mutation SaveGameSystem($gameId: ID!, $system: SystemInput!) {
		saveGameSystem(gameId: $gameId, system: $system) {
			repository
			name
			version
			description
			author
			license
			code
			assetsPath
			logoPath
			coverPath
			assetIds
			grid {
				type
				size
				unitSize
				unit
			}
			collections {
				singularName
				pluralName
				description
				type
				acceptTypes
				windowSize
				thumbnailField
				allowCreate
				hasEditMode
				canAssumeAsCharacter
				defaultAccess
				isUnique
				relationship
			}
			chatMessageTypes {
				type
			}
			diceConfig {
				label
				notation
				icons
			}
		}
	}
`

export const LOAD_GAME_SYSTEM = gql`
	query LoadGameSystem($gameId: ID!) {
		loadGameSystem(gameId: $gameId) {
			repository
			name
			version
			description
			author
			license
			code
			assetsPath
			logoPath
			coverPath
			assetIds
			grid {
				type
				size
				unitSize
				unit
			}
			collections {
				singularName
				pluralName
				description
				type
				acceptTypes
				windowSize
				thumbnailField
				allowCreate
				hasEditMode
				canAssumeAsCharacter
				defaultAccess
				isUnique
				relationship
			}
			chatMessageTypes {
				type
			}
			diceConfig {
				label
				notation
				icons
			}
		}
	}
`

export const VALIDATE_INVITE_TOKEN = gql`
	query ValidateInviteToken($gameId: ID!, $inviteToken: String!) {
		validateInviteToken(gameId: $gameId, inviteToken: $inviteToken)
	}
`

export const UPDATE_INVITE_TOKEN = gql`
	mutation UpdateInviteToken($gameId: ID!, $inviteToken: String!) {
		updateInviteToken(gameId: $gameId, inviteToken: $inviteToken) {
			_id
			inviteToken
		}
	}
`

export const ADD_PDF_SHEET = gql`
	mutation AddPdfSheet(
		$gameId: ID!
		$id: ID!
		$resourceId: ID!
		$collection: CollectionInput!
	) {
		addPdfSheet(
			gameId: $gameId
			id: $id
			resourceId: $resourceId
			collection: $collection
		) {
			_id
			pdfSheets {
				byId
				allIds
			}
		}
	}
`

export const REMOVE_PDF_SHEET = gql`
	mutation RemovePdfSheet($gameId: ID!, $id: ID!) {
		removePdfSheet(gameId: $gameId, id: $id) {
			_id
			pdfSheets {
				byId
				allIds
			}
		}
	}
`

export const UPDATE_PDF_SHEET = gql`
	mutation UpdatePdfSheet(
		$gameId: ID!
		$id: ID!
		$collection: CollectionInput!
	) {
		updatePdfSheet(gameId: $gameId, id: $id, collection: $collection) {
			_id
			pdfSheets {
				byId
				allIds
			}
		}
	}
`
