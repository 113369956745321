import { useMutation } from '@apollo/client'
import { Bell, LogOut, User } from 'lucide-react'
import { FC, useContext, useRef, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import AuthContext from '../../../contexts/auth'
import {
	DELETE_USER,
	UPDATE_USER_EMAIL,
	UPDATE_USER_NAME,
} from '../../../graphql/users'
import SidebarModalWindow from '../../window/SidebarModalWindow'
import AccountTab from './profile/AccountTab'
import NotificationsTab from './profile/NotificationsTab'

interface IProps {
	onClose: () => void
}

const ProfileEditor: FC<IProps> = props => {
	const { authState, authDispatch } = useContext(AuthContext)
	const fileRef = useRef<HTMLInputElement>(null)
	const [deleteUser] = useMutation(DELETE_USER)
	const [updateUserName] = useMutation(UPDATE_USER_NAME)
	const [updateUserEmail] = useMutation(UPDATE_USER_EMAIL)
	const [isUploading, setIsUploading] = useState(false)
	const [uploadError, setUploadError] = useState<string | null>(null)
	const [previewUrl, setPreviewUrl] = useState<string | null>(null)
	const [isOpen, setIsOpen] = useState(true)

	const { register, getValues, setValue } = useForm<FieldValues>({
		defaultValues: {
			name: authState.userDetails.name,
			email: authState.userDetails.email,
		},
	})

	const handleFieldUpdate = async (field: string) => {
		const values = getValues()
		const value = values[field]

		if (field === 'name') {
			await updateUserName({
				variables: { name: value },
			})
		} else if (field === 'email') {
			await updateUserEmail({
				variables: { email: value },
			})
		}

		authDispatch({
			type: 'UPDATE_PROFILE',
			payload: {
				userDetails: {
					...authState.userDetails,
					[field]: value,
				},
			},
		})
	}

	const handleCancel = () => {
		setIsOpen(false)
		setTimeout(props.onClose, 500)
	}

	const handleDeleteAccount = () => {
		if (
			!window.confirm(
				'Are you sure you want to delete your account? This will remove you from all games you have joined, and it will delete all your games. This cannot be undone.',
			)
		) {
			return
		}

		deleteUser()
		authState.logout()
	}

	return (
		<SidebarModalWindow
			id='profile-editor'
			title='Edit Account'
			size='large'
			onClose={handleCancel}
			isOpen={isOpen}
			tabs={[
				{
					id: 'account',
					label: 'Account',
					icon: <User />,
					content: (
						<AccountTab
							handleSubmit={handleFieldUpdate}
							handleDeleteAccount={handleDeleteAccount}
							register={register}
							setValue={setValue}
							fileRef={fileRef}
							isUploading={isUploading}
							uploadError={uploadError}
							previewUrl={previewUrl}
							setPreviewUrl={setPreviewUrl}
							setIsUploading={setIsUploading}
							setUploadError={setUploadError}
						/>
					),
				},
				{
					id: 'notifications',
					label: 'Notifications',
					icon: <Bell />,
					content: <NotificationsTab />,
				},
				{
					id: 'signout',
					label: 'Sign Out',
					icon: <LogOut />,
					content: <div />,
					onClick: () => authState.logout(),
				},
			]}
		/>
	)
}

export default ProfileEditor
