import { useEffect } from 'react'

const useEventBusDebugger = (
	eventBusRef: any,
	isLoading: boolean,
	debug: boolean,
) => {
	const eventNames = [
		'updatefromsandbox',
		'dispatcheventinsandbox',
		'updatefromsandbox',
		'annotationeditorstatechanged',
		'annotationeditorstateschanged', // Tracks editor state changes with details about selection, undo/redo state, etc.
		'annotationeditorparamschanged', // Tracks changes to editor parameters
		'annotationupdated', // Tracks when annotations are updated
		'switchannotationeditormode', // Tracks when annotation editor mode changes
		'switchannotationeditorparams', // Tracks changes to editor parameters
		'editingaction', // Tracks annotation editing actions
		'updatefromsandbox',
	]

	useEffect(() => {
		if (isLoading || !debug) return
		if (!eventBusRef.current) return

		eventNames.forEach(eventName => {
			console.log(`[PDFViewer] Adding event listener for: ${eventName}`)

			eventBusRef.current?.on(eventName, (event: any) => {
				console.log(`[PDFViewer] Event received: ${eventName}`, event)
			})
		})

		return () => {
			eventNames.forEach(eventName => {
				eventBusRef.current?.off(eventName, () => {})
			})
		}
	}, [eventBusRef.current, isLoading, debug])
}

export default useEventBusDebugger
