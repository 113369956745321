import { Graphics } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useEffect, useState } from 'react'
import useGrid from '../../hooks/useGrid'

interface Props {
	width: number
	height: number
	viewport: PixiViewport | null
}

const Grid = ({ width, height, viewport }: Props) => {
	const gridSettings = useGrid()
	const [scale, setScale] = useState(viewport?.scale?.x || 1)

	useEffect(() => {
		if (!viewport) return

		const onMoved = () => {
			if (viewport?.scale?.x) {
				setScale(viewport.scale.x)
			}
		}

		viewport.on('moved', onMoved)

		// Set initial scale safely
		if (viewport?.scale?.x) {
			setScale(viewport.scale.x)
		}

		return () => {
			viewport.off('moved', onMoved)
		}
	}, [viewport])

	const draw = useCallback(
		(g: PIXI.Graphics) => {
			if (!width || !height) return

			// If size is 1, we want 100 cells across the map
			const cellSize = width / (100 / gridSettings.size)

			// Scale line thickness inversely with zoom
			const baseLineWidth = 1
			const lineWidth = baseLineWidth / scale

			// Calculate opacity based on zoom level
			let opacity = gridSettings.alpha
			if (scale < 20) {
				// Gradually reduce opacity between scale 20 and 15
				const fadeStart = 20
				const fadeEnd = 15
				opacity =
					gridSettings.alpha *
					Math.max(0, (scale - fadeEnd) / (fadeStart - fadeEnd))
			}

			g.clear()
			g.alpha = opacity
			g.lineStyle(
				lineWidth,
				parseInt(gridSettings.color.replace('#', ''), 16),
				1,
			)

			// Draw vertical lines
			for (let x = 0; x <= width; x += cellSize) {
				g.moveTo(x, 0)
				g.lineTo(x, height)
			}

			// Draw horizontal lines
			for (let y = 0; y <= height; y += cellSize) {
				g.moveTo(0, y)
				g.lineTo(width, y)
			}
		},
		[width, height, gridSettings, scale],
	)

	if (!width || !height) return null

	return <Graphics draw={draw} />
}

export default Grid
