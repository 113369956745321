import React, { ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_BACKGROUND_COLOR, INTERFACE_DROPSHADOW } from '../constants'

export interface ToolbarProps extends React.HTMLAttributes<HTMLDivElement> {
	children: ReactNode
	className?: string
	style?: React.CSSProperties
	expandable?: boolean
}

const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
	({ children, className, style, expandable = false, ...rest }, ref) => {
		return (
			<div
				className={twMerge(
					'pointer-events-auto flex min-h-[3.5rem] w-fit flex-shrink-0 items-center justify-center space-x-1 rounded-[1.75rem] p-1',
					expandable ? 'h-auto' : 'h-[3.5rem]',
					className,
				)}
				ref={ref}
				style={{
					backgroundColor: INTERFACE_BACKGROUND_COLOR,
					boxShadow: INTERFACE_DROPSHADOW,
					...style,
				}}
				{...rest}
			>
				{children}
			</div>
		)
	},
)

Toolbar.displayName = 'Toolbar'

export default Toolbar
