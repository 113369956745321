import { useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { default as BookApp } from '../BookApp'
import AuthContext from '../contexts/auth'
import { ProfileEditContext } from '../contexts/ProfileEditContext'
import { QueueProvider } from '../contexts/queue'
import { SocketContext, socket } from '../contexts/socket'
import DocumentApp from '../DocumentApp'
import GameApp from '../GameApp'
import ResourceApp from '../ResourceApp'
import SheetApp from '../SheetApp'
import AboutPage from './pages/About'
import AdminLayout from './pages/admin/AdminLayout'
import AdminDashboard from './pages/admin/Dashboard'
import AdminGameDetail from './pages/admin/GameDetail'
import AdminGames from './pages/admin/Games'
import AdminUserDetail from './pages/admin/UserDetail'
import AdminUsers from './pages/admin/Users'
import BooksPage from './pages/books/BooksPage'
import ForgotPassword from './pages/ForgotPassword'
import HomePage from './pages/Home'
import InvitePage from './pages/InvitePage'
import NewGamePage from './pages/NewGame'
import ProfilePage from './pages/Profile'
import ResetPassword from './pages/ResetPassword'
import Footer from './pages/sections/Footer'
import Navigation from './pages/sections/Navigation'
import SignInPage from './pages/SignIn'
import SignUpPage from './pages/SignUp'
import ProfileEditor from './pages/views/ProfileEditor'
import { ProtectedAdminRoute } from './ProtectedAdminRoute'
import ResourcesProvider from './ResourcesProvider'

interface AppContentProps {
	authState: any // Replace with proper type
	authDispatch: any // Replace with proper type
	loading: boolean
	isAuthed: boolean
	isInvited: boolean
	hideNavigation: (pathname: string) => boolean
}

export function AppContent({
	authState,
	authDispatch,
	loading,
	isAuthed,
	isInvited,
	hideNavigation,
}: AppContentProps) {
	const { editProfile, setEditProfile } = useContext(ProfileEditContext)
	const location = useLocation()

	const protectedAdminRoute = (
		<ProtectedAdminRoute>
			<AdminLayout />
		</ProtectedAdminRoute>
	)

	return (
		<AuthContext.Provider value={{ authState, authDispatch }}>
			<ResourcesProvider userId={authState.userId}>
				<SocketContext.Provider value={socket}>
					<QueueProvider>
						{!hideNavigation(location.pathname) && (
							<Navigation loading={loading} />
						)}
						<Routes>
							<Route path='/' element={<HomePage />} />
							<Route path='/about' element={<AboutPage />} />
							<Route path='/hiring' element={<AboutPage />} />
							{!isAuthed && <Route path='/signin' element={<SignInPage />} />}
							{!isAuthed && <Route path='/signup' element={<SignUpPage />} />}
							{!isAuthed && (
								<Route
									path='/profile'
									element={<Navigate to='/signin' replace />}
								/>
							)}

							{isInvited && (
								<Route path='/game/:gameId' element={<InvitePage />} />
							)}
							{!isAuthed && !isInvited && (
								<Route
									path='/game/:gameId'
									element={<Navigate to='/signin' />}
								/>
							)}

							{isAuthed && (
								<Route path='/signin' element={<Navigate to='/profile' />} />
							)}
							{isAuthed && <Route path='/profile' element={<ProfilePage />} />}
							{isAuthed && (
								<Route path='/profile/books' element={<BooksPage />} />
							)}
							{isAuthed && <Route path='/game/new' element={<NewGamePage />} />}
							{isAuthed && <Route path='/game/:gameId' element={<GameApp />} />}
							{isAuthed && (
								<Route
									path='/document/:gameId/:documentId'
									element={<DocumentApp />}
								/>
							)}
							{isAuthed && (
								<Route path='/book/:gameId/:bookId' element={<BookApp />} />
							)}
							{isAuthed && (
								<Route path='/sheet/:gameId/:sheetId' element={<SheetApp />} />
							)}
							{isAuthed && (
								<Route path='/resource/:resourceId' element={<ResourceApp />} />
							)}
							<Route path='/forgot-password' element={<ForgotPassword />} />
							<Route path='/reset-password' element={<ResetPassword />} />
							{isAuthed && authState.userDetails.isAdmin && (
								<Route path='/admin' element={protectedAdminRoute}>
									<Route index element={<AdminDashboard />} />
									<Route path='users' element={<AdminUsers />} />
									<Route path='users/:id' element={<AdminUserDetail />} />
									<Route path='games' element={<AdminGames />}>
										<Route path=':id' element={<AdminGameDetail />} />
									</Route>
								</Route>
							)}
						</Routes>
						<Footer />
						{authState.token && editProfile && (
							<ProfileEditor onClose={() => setEditProfile(false)} />
						)}
					</QueueProvider>
				</SocketContext.Provider>
			</ResourcesProvider>
		</AuthContext.Provider>
	)
}
