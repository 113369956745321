import { ArrowUpCircleIcon } from '@heroicons/react/24/outline'
import { TrashIcon } from '@heroicons/react/24/solid'
import { useContext, useState } from 'react'
import Dropzone from 'react-dropzone'
import { twMerge } from 'tailwind-merge'
import { IAsset } from '../../../shared/types/asset'
import GameContext from '../contexts/game'
import useAssetUploader from '../hooks/useAssetUploader'
import useGetAssetById from '../hooks/useGetAssetById'
import useRemoveAsset from '../hooks/useRemoveAsset'
import Asset from './Asset'
import Translucency from './Translucency'

type Props = {
	assetId: string
	onAssetChange: (asset?: IAsset) => void
	className?: string
	assetClassName?: string
	showBlur?: boolean
}

const AssetManager = ({
	assetId,
	onAssetChange,
	className,
	assetClassName,
	showBlur = true,
}: Props) => {
	const { game } = useContext(GameContext)
	const { asset } = useGetAssetById(assetId)
	const assetUrl = asset?.fileurl
	const [isOver, setIsOver] = useState(false)
	const [uploadProgress, setUploadProgress] = useState<number | null>(null)
	const assetUploader = useAssetUploader()
	const { removeAsset } = useRemoveAsset()

	const handleFileDragEnter = () => {
		setIsOver(true)
	}

	const handleFileDragLeave = () => {
		setIsOver(false)
	}

	const handleFileDrop = async (files: File[]) => {
		try {
			setUploadProgress(0)
			const uploadedAsset = await assetUploader(files[0], game._id, percent =>
				setUploadProgress(percent),
			)
			onAssetChange(uploadedAsset)
			setUploadProgress(null)
		} catch (error) {
			console.error('Failed to upload asset:', error)
			setUploadProgress(null)
		}
	}

	const handleRemoveAsset = () => {
		removeAsset({
			variables: {
				gameId: game._id,
				assetId,
			},
		})

		onAssetChange()
	}

	return (
		<Dropzone
			onDragEnter={handleFileDragEnter}
			onDragLeave={handleFileDragLeave}
			onDrop={handleFileDrop}
		>
			{({ getRootProps, getInputProps }) => (
				<div
					className={twMerge(
						'relative flex aspect-video items-center justify-center bg-gray-800/20 transition-all duration-300',
						isOver && 'bg-gray-800',
						className,
					)}
					{...getRootProps()}
				>
					{(!assetId || !asset) && (
						<>
							<input {...getInputProps()} />
							<div className='flex flex-col items-center text-xs text-gray-500'>
								{uploadProgress !== null ? (
									<div className='text-center'>
										<div>Uploading...</div>
										<div>{uploadProgress}%</div>
									</div>
								) : (
									<>
										<ArrowUpCircleIcon className='!relative !h-8 !w-8' />
										<p className='mt-2 text-center'>
											Drop image or video here
											<br /> or click to upload
										</p>
									</>
								)}
							</div>
						</>
					)}

					{assetId && asset && (
						<>
							{showBlur && (
								<div
									className='absolute inset-0 bg-cover bg-center blur-xl'
									style={{
										backgroundImage: `url(${assetUrl})`,
									}}
								/>
							)}
							<Asset
								assetId={assetId}
								className={twMerge('z-10', assetClassName)}
							/>
							<button
								className={twMerge(
									'absolute bottom-4 right-4 z-20 !flex !h-8 !w-8 !items-center !justify-center rounded-full p-1 text-white',
									Translucency,
								)}
								onClick={handleRemoveAsset}
							>
								<TrashIcon className='!relative !h-4 !w-4' />
							</button>
						</>
					)}
				</div>
			)}
		</Dropzone>
	)
}

export default AssetManager
