import { useContext, useEffect, useRef, useState } from 'react'
import AuthContext from '../../contexts/auth'
import { socket } from '../../contexts/socket'
import useMessageHandler from '../../hooks/useMessageHandler'
import ChatInput from './Input'

const ChatForm = () => {
	const { authState } = useContext(AuthContext)
	const [message, setMessage] = useState('')
	const typingTimeoutRef = useRef<number | null>(null)
	const handleNewMessage = useMessageHandler()

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setMessage(e.target.value)

		if (e.target.value === '') {
			// If the input field is empty, send the "stop typing" event
			socket.emit('stop typing', authState.userId)

			if (typingTimeoutRef.current) {
				clearTimeout(typingTimeoutRef.current)
				typingTimeoutRef.current = null
			}
		} else {
			socket.emit('typing', authState.userId)

			if (typingTimeoutRef.current) {
				clearTimeout(typingTimeoutRef.current)
			}

			typingTimeoutRef.current = window.setTimeout(() => {
				socket.emit('stop typing', authState.userId)
			}, 1000) // Timeout duration (1000 ms = 1 second)
		}
	}

	useEffect(() => {
		return () => {
			if (typingTimeoutRef.current) {
				clearTimeout(typingTimeoutRef.current)
			}
		}
	}, [])

	const handleSubmit = () => {
		const msg = message
		if (!msg) return

		handleNewMessage(msg)

		setMessage('')
	}

	return (
		<form
			onSubmit={handleSubmit}
			className='min-h-10 flex flex-none items-end space-x-2'
		>
			<ChatInput
				message={message}
				onChange={handleChange}
				onSend={handleSubmit}
			/>
		</form>
	)
}

export default ChatForm
