import React, { useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IBook } from '../../../../../shared/types/book'
import { BookResource } from '../../../../../shared/types/resources'
import address from '../../../config'
import BookSpineEffect from '../../books/BookSpineEffect'
import { INTERFACE_DROPSHADOW } from '../../interface/constants'

const CELL_ASPECT_RATIO = { width: 9, height: 12 }
const CELL_PADDING = 16

const getScaledDimensions = (
	bookWidthInches: number,
	bookHeightInches: number,
	maxWidth: number,
	maxHeight: number,
) => {
	if (!maxWidth || !maxHeight) return { width: 0, height: 0 }

	const pixelsPerInchWidth = maxWidth / CELL_ASPECT_RATIO.width
	const pixelsPerInchHeight = maxHeight / CELL_ASPECT_RATIO.height
	const pixelsPerInch = Math.min(pixelsPerInchWidth, pixelsPerInchHeight)

	let scaledWidth = bookWidthInches * pixelsPerInch
	let scaledHeight = bookHeightInches * pixelsPerInch

	if (scaledWidth > maxWidth || scaledHeight > maxHeight) {
		const widthRatio = maxWidth / scaledWidth
		const heightRatio = maxHeight / scaledHeight
		const scale = Math.min(widthRatio, heightRatio)
		scaledWidth *= scale
		scaledHeight *= scale
	}

	return { width: scaledWidth, height: scaledHeight }
}

interface BookCoverProps {
	resource: BookResource
	book?: IBook
	onLightBackground?: boolean
}

const BookCover: React.FC<BookCoverProps> = ({
	resource,
	book,
	onLightBackground = false,
}) => {
	const { gameId } = useParams()
	const [windows, setWindows] = useState<{ [key: string]: Window | null }>({})
	const cellRef = useRef<HTMLDivElement | null>(null)
	const textContainerRef = useRef<HTMLDivElement | null>(null)
	const [imageDimensions, setImageDimensions] = useState({
		width: 0,
		height: 0,
	})

	const handleOpen = () => {
		if (book?._id) {
			// for in-game books
			const bookId = book._id
			if (windows[bookId] && !windows[bookId]?.closed) {
				windows[bookId]?.focus()
			} else {
				const newWindow = window.open(
					`${origin}/book/${gameId}/${bookId}`,
					'_blank',
					'',
				)
				setWindows(prevWindows => ({ ...prevWindows, [bookId]: newWindow }))
			}
		} else if (resource?._id) {
			// for user library
			window.location.href = `${origin}/resource/${resource._id}`
		}
	}

	useLayoutEffect(() => {
		if (!cellRef.current || !textContainerRef.current) return
		const cellWidth = cellRef.current.clientWidth
		const cellHeight = cellRef.current.clientHeight
		const textHeight = textContainerRef.current.clientHeight

		const availableWidth = cellWidth - CELL_PADDING
		const availableHeight = cellHeight - textHeight - CELL_PADDING

		const inchesWidth =
			resource.metadata?.pdf?.dimensions?.widthInches || CELL_ASPECT_RATIO.width
		const inchesHeight =
			resource.metadata?.pdf?.dimensions?.heightInches ||
			CELL_ASPECT_RATIO.height

		const { width, height } = getScaledDimensions(
			inchesWidth,
			inchesHeight,
			availableWidth,
			availableHeight,
		)

		setImageDimensions({ width, height })
		// Measure only once after mount
	}, [resource])

	const displayTitle =
		resource.metadata?.title || resource.name.replace(/\.[^/.]+$/, '')
	const hasManyPages = (resource.metadata?.pdf?.pageCount || 0) > 10

	return (
		<div ref={cellRef} className='flex h-full w-full flex-col justify-end'>
			<div className='flex flex-col items-center'>
				<button
					onClick={handleOpen}
					className='relative flex transform items-center justify-center shadow-md transition-transform duration-200 hover:scale-105'
					style={{
						width: imageDimensions.width ? `${imageDimensions.width}px` : '0',
						height: imageDimensions.height
							? `${imageDimensions.height}px`
							: '0',
						boxShadow: INTERFACE_DROPSHADOW,
						overflow: 'hidden',
						position: 'relative',
					}}
				>
					<img
						src={`${address}${resource.thumbnailurl}`}
						alt={resource.name}
						className='max-h-full max-w-full object-contain'
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'contain',
						}}
					/>
					{hasManyPages && (
						<>
							<div className='absolute inset-x-0 top-0 h-px bg-gradient-to-r from-white/10 via-white/30 to-white/10' />
							<div className='pointer-events-none absolute inset-0'>
								<BookSpineEffect />
							</div>
						</>
					)}
				</button>
			</div>

			<div
				ref={textContainerRef}
				className='mt-2 flex flex-col items-center px-1'
			>
				<div
					className={`w-full truncate text-center text-xs ${
						onLightBackground ? 'text-gray-900' : 'text-white'
					}`}
				>
					{displayTitle}
				</div>
				<div
					className={`mt-1 w-full truncate text-center text-xs text-gray-400`}
				>
					{resource.metadata?.game || <span>&nbsp;</span>}
				</div>
			</div>
		</div>
	)
}

export default BookCover
