// Tabs.tsx
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import AuthContext from '../../../contexts/auth'

const Tabs = () => {
	const { authState } = useContext(AuthContext)

	return (
		<>
			{authState.userDetails.isAdmin && (
				<NavLink
					to='/admin'
					className={({ isActive }) =>
						isActive ? '' : 'opacity-50 hover:opacity-100'
					}
				>
					Admin
				</NavLink>
			)}

			<NavLink
				to='/profile'
				end
				className={({ isActive }) =>
					isActive ? '' : 'opacity-50 hover:opacity-100'
				}
			>
				Games
			</NavLink>
			<NavLink
				to='/profile/books'
				className={({ isActive }) =>
					isActive ? '' : 'opacity-50 hover:opacity-100'
				}
			>
				Books
			</NavLink>
		</>
	)
}

export default Tabs
