import * as React from 'react'
import { ICollection } from '../../../../shared/types/system'
import useUser from '../../hooks/useUser'
import Popover from '../Popover'
import ListItem from '../views/ListItem'

export interface IAddMenuProps {
	onAddDocument: (type: string) => void
	onAddBook: () => void
	onClose: () => void
	collections: ICollection[]
	open: boolean
	anchorRef: React.RefObject<HTMLElement>
}

export default function AddMenu(props: IAddMenuProps) {
	const { onAddDocument, onAddBook, onClose, collections, open, anchorRef } =
		props
	const { role } = useUser()
	const isGM = role === 'gm'

	const collectionsWithCreatePermission = React.useMemo(() => {
		const types = [...collections].filter(c => c.type !== 'Books')
		if (isGM) {
			types.push({
				type: 'Books',
				singularName: 'Book',
				pluralName: 'Books',
				allowCreate: 'true',
				description: 'Book collection',
				acceptTypes: [],
				hasEditMode: 'false',
			})
		}
		return types.sort((a, b) => a.singularName.localeCompare(b.singularName))
	}, [collections, isGM])

	return (
		<Popover
			id='add-document-popover'
			open={open}
			onClose={onClose}
			anchor={anchorRef}
			placement='bottom'
			className='w-32'
		>
			{collectionsWithCreatePermission.map(
				({ singularName, type, allowCreate }, i) => {
					if (type === 'all') return null
					if (type === 'Books' && !isGM) return null

					if (isGM || allowCreate === 'true') {
						return (
							<ListItem
								key={`type-${i}`}
								name={singularName}
								onOpen={
									type === 'Books' ? onAddBook : () => onAddDocument(type)
								}
							/>
						)
					}

					return null
				},
			)}
		</Popover>
	)
}
