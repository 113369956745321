import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { animated, useSpring } from '@react-spring/web'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { IBook } from '../../../../shared/types/book'
import { BookResource } from '../../../../shared/types/resources'
import address from '../../config'
import { INTERFACE_DROPSHADOW } from '../interface/constants'
import BookSpineEffect from './BookSpineEffect'
import BookMenu from './menu/BookMenu'
import ResourceMenu from './ResourceMenu'

// Standard size constants in inches
const STANDARD_WIDTH = 9
const STANDARD_HEIGHT = 12

const calculateScale = (widthInches?: number, heightInches?: number) => {
	if (!widthInches || !heightInches) return 1

	// If both dimensions are smaller than standard, scale proportionally
	if (widthInches <= STANDARD_WIDTH && heightInches <= STANDARD_HEIGHT) {
		return Math.max(
			widthInches / STANDARD_WIDTH,
			heightInches / STANDARD_HEIGHT,
		)
	}

	// If either dimension is larger, scale down proportionally
	return Math.min(
		1,
		STANDARD_WIDTH / widthInches,
		STANDARD_HEIGHT / heightInches,
	)
}

type Props = {
	resource: BookResource
	book?: IBook
	selectMode?: boolean
	selected?: boolean
	onSelect?: (selected: boolean) => void
	className?: string
}

const BookItem = ({
	resource,
	book,
	selectMode,
	selected = false,
	onSelect,
	className,
}: Props) => {
	const isGame = window.location.pathname.includes('game')
	const { gameId } = useParams()
	const [windows, setWindows] = useState<{ [key: string]: Window | null }>({})
	const [isHovered, setIsHovered] = useState(false)

	const scale = calculateScale(
		resource.metadata?.pdf?.dimensions?.widthInches,
		resource.metadata?.pdf?.dimensions?.heightInches,
	)

	const springProps = useSpring({
		transform: `scale(${isHovered ? scale * 1.05 : scale})`,
		rotateY: isHovered ? '5deg' : '0deg',
		config: {
			tension: 300,
			friction: 20,
		},
	})

	const handleSelect = () => onSelect?.(!selected)

	const handleOpen = () => {
		if (selectMode) {
			handleSelect()
		} else if (book?._id) {
			// for in-game books
			const bookId = book?._id
			if (!bookId) return
			if (windows[bookId] && !windows[bookId]?.closed) {
				windows[bookId]?.focus()
			} else {
				const newWindow = window.open(
					`${origin}/book/${gameId}/${bookId}`,
					'_blank',
					'',
				)
				setWindows(prevWindows => ({ ...prevWindows, [bookId]: newWindow }))
			}
		} else if (resource?._id) {
			// for user library
			const resourceId = resource._id
			if (!resourceId) return
			window.location.href = `${origin}/resource/${resourceId}`
		}
	}

	const displayTitle =
		resource.resourceType === 'book'
			? resource.metadata?.title || resource.name.replace(/\.[^/.]+$/, '')
			: resource.name

	return (
		<li
			className={twMerge(
				'rounded-lg',
				selectMode && 'cursor-pointer',
				selected && 'bg-black/5 dark:bg-white/10',
			)}
		>
			<div
				className='relative'
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				{selectMode && (
					<input
						type='checkbox'
						checked={selected}
						onChange={handleSelect}
						className='absolute top-2 right-2 z-10'
					/>
				)}
				<button onClick={handleOpen} className='w-full'>
					<animated.div
						id='book-cover-container'
						className='relative inline-block'
						style={{
							...springProps,
							transformOrigin: 'bottom center',
						}}
					>
						<img
							src={`${address}${resource.thumbnailurl}`}
							alt={resource.name}
							className='max-h-full max-w-full object-contain'
							style={{
								boxShadow: INTERFACE_DROPSHADOW,
							}}
						/>
						{resource.metadata?.pdf?.pageCount > 10 && (
							<>
								<div className='absolute inset-x-0 top-0 h-px bg-gradient-to-r from-white/10 via-white/30 to-white/10' />
								<div className='pointer-events-none absolute inset-0'>
									<BookSpineEffect />
								</div>
							</>
						)}
					</animated.div>
				</button>
			</div>
			<div className='mt-2 flex w-full justify-between space-x-2'>
				<div className='flex flex-col'>
					<p
						className={twMerge(
							'line-clamp-1 w-full overflow-hidden text-ellipsis text-left text-xs text-black overflow-wrap-anywhere',
							className,
						)}
					>
						<a
							href={`${address}${resource.fileurl}`}
							target='_blank'
							rel='noreferrer'
						>
							{displayTitle}
						</a>
					</p>
					{resource.metadata.ruleSystem && (
						<p className='line-clamp-1 mt-0.5 flex items-center text-ellipsis text-xs text-gray-400'>
							{resource.metadata.game}
						</p>
					)}
					{book && (
						<div className='mt-1 flex items-center text-xs text-gray-500'>
							{book.access === 'private' ? (
								<>
									<EyeSlashIcon className='mr-1 h-3 w-3' />
									Private
								</>
							) : (
								<>
									<EyeIcon className='mr-1 h-3 w-3' />
									Public
								</>
							)}
						</div>
					)}
				</div>
				{!selectMode && !isGame && <ResourceMenu resource={resource} />}
				{!selectMode && isGame && book && <BookMenu book={book} />}
			</div>
		</li>
	)
}

export default BookItem
