import { PanelLeft } from 'lucide-react'
import ToolButton from '../../interface/toolbar/ToolButton'

interface PDFOutlineButtonProps {
	onOutlineToggle: () => void
	isActive?: boolean
}

const PDFOutlineButton = ({
	onOutlineToggle,
	isActive = false,
}: PDFOutlineButtonProps) => {
	return (
		<ToolButton
			onClick={onOutlineToggle}
			isOpen={isActive}
			tooltip='Toggle Outline'
		>
			<PanelLeft />
		</ToolButton>
	)
}

export default PDFOutlineButton
