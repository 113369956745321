import { useQuery } from '@apollo/client'
import { animated, useSpring } from '@react-spring/web'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { GAME_USERS } from '../../../graphql/games'
import { IGameCard } from '../../../interfaces/game'
import { IUserProfile } from '../../../interfaces/users'
import Avatar from '../../Avatar'
import GameMenu from '../../gamemenu/GameMenu'
import { INTERFACE_DROPSHADOW } from '../../interface/constants'
import DocumentWindowMenuButton from '../../Library/MenuButton'
import Overlapper from '../../Overlapper'
import Tooltip from '../../Tooltip'
import GameCard from './GameCard'

export interface IGameCardContainerProps {
	game: IGameCard
	refetch?: () => void
	className?: string
}

export default function GameCardContainer({
	game,
	refetch,
	className,
}: IGameCardContainerProps) {
	const [isHovered, setIsHovered] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const springProps = useSpring({
		scale: isHovered || isMenuOpen ? 1.05 : 1,
		rotateY: isHovered || isMenuOpen ? '5deg' : '0deg',
		config: {
			tension: 300,
			friction: 20,
		},
	})

	const { data, loading, error } = useQuery(GAME_USERS, {
		variables: {
			gameId: game._id,
		},
		pollInterval: 5000,
		fetchPolicy: 'cache-and-network',
	})

	const UserAvatarWithTooltip = ({ user }: { user: IUserProfile }) => {
		const avatarRef = useRef<HTMLDivElement>(null)
		const [showTooltip, setShowTooltip] = useState(false)

		return (
			<div
				key={user._id}
				ref={avatarRef}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<Tooltip open={showTooltip} anchor={avatarRef} placement='bottom'>
					{user.name}
				</Tooltip>
				<Avatar
					src={user.avatar}
					userId={user._id}
					userName={user.name}
					className='mr-2'
					style={{ boxShadow: '0 2px 10px 0 rgba(0,0,0,0.2)' }}
					noBorder
				/>
			</div>
		)
	}

	return (
		<div
			className={twMerge('rounded-xl', className)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div className='relative z-10'>
				<GameMenu
					game={game}
					refetch={refetch}
					show={isHovered}
					onOpen={() => setIsMenuOpen(true)}
					onClose={() => setIsMenuOpen(false)}
				>
					<DocumentWindowMenuButton show={true} />
				</GameMenu>

				<Link key={game._id} to={'/game/' + game._id}>
					<animated.div
						style={{
							...springProps,
							transformOrigin: 'center center',
						}}
					>
						<GameCard game={game} style={{ boxShadow: INTERFACE_DROPSHADOW }} />
					</animated.div>
				</Link>
			</div>

			<div className='relative z-0 flex flex-row items-center justify-between pl-2 pr-1'>
				<div className='flex min-w-0 flex-1 flex-col'>
					<p className='mt-2 truncate font-medium'>{game.title}</p>
					<p className='truncate text-xs text-gray-500'>
						{game.system?.name || 'No Game System'}
					</p>
				</div>

				<Overlapper className='w-1/3' align='right'>
					{loading && (
						<div className='flex items-center justify-center p-3'>loading</div>
					)}
					{error && (
						<div className='flex items-center justify-center p-3'>error</div>
					)}
					{data?.gameUsers?.map((user: IUserProfile) => (
						<UserAvatarWithTooltip key={user._id} user={user} />
					))}
				</Overlapper>
			</div>
		</div>
	)
}
