interface IProps {
	children: React.ReactNode
}

const Footer: React.FC<IProps> = ({ children }) => {
	return (
		<div className='border-top items-center border-t border-gray-800 bg-gray-900 px-2 py-2 text-white'>
			{children}
		</div>
	)
}

export default Footer
