import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import SlideoverPanel from '../../SlideoverPanel'

const GET_USER_DETAIL = gql`
	query GetUserDetail($userId: ID!) {
		adminUser(userId: $userId) {
			_id
			name
			email
			avatar
			isAdmin
			createdAt
			createdGames {
				_id
				title
			}
			joinedGames {
				_id
				title
			}
			resources {
				... on BookResource {
					_id
					name
				}
				... on ImageResource {
					_id
					name
				}
				... on VideoResource {
					_id
					name
				}
				... on OtherResource {
					_id
					name
				}
			}
		}
	}
`

interface UserDetailProps {
	user?: any // Replace with proper type when available
	onClose?: () => void
	open?: boolean
}

export default function AdminUserDetail({
	user,
	onClose,
	open = false,
}: UserDetailProps) {
	const { id } = useParams()

	const { data, loading, error } = useQuery(GET_USER_DETAIL, {
		variables: { userId: id },
		skip: !id || !!user, // Skip if we have a user prop or no ID
	})

	if (loading) return <div>Loading...</div>
	if (error && !user) return <div>Error loading user details</div>

	const userData = user || data?.adminUser
	if (!userData) return null

	const content = (
		<div className='space-y-6'>
			<div>
				<div className='flex items-center gap-4'>
					{userData.avatar ? (
						<img
							src={userData.avatar}
							alt={userData.name}
							className='h-16 w-16 rounded-full object-cover'
						/>
					) : (
						<div className='flex h-16 w-16 items-center justify-center rounded-full bg-gray-200 text-xl text-gray-500'>
							{userData.name.charAt(0).toUpperCase()}
						</div>
					)}
					<div>
						<h3 className='text-lg font-medium leading-6 text-gray-900'>
							{userData.name}
						</h3>
						<p className='mt-1 text-sm text-gray-500'>{userData.email}</p>
					</div>
				</div>
				<div className='mt-4'>
					<span
						className={`inline-flex rounded-full px-2 py-1 text-xs font-semibold ${
							userData.isAdmin
								? 'bg-green-100 text-green-800'
								: 'bg-gray-100 text-gray-800'
						}`}
					>
						{userData.isAdmin ? 'Admin' : 'User'}
					</span>
				</div>
			</div>

			<div className='border-t border-gray-200 pt-5'>
				<dl className='grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2'>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Created Games</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{userData.createdGames.length} games
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Joined Games</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{userData.joinedGames.length} games
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Resources</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{userData.resources.length} resources
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Joined</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{new Date(userData.createdAt).toLocaleDateString()}
						</dd>
					</div>
				</dl>
			</div>

			{userData.createdGames.length > 0 && (
				<div className='border-t border-gray-200 pt-5'>
					<h4 className='mb-2 text-sm font-medium text-gray-500'>
						Created Games
					</h4>
					<ul className='space-y-2'>
						{userData.createdGames.map((game: any) => (
							<li key={game._id}>
								<a
									href={`/admin/games/${game._id}`}
									className='text-sm text-indigo-600 hover:text-indigo-900'
								>
									{game.title}
								</a>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	)

	return (
		<SlideoverPanel
			open={open}
			onClose={onClose || (() => {})}
			title='User Details'
		>
			{content}
		</SlideoverPanel>
	)
}
