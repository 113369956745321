import { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { twMerge } from 'tailwind-merge'
import DocumentsContext from '../../contexts/documents'
import useItemFilter from '../../hooks/UseItemFilter'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useGetDocumentById from '../../hooks/useGetDocumentById'
import useHandleShareDocumentWith from '../../hooks/useHandleShareDocumentWith'
import { IRegisteredUser } from '../../interfaces/users'
import Avatar from '../Avatar'
import ItemTypes from '../draganddrop/ItemTypes'
import { INTERFACE_DROPSHADOW } from '../interface/constants'
import SmallUppercase from '../interface/text/SmallUppercase'
import UserMenu from './UserMenu'
import UserPill from './UserPill'

type Props = {
	user: IRegisteredUser
	className?: string
	style?: React.CSSProperties
}

export default function UserHead({ user, className, style }: Props) {
	const { username, userId, userSettings } = user
	const { role } = userSettings
	const isGM = role === 'gm'
	const { dispatchDocuments } = useContext(DocumentsContext)
	const { handleShareWith } = useHandleShareDocumentWith()
	const color = userSettings?.color
	const assumedCharacterId = userSettings?.assumedCharacterId
	const { document } = useGetDocumentById(assumedCharacterId)
	const { checkAccess } = useItemFilter()
	const hasAccessToAssumedCharacter = checkAccess(document)
	const avatarSrc = useGetAvatarSrc({ userId })

	const handleDrop = ({ id }) => {
		handleShareWith(id, userId)
	}

	const handleClick = () => {
		console.log('handleClick', hasAccessToAssumedCharacter)
		if (hasAccessToAssumedCharacter) {
			dispatchDocuments({
				type: 'OPEN_DOCUMENT',
				payload: { documentId: assumedCharacterId },
			})
		}
	}

	const [{ isOver }, drop] = useDrop({
		accept: [ItemTypes.DOCUMENT],
		drop: handleDrop,
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	})

	return (
		<li
			ref={drop}
			className={twMerge(
				'group relative flex flex-col items-center transition-all duration-300 ease-in-out',
				assumedCharacterId && 'cursor-pointer',
				className,
			)}
			style={{
				...style,
				maxWidth: '32rem',
				transform: isOver ? 'scale(1.1)' : 'scale(1)',
			}}
			onClick={handleClick}
		>
			<UserMenu userId={userId}>
				<Avatar
					src={avatarSrc}
					color={color}
					userId={userId}
					userName={username}
					size='xl'
					style={{
						boxShadow: INTERFACE_DROPSHADOW,
					}}
				/>
				{isGM && (
					<SmallUppercase
						className='absolute left-1/2 bottom-0.5 -translate-x-1/2 translate-y-1/2 rounded-full px-1.5 py-0.5 text-xs text-white'
						style={{
							color: 'white',
							backgroundColor: color,
						}}
					>
						GM
					</SmallUppercase>
				)}
				<UserPill
					userId={userId}
					className='absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-7 transform opacity-0 transition-all duration-300 ease-in-out group-hover:translate-y-9 group-hover:opacity-100'
				/>
			</UserMenu>
		</li>
	)
}
