const Footer = () => {
	const isNotFrontsite =
		location.pathname.includes('/game/') ||
		location.pathname.includes('/book/') ||
		location.pathname.includes('/document/') ||
		location.pathname.includes('/resource/') ||
		location.pathname.includes('/sheet/')

	if (isNotFrontsite) return null

	const currentYear = new Date().getFullYear()

	return (
		<div className='business-info my-12 text-center text-sm text-gray-400'>
			<p>&copy; {currentYear} New Realms, Inc. All rights reserved.</p>
			<p>
				<a href='/terms-of-service/' className='text-gray-400 hover:text-white'>
					Terms of Service
				</a>{' '}
				•{' '}
				<a href='/privacy-policy/' className='text-gray-400 hover:text-white'>
					Privacy Policy
				</a>
			</p>
		</div>
	)
}

export default Footer
