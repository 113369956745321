import { gql, useMutation, useQuery } from '@apollo/client'
import { useContext } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/auth'
import { CREATE_GAME } from '../../graphql/games'
import Label from '../FormComponents/Label'
import Input from '../Input'
import ButtonLink from './elements/ButtonLink'
import ButtonText from './elements/ButtonText'
import Card from './elements/Card'
import GameCard from './elements/GameCard'
import PageHeader from './sections/PageHeader'

const GET_GAME_TEMPLATES = gql`
	query GetGameTemplates {
		gameTemplates {
			_id
			title
			description
			coverImage
			logo
			hideLogo
			assets {
				byId
				allIds
			}
			creator {
				_id
				name
				email
			}
			isTemplate
		}
	}
`

interface CreatorShallow {
	_id: string
	name: string
}

interface TemplateGame {
	_id: string
	title: string
	description: string
	coverImage: string | null
	logo: string
	hideLogo: boolean
	assets: {
		byId: { [key: string]: any }
		allIds: string[]
	}
	creator: CreatorShallow
	users: string[]
	createdAt: string
	updatedAt: string
	isTemplate: boolean
}

const NewGame = () => {
	const navigate = useNavigate()
	const { authState } = useContext(AuthContext)
	const { data: templateData, loading: templatesLoading } = useQuery<{
		gameTemplates: TemplateGame[]
	}>(GET_GAME_TEMPLATES, {
		skip: !authState.token,
	})

	const {
		register,
		handleSubmit,
		formState: { isDirty },
		watch,
		setValue,
	} = useForm({
		reValidateMode: 'onChange',
	})

	const selectedTemplateId = watch('templateId')
	const selectedTemplate = templateData?.gameTemplates.find(
		t => t._id === selectedTemplateId,
	)

	const [createGame, { loading }] = useMutation(CREATE_GAME, {
		onCompleted: data => {
			navigate(`/game/${data.createGame._id}`)
		},
		onError: error => {
			console.error('Error creating game:', error)
		},
	})

	const handleSave: SubmitHandler<FieldValues> = formData => {
		createGame({
			variables: {
				createGameInput: {
					title: formData.title,
					description: selectedTemplate?.description || '',
					templateId: formData.templateId || null,
				},
			},
		})
	}

	const handleTemplateSelect = (template: TemplateGame | null) => {
		setValue('templateId', template?._id || '', { shouldDirty: true })
		const titleInput = document.querySelector(
			'input[name="title"]',
		) as HTMLInputElement
		if (titleInput && template && !titleInput.value) {
			titleInput.value = `${template.title} (Copy)`
			titleInput.focus()
		}
	}

	return (
		<>
			<Card>
				<ButtonLink className='mb-16 block' to='/profile'>
					← Go Back
				</ButtonLink>
				<PageHeader title='New Game' className='mb-12' />

				<form onSubmit={handleSubmit(handleSave)}>
					<Label>Title</Label>
					<Input
						placeholder='Add a game title...'
						inputClassName='bg-gray-900'
						{...register('title', {
							required: true,
						})}
					/>

					{!templatesLoading && templateData?.gameTemplates?.length > 0 && (
						<div className='mt-6'>
							<Label>Start from Template (Optional)</Label>
							<div className='mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
								<div
									onClick={() => handleTemplateSelect(null)}
									className={`cursor-pointer rounded-xl border-2 p-2 transition-all ${
										!selectedTemplateId
											? 'border-indigo-600'
											: 'border-transparent hover:border-indigo-300'
									}`}
								>
									<input
										type='radio'
										{...register('templateId')}
										value=''
										className='sr-only'
									/>
									<div className='flex aspect-[1.65] items-center justify-center rounded-lg bg-gray-100'>
										<div className='text-center'>
											<div className='font-medium text-gray-900'>
												Start from Scratch
											</div>
											<div className='text-sm text-gray-500'>
												Create a new empty game
											</div>
										</div>
									</div>
								</div>

								{templateData.gameTemplates.map(template => (
									<div
										key={template._id}
										onClick={() => handleTemplateSelect(template)}
										className={`cursor-pointer rounded-xl border-2 p-2 transition-all ${
											selectedTemplateId === template._id
												? 'border-indigo-600'
												: 'border-transparent hover:border-indigo-300'
										}`}
									>
										<input
											type='radio'
											{...register('templateId')}
											value={template._id}
											className='sr-only'
										/>
										<GameCard game={template} />
										<div className='mt-2 text-center text-xs text-gray-500'>
											{template.title}
										</div>
									</div>
								))}
							</div>
						</div>
					)}

					<ButtonText
						type='submit'
						className='float-right mt-8'
						disabled={!isDirty || loading}
					>
						Create Game
					</ButtonText>
				</form>
			</Card>
		</>
	)
}

export default NewGame
