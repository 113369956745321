import * as yup from 'yup'

export const passwordSchema = yup
	.string()
	.required('Password is required')
	.min(8, 'Password must be at least 8 characters')

export const confirmPasswordSchema = yup
	.string()
	.oneOf([yup.ref('password')], 'Passwords must match')
	.required('Please confirm your password')

export const passwordValidationSchema = yup.object({
	password: passwordSchema,
	confirmPassword: confirmPasswordSchema,
})
