import { forwardRef, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	acceptTypes?: string
}

const UploadField = forwardRef<HTMLInputElement, IProps>((props, ref) => {
	const { acceptTypes, className, ...rest } = props
	const acceptedTypes =
		acceptTypes || 'image/png, image/jpeg, image/webp, image/gif'

	return (
		<input
			className={twMerge('leading-16 h-full w-full rounded-lg', className)}
			type='file'
			accept={acceptedTypes}
			ref={ref}
			{...rest}
		/>
	)
})

export default UploadField
