import { IDocument } from '../../../../shared/types/document'
import BaseType from '../interface/text/BaseType'
import AccessDots from './AccessDots'

type Props = {
	title: string
	singularName: string
	document: IDocument
}

const DocumentWindowTitle = ({ title, singularName, document }: Props) => {
	false && console.log('DocumentWindowTitle', title, singularName, document)

	return (
		<div className='flex gap-2'>
			<BaseType>{title}</BaseType>
			<AccessDots document={document} />
		</div>
	)
}

export default DocumentWindowTitle
