// this is used both for Window.tsx as well as for DocumentApp.tsx
import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import WindowsContext from '../../contexts/windows'
import CloseButton from '../FormComponents/CloseButton'
import DialogBody from './Body'
import DialogFooter from './Footer'
import DialogHeader from './Header'

type Props = {
	title: React.ReactNode
	children: React.ReactNode
	headerChildren?: React.ReactNode
	footerChildren?: React.ReactNode
	className?: string
	bodyClassName?: string
	headerIcon?: React.ReactNode
	zIndex?: number
	onClose?: () => void
	showCloseButton?: boolean
	style?: React.CSSProperties
}

const WindowInner = ({
	title,
	children,
	className,
	headerChildren,
	footerChildren,
	bodyClassName = '',
	headerIcon,
	zIndex = 0,
	onClose = () => {}, // how clients can call things when we're closing
	showCloseButton = true,
	style,
}: Props) => {
	const { windowsState } = useContext(WindowsContext)

	return (
		<div
			className={twMerge('absolute inset-0 flex flex-col', className)}
			style={style}
		>
			<DialogHeader
				title={title}
				className={twMerge(
					zIndex + 1 === windowsState.windows.length
						? 'text-gray-900'
						: 'text-gray-500',
				)}
				headerIcon={headerIcon}
			>
				{headerChildren}
				{showCloseButton && <CloseButton onClick={onClose} />}
			</DialogHeader>
			<DialogBody className={twMerge('relative', bodyClassName)}>
				{children}
			</DialogBody>
			{footerChildren && <DialogFooter>{footerChildren}</DialogFooter>}
		</div>
	)
}

export default WindowInner
