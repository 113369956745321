import { ArrowLeft } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import ToolbarButton from '../toolbar/ToolbarButton'

export default function BackToBooksButton() {
	return (
		<NavLink to='/profile/books'>
			<ToolbarButton tooltip='Back to Books' className='mr-4'>
				<ArrowLeft aria-hidden='true' />
			</ToolbarButton>
		</NavLink>
	)
}
