import { animated, useTransition } from '@react-spring/web'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import ToolsContext from '../../contexts/tools'
import useShowMap from '../../hooks/useShowMap'
import useUser from '../../hooks/useUser'
import GuideButton from '../interface/GuideButton'
import GuideNotification from '../interface/GuideNotification'
import Toolbar from '../interface/toolbar/Toolbar'
import ClearDrawingsButton from './ClearDrawingsButton'
import DrawModeButton from './DrawModeButton'
import FogModeButton from './FogModeButton'
import RedoButton from './RedoButton'
import UndoButton from './UndoButton'

const SceneTools = () => {
	const { activeSceneId } = useUser()
	const { showMap } = useShowMap()
	const { toolsState } = useContext(ToolsContext)
	const drawAnchorRef = useRef<HTMLButtonElement>(null)
	const clearDrawingsAnchorRef = useRef<HTMLButtonElement>(null)
	const fogAnchorRef = useRef<HTMLButtonElement>(null)
	const [showDrawTools, setShowDrawTools] = useState(false)

	useEffect(() => {
		setShowDrawTools(toolsState.mode === 'draw')
	}, [toolsState.mode])

	// Memoize the drawing tools array
	const drawingTools = useMemo(
		() => [
			{ key: 'undo', Component: UndoButton },
			{ key: 'redo', Component: RedoButton },
			{
				key: 'clear',
				Component: ClearDrawingsButton,
				ref: clearDrawingsAnchorRef,
			},
		],
		[],
	)

	const transitions = useTransition(showDrawTools ? drawingTools : [], {
		keys: item => item.key,
		from: { width: 0, scale: 0, opacity: 0 },
		enter: { width: 48, scale: 1, opacity: 1 },
		leave: { width: 0, scale: 0, opacity: 0 },
		trail: 100,
		config: { tension: 300, friction: 20 },
	})

	if (!activeSceneId) {
		return null
	}

	const MapTools = () => {
		if (showMap === 'false') return null

		return (
			<>
				<DrawModeButton ref={drawAnchorRef} />
				{transitions((style, item) => (
					<animated.div
						style={{
							width: style.width,
							overflow: 'hidden',
						}}
					>
						<animated.div
							style={{
								transform: style.scale.to(s => `scale(${s})`),
								opacity: style.opacity,
								width: 48, // Fixed width for the button
								height: 48, // Fixed height for the button
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<item.Component ref={item.ref} />
						</animated.div>
					</animated.div>
				))}
				{false && <FogModeButton ref={fogAnchorRef} />}
			</>
		)
	}

	return (
		<div className='flex flex-row justify-end'>
			<div className='pointer-events-auto flex flex-col items-center'>
				<GuideNotification />
				<div className='flex items-center gap-2'>
					{showMap !== 'false' && (
						<Toolbar>
							<MapTools />
							{showDrawTools && (
								<div className='h-2/3 border-l border-gray-200' />
							)}
							<GuideButton />
						</Toolbar>
					)}
				</div>
			</div>
		</div>
	)
}

export default SceneTools
