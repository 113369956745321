const BookSpineEffect = () => {
	return (
		<div
			className='absolute inset-0 mix-blend-multiply'
			style={{
				background:
					'linear-gradient(90deg, #AAA 0%, #DDD 1%, #DDD 2%, #999 3%, #FFF 4%, #FFF 22%, #FFF 100%)',
			}}
		></div>
	)
}

export default BookSpineEffect
