import { useQuery } from '@apollo/client'
import { useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContent } from './components/AppContent'
import { ProfileEditProvider } from './contexts/ProfileEditContext'
import { AM_I_AUTHED } from './graphql/users'
import { IAuthState } from './interfaces/auth'
import { IUser } from './interfaces/users'
import AuthReducer from './reducers/auth'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

export default function App() {
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')
	const origin = window.location.origin

	const loginHandler = (token: string, user: IUser) => {
		authDispatch({
			type: 'UPDATE_ALL',
			payload: {
				userId: user._id,
				token,
				userDetails: {
					name: user.name,
					email: user.email,
					avatar: user.avatar,
				},
			},
		})
	}

	const logoutHandler = () => {
		const options: RequestInit = {
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
			},
		}

		fetch(`${origin}/api/logout/`, options)
			.then(res => {
				if (!res.ok) {
					alert('An error occurred while attempting to sign out.')
				}
				return res
			})
			.then(() => {
				authDispatch({ type: 'LOGOUT' })

				localStorage?.removeItem('token')
			})
	}

	const loadedUser = localStorage?.getItem('user')

	const initialAuthState: IAuthState = {
		token: localStorage?.getItem('token') || null,
		userId: loadedUser || '',
		userDetails: {
			name: '',
			avatar: '',
			email: '',
		},
		login: loginHandler,
		logout: logoutHandler,
	}

	const [authState, authDispatch] = useReducer(AuthReducer, initialAuthState)
	const authToken = authState.token

	const { loading } = useQuery(AM_I_AUTHED, {
		onCompleted(data) {
			const { token, user } = data.amIAuthed

			if (!user || !token) {
				authDispatch({ type: 'LOGOUT' })

				localStorage?.removeItem('token')
			} else {
				authDispatch({
					type: 'UPDATE_ALL',
					payload: {
						userId: user._id,
						token: token,
						userDetails: {
							name: user.name,
							avatar: user.avatar,
							email: user.email,
							isAdmin: user.isAdmin,
						},
					},
				})
			}
		},
		onError(error) {
			throw error
		},
	})

	if (loading) {
		return <div>Loading...</div>
	}

	const isAuthed = !!authToken
	const isInvited = !!inviteToken

	const hideNavigation = (pathname: string) => {
		return (
			pathname.startsWith('/admin') ||
			pathname.startsWith('/game/') ||
			pathname.startsWith('/document/')
		)
	}

	return (
		<ProfileEditProvider>
			<AppContent
				authState={authState}
				authDispatch={authDispatch}
				loading={loading}
				isAuthed={isAuthed}
				isInvited={isInvited}
				hideNavigation={hideNavigation}
			/>
		</ProfileEditProvider>
	)
}
