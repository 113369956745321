import { useMutation } from '@apollo/client'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { UPDATE_USER_PASSWORD } from '../graphql/users'
import { passwordSchema } from '../utils/validation'
import Caption from './Caption'
import Button from './FormComponents/Button'
import Input from './Input'
import ModalWindow from './window/ModalWindow'

export interface IPasswordEditorProps {
	onClose: () => void
}

interface IFormInputs {
	currentPassword: string
	newPassword: string
	newPasswordConfirmation: string
}

export default function PasswordEditor({ onClose }: IPasswordEditorProps) {
	const [isOpen, setIsOpen] = useState(true)
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(
			yup.object({
				currentPassword: yup.string().required('Current password is required'),
				newPassword: passwordSchema,
				newPasswordConfirmation: yup
					.string()
					.oneOf([yup.ref('newPassword')], 'Passwords must match')
					.required('Please confirm your password'),
			}),
		),
		reValidateMode: 'onChange',
	})

	const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD, {
		onCompleted: () => {
			handleClose()
		},
		onError: error => {
			alert(error.message)
			console.error(error)
		},
	})

	const handleClose = () => {
		setIsOpen(false)
		setTimeout(onClose, 500)
	}

	const onSubmit = async (data: IFormInputs) => {
		updateUserPassword({
			variables: {
				currentPassword: data.currentPassword,
				newPassword: data.newPassword,
			},
		})
	}

	return (
		<ModalWindow
			id='password-editor'
			title='Change Password'
			headerIcon={<LockClosedIcon className='h-5 w-5' aria-hidden='true' />}
			onClose={handleClose}
			size='small'
			dynamicHeight
			isOpen={isOpen}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Input
					type='password'
					placeholder='Current password...'
					className='mt-4'
					{...register('currentPassword')}
				/>
				{errors.currentPassword && (
					<Caption className='text-red-500 dark:text-red-800'>
						{errors.currentPassword.message}
					</Caption>
				)}

				<Input
					type='password'
					placeholder='New password...'
					className='mt-6'
					{...register('newPassword')}
				/>
				{errors.newPassword && (
					<Caption className='text-red-500 dark:text-red-800'>
						{errors.newPassword.message}
					</Caption>
				)}

				<Input
					type='password'
					placeholder='New password again...'
					className='mt-2'
					{...register('newPasswordConfirmation')}
				/>
				{errors.newPasswordConfirmation && (
					<Caption className='text-red-500 dark:text-red-800'>
						{errors.newPasswordConfirmation.message}
					</Caption>
				)}

				<Button
					type='submit'
					className='mt-8'
					disabled={Object.keys(errors).length > 0}
				>
					Change Password
				</Button>
			</form>
		</ModalWindow>
	)
}
