import { Reducer } from 'react'
import { IUsersState, TUsersAction } from '../interfaces/users'

const UsersReducer: Reducer<IUsersState, TUsersAction> = (state, action) => {
	const { type, payload } = action

	switch (type) {
		case 'UPDATE_SOCKET_USER_CONNECTION': {
			const userIndex = state.users.findIndex(u => u.userId === payload.userId)
			if (userIndex === -1) {
				// User not found, return the original state
				return state
			}

			const user = state.users[userIndex]
			if (user.socketIds.includes(payload.socketId)) {
				// Socket ID already exists, return the original state
				return state
			}

			// Update the user with the new socket ID added to the array
			const updatedUser = {
				...user,
				socketIds: [...user.socketIds, payload.socketId],
				online: true, // Set user to online when a new socket connects
			}
			const updatedUsers = [...state.users]
			updatedUsers[userIndex] = updatedUser

			return {
				...state,
				users: updatedUsers,
			}
		}

		case 'UPDATE_USERS': {
			return {
				...state,
				users: payload.users,
			}
		}

		case 'UPDATE_USER_PROFILE': {
			const userIndex = state.users.findIndex(u => u.userId === payload.userId)
			if (userIndex === -1) return state

			const updatedUsers = [...state.users]
			updatedUsers[userIndex] = {
				...updatedUsers[userIndex],
				userProfile: {
					...updatedUsers[userIndex].userProfile,
					...payload.profile,
				},
			}

			return {
				...state,
				users: updatedUsers,
			}
		}

		default:
			return state
	}
}

export default UsersReducer
