import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import AuthContext from '../../../../contexts/auth'
import UsersContext from '../../../../contexts/users'
import { UPDATE_AVATAR } from '../../../../graphql/users'
import useFileUpload from '../../../../hooks/useFileUpload'
import Avatar from '../../../Avatar'

interface AvatarSectionProps {
	fileRef: React.RefObject<HTMLInputElement>
	isUploading: boolean
	uploadError: string | null
	previewUrl: string | null
	setPreviewUrl: (url: string | null) => void
	setIsUploading: (isUploading: boolean) => void
	setUploadError: (error: string | null) => void
}

const AvatarSection = ({
	fileRef,
	isUploading,
	uploadError,
	previewUrl,
	setPreviewUrl,
	setIsUploading,
	setUploadError,
}: AvatarSectionProps) => {
	const { authState, authDispatch } = useContext(AuthContext)
	const { dispatchUsers } = useContext(UsersContext)
	const uploadFile = useFileUpload()
	const [updateAvatar] = useMutation(UPDATE_AVATAR)
	const hasAvatar = !!(previewUrl || authState.userDetails.avatar)
	const avatarUrl = previewUrl || authState.userDetails.avatar

	const handleUpload = async () => {
		const file = fileRef.current?.files?.[0]
		if (!file) return

		try {
			setIsUploading(true)
			setUploadError(null)
			const oldImageUrl = authState.userDetails.avatar

			const uploadResult = await uploadFile(file)

			await updateAvatar({
				variables: {
					avatar: uploadResult.fileurl,
					userId: authState.userId,
				},
			})

			if (oldImageUrl) {
				await fetch('/api/delete', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ key: oldImageUrl }),
				})
			}

			authDispatch({
				type: 'UPDATE_PROFILE_PICTURE',
				payload: { avatar: uploadResult.fileurl },
			})

			dispatchUsers({
				type: 'UPDATE_USER_PROFILE',
				payload: {
					userId: authState.userId,
					profile: {
						avatar: uploadResult.fileurl,
					},
				},
			})

			setPreviewUrl(null)
			if (fileRef.current) fileRef.current.value = ''
		} catch (error) {
			setUploadError('Failed to update avatar. Please try again.')
			console.error('Error updating avatar:', error)
		} finally {
			setIsUploading(false)
		}
	}

	const handleRemoveAvatar = async () => {
		try {
			setIsUploading(true)
			setUploadError(null)
			const oldImageUrl = authState.userDetails.avatar

			await updateAvatar({
				variables: {
					avatar: '',
					userId: authState.userId,
				},
			})

			if (oldImageUrl) {
				await fetch('/api/delete', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ key: oldImageUrl }),
				})
			}

			authDispatch({
				type: 'UPDATE_PROFILE_PICTURE',
				payload: { avatar: null },
			})

			dispatchUsers({
				type: 'UPDATE_USER_PROFILE',
				payload: {
					userId: authState.userId,
					profile: {
						avatar: null,
					},
				},
			})

			setPreviewUrl(null)
			if (fileRef.current) fileRef.current.value = ''
		} catch (error) {
			setUploadError('Failed to remove avatar. Please try again.')
			console.error('Error removing avatar:', error)
		} finally {
			setIsUploading(false)
		}
	}

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0]
		if (file) {
			const url = URL.createObjectURL(file)
			setPreviewUrl(url)
			handleUpload()
		}
	}

	const triggerFileSelect = () => {
		fileRef.current?.click()
	}

	return (
		<div className='flex flex-col items-center space-y-4'>
			<div className='relative'>
				<Avatar
					src={avatarUrl}
					userName={authState.userDetails.name}
					userId={authState.userId}
					size='xl'
				/>

				{isUploading && (
					<div className='absolute inset-0 flex items-center justify-center rounded-full bg-black/60'>
						<div className='border-3 h-8 w-8 animate-spin rounded-full border-white border-t-transparent' />
					</div>
				)}
			</div>

			<div className='flex justify-center gap-2'>
				<button
					type='button'
					onClick={triggerFileSelect}
					className='rounded-md bg-gray-800 px-2 py-1 text-sm text-white hover:bg-gray-700'
					title={hasAvatar ? 'Change avatar' : 'Upload avatar'}
				>
					{hasAvatar ? 'Change' : 'Upload Image'}
				</button>
				{hasAvatar && (
					<button
						type='button'
						onClick={handleRemoveAvatar}
						className='rounded-md bg-gray-800 px-2 py-1 text-sm text-red-500 hover:bg-gray-700'
						title='Remove avatar'
					>
						Remove
					</button>
				)}
			</div>

			<input
				type='file'
				ref={fileRef}
				className='hidden'
				accept='image/*'
				onChange={handleFileChange}
				data-modal-control='true'
			/>

			{uploadError && (
				<p className='mt-2 text-center text-sm text-red-500'>{uploadError}</p>
			)}
		</div>
	)
}

export default AvatarSection
