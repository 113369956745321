import { Video } from 'lucide-react'
import { useContext, useEffect } from 'react'
import GameContext from '../../contexts/game'
import { useGuide } from '../../contexts/guideContext'
import { socket } from '../../contexts/socket'
import useUser from '../../hooks/useUser'
import ToolButton from './toolbar/ToolButton'

export default function GuideButton() {
	const { guidingUserId, setGuidingUserId } = useGuide()
	const { userId, isGM, activeSceneId } = useUser()
	const { game } = useContext(GameContext)
	const isMap = game.documents.byId[activeSceneId]?.type === 'map'

	// Stop guiding when scene changes
	useEffect(() => {
		if (guidingUserId === userId) {
			setGuidingUserId(null)
			socket.emit('guideStatusUpdate', { userId, isGuiding: false })
		}
	}, [activeSceneId])

	const handleClick = () => {
		const isGuiding = !guidingUserId
		setGuidingUserId(isGuiding ? userId : null)
		socket.emit('guideStatusUpdate', { userId, isGuiding })
	}

	if (!isGM || !isMap) return null

	return (
		<ToolButton
			tooltip='Guide Map'
			isOpen={guidingUserId === userId}
			onClick={handleClick}
		>
			<Video className='h-5 w-5' aria-hidden='true' />{' '}
		</ToolButton>
	)
}
