import { AnnotationType } from 'pdfjs-dist/types/src/shared/util'
import { PDFViewer as PDFViewerComponent } from 'pdfjs-dist/web/pdf_viewer.mjs'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'
import { IAsset } from '../../../../shared/types/asset'
import AssetManager from '../AssetManager'

export type PDFImageAnnotation = {
	id: string
	value: string
	defaultValue: string | null
	editable: boolean
	name: string
	rect: [number, number, number, number]
	hidden: boolean
	actions: {
		Action: string[]
	}
	page: number
	strokeColor?: {
		0: number
		1: number
		2: number
	}
	fillColor?: {
		0: number
		1: number
		2: number
	}
	rotation: number
	type: keyof typeof AnnotationType
}

export const useCustomImageAnnotations = (
	pdfViewerRef: React.RefObject<PDFViewerComponent>,
	onChange: (args: { annotationId: string; value: string }) => void,
) => {
	const [imageAnnotations, setImageAnnotations] = useState<
		PDFImageAnnotation[]
	>([])
	const [portals, setPortals] = useState<React.ReactPortal[]>([])

	const findImageAnnotations = async () => {
		if (!pdfViewerRef.current) return
		const pdfDoc = pdfViewerRef.current?.pdfDocument
		if (!pdfDoc) return

		const fieldObjects = (await pdfDoc.getFieldObjects()) as Record<
			string,
			any[]
		>
		if (!fieldObjects) return

		// Flatten and filter all annotations that have a 'buttonImportIcon' action
		const foundAnnotations = Object.values(fieldObjects)
			.flatMap(annotations =>
				annotations.filter(a =>
					a.actions?.Action?.some((action: string) =>
						action.includes('buttonImportIcon'),
					),
				),
			)
			.map(a => ({
				...a,
				rect: a.rect,
			}))

		const changedAnnotationValues = pdfDoc.annotationStorage?.getAll()

		const updatedAnnotations = foundAnnotations.map(annotation => ({
			...annotation,
			...(changedAnnotationValues?.[annotation.id] || {}),
		}))

		// Only update state if annotations have changed
		if (
			JSON.stringify(updatedAnnotations) !== JSON.stringify(imageAnnotations)
		) {
			setImageAnnotations(updatedAnnotations)
		}
	}

	useEffect(() => {
		if (!pdfViewerRef.current) return
		const eventBus = pdfViewerRef.current.eventBus
		if (!eventBus) return

		eventBus.on('pagerendered', findImageAnnotations)
		return () => {
			eventBus.off('pagerendered', findImageAnnotations)
		}
	}, [pdfViewerRef.current])

	const handleAssetChange = (asset: IAsset, annotation: PDFImageAnnotation) => {
		pdfViewerRef.current?.pdfDocument?.annotationStorage?.setValue(
			annotation.id,
			{
				value: asset?._id ?? '',
			},
		)

		onChange({
			annotationId: annotation.id,
			value: asset?._id ?? '',
		})

		findImageAnnotations()
	}

	const renderPortals = useCallback(() => {
		if (!pdfViewerRef.current) return
		const container = pdfViewerRef.current.container
		if (!container) return

		const newPortals = imageAnnotations
			.map(annotation => {
				const element = container.querySelector(
					`[data-annotation-id="${annotation.id}"]`,
				)
				if (!element) return null

				return createPortal(
					<AssetManager
						assetId={annotation.value}
						onAssetChange={asset => handleAssetChange(asset, annotation)}
						className={twMerge(
							'absolute inset-0 z-[1000] aspect-auto',
							annotation.value === 'bg-transparent' && '',
						)}
						showBlur={false}
					/>,
					element,
				)
			})
			.filter(Boolean) as React.ReactPortal[]

		setPortals(newPortals)
	}, [imageAnnotations, pdfViewerRef])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		const container = pdfViewerRef.current.container
		if (!container) return

		let renderTimeout: NodeJS.Timeout | null = null

		const debouncedRender = () => {
			if (renderTimeout) clearTimeout(renderTimeout)
			renderTimeout = setTimeout(renderPortals, 100)
		}

		// Initial render
		debouncedRender()

		const observer = new MutationObserver(mutations => {
			const hasRelevantMutation = mutations.some(mutation => {
				return Array.from(mutation.addedNodes).some(node => {
					if (!(node instanceof HTMLElement)) return false
					return (
						node.hasAttribute('data-annotation-id') ||
						node.querySelector('[data-annotation-id]') !== null
					)
				})
			})

			if (hasRelevantMutation) debouncedRender()
		})

		observer.observe(container, {
			childList: true,
			subtree: true,
			attributes: false,
			characterData: false,
		})

		return () => {
			observer.disconnect()
			if (renderTimeout) clearTimeout(renderTimeout)
		}
	}, [imageAnnotations, pdfViewerRef, renderPortals])

	return {
		findImageAnnotations,
		portals,
	}
}
