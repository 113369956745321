import { twMerge } from 'tailwind-merge'

interface IProps {
	children: React.ReactNode
	className?: string
	innerClassName?: string
}

const DialogBody: React.FC<IProps> = ({
	children,
	className = '',
	innerClassName = '',
}) => {
	return (
		<div
			className={twMerge(
				'outer-body flex flex-1 overflow-scroll px-4 text-left text-white',
				className,
			)}
		>
			<div
				className={twMerge('inner-body flex w-full flex-col', innerClassName)}
			>
				{children}
			</div>
		</div>
	)
}

export default DialogBody
