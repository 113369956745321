import { useEffect } from 'react'
import {
	ScaleModeType,
	SpreadMode,
	SpreadModeType,
} from '../types/pdfViewerTypes'

interface UsePdfKeyboardNavigationProps {
	currentPage: number
	pageCount: number
	spreadMode: SpreadModeType
	handlePageChange: (page: number, isAbsolute: boolean) => void
	handleZoomIn: () => void
	handleZoomOut: () => void
	setScaleMode: (mode: ScaleModeType) => void
	setShowOutline: (show: boolean) => void
	showOutline: boolean
}

export const usePdfKeyboardNavigation = ({
	currentPage,
	pageCount,
	spreadMode,
	handlePageChange,
	handleZoomIn,
	handleZoomOut,
	setScaleMode,
	setShowOutline,
	showOutline,
}: UsePdfKeyboardNavigationProps) => {
	useEffect(() => {
		const getNextPage = (forward: boolean): number => {
			// In single page mode, just move one page at a time
			if (spreadMode === SpreadMode.NONE) {
				return forward ? currentPage + 1 : currentPage - 1
			}

			// For even spread mode (first page alone)
			if (spreadMode === SpreadMode.EVEN) {
				if (currentPage === 1) {
					return forward ? 2 : 1
				}
				return forward ? currentPage + 2 : currentPage - 2
			}

			// For odd spread mode (spreads start from page 1)
			if (spreadMode === SpreadMode.ODD) {
				if (currentPage === 2 && !forward) {
					return 1
				}
				if (currentPage === 1) {
					return forward ? 2 : 1
				}
				return forward ? currentPage + 2 : currentPage - 2
			}

			return forward ? currentPage + 1 : currentPage - 1
		}

		const handleKeyDown = (e: KeyboardEvent) => {
			// Don't handle if event was already handled
			if (e.defaultPrevented) {
				return
			}

			// Don't handle keyboard events if user is typing in an input
			const target = e.target as HTMLElement
			if (
				target.tagName === 'INPUT' ||
				target.tagName === 'TEXTAREA' ||
				target.tagName === 'SELECT'
			) {
				return
			}

			// Don't interfere with browser shortcuts
			if ((e.ctrlKey || e.metaKey) && !['0', '+', '-', 'o'].includes(e.key)) {
				return
			}

			// Don't handle navigation if PDF is not ready
			if (pageCount === 0) {
				return
			}

			let handled = true

			switch (e.key) {
				case 'ArrowRight':
				case 'PageDown': {
					const nextPage = getNextPage(true)
					if (nextPage <= pageCount) {
						handlePageChange(nextPage, false)
					}
					break
				}
				case 'ArrowLeft':
				case 'PageUp': {
					const prevPage = getNextPage(false)
					if (prevPage >= 1) {
						handlePageChange(prevPage, false)
					}
					break
				}
				case 'Home':
					handlePageChange(1, true)
					break
				case 'End':
					handlePageChange(pageCount, true)
					break
				case '+':
					if (e.ctrlKey || e.metaKey) {
						handleZoomIn()
					} else {
						handled = false
					}
					break
				case '-':
					if (e.ctrlKey || e.metaKey) {
						handleZoomOut()
					} else {
						handled = false
					}
					break
				case '0':
					if (e.ctrlKey || e.metaKey) {
						setScaleMode('auto')
					} else {
						handled = false
					}
					break
				case 'o':
					if (e.ctrlKey || e.metaKey) {
						setShowOutline(!showOutline)
					} else {
						handled = false
					}
					break
				default:
					handled = false
			}

			if (handled) {
				e.preventDefault()
				e.stopPropagation()
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [
		currentPage,
		pageCount,
		spreadMode,
		handlePageChange,
		handleZoomIn,
		handleZoomOut,
		setScaleMode,
		setShowOutline,
		showOutline,
	])
}
