import { Combobox } from '@headlessui/react'
import { ChevronRight, Search, X } from 'lucide-react'
import { PDFViewer as PDFViewerComponent } from 'pdfjs-dist/web/pdf_viewer.mjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_BACKGROUND_COLOR } from '../interface/constants'

// Width constraints
const MIN_WIDTH = 100 // Below this, outline will hide
const MAX_WIDTH = 600
const STORAGE_KEY_PREFIX = 'pdf-outline-settings-'

export interface PDFOutlineItem {
	title: string
	bold: boolean
	italic: boolean
	color: Uint8ClampedArray
	dest: any
	url: string | null
	unsafeUrl: string | null
	newWindow: boolean
	count: number
	items: PDFOutlineItem[]
}

interface PDFOutlineProps {
	outline: PDFOutlineItem[]
	pdfViewer: PDFViewerComponent | null
	onPageChange: (pageNumber: number) => void
	currentPage?: number
	onWidthChange: (width: number) => void
	onRequestHide: () => void
	resourceId: string
	width: number
}

interface FlatOutlineItem extends PDFOutlineItem {
	path: string
	level: number
	parentExpanded: boolean
	parentPaths: string[]
	rootPath: string
}

export const PDFOutline = ({
	outline,
	pdfViewer,
	onPageChange,
	currentPage,
	onWidthChange,
	onRequestHide,
	resourceId,
	width: parentWidth,
}: PDFOutlineProps) => {
	const [query, setQuery] = useState('')
	const [expandedItems, setExpandedItems] = useState<Set<string>>(() => {
		const savedState = localStorage.getItem(STORAGE_KEY_PREFIX + resourceId)
		if (savedState) {
			try {
				const paths: string[] = JSON.parse(savedState)
				return new Set(paths)
			} catch (e) {
				console.error('Failed to parse saved outline state:', e)
				return new Set()
			}
		}
		return new Set()
	})

	// Save expanded items state when it changes
	useEffect(() => {
		const expandedArray = Array.from(expandedItems)
		localStorage.setItem(
			STORAGE_KEY_PREFIX + resourceId,
			JSON.stringify(expandedArray),
		)
	}, [expandedItems, resourceId])

	const [preSearchExpandedItems, setPreSearchExpandedItems] =
		useState<Set<string> | null>(null)
	const [loadingItem, setLoadingItem] = useState<string | null>(null)
	const [activeItemPath, setActiveItemPath] = useState<string | null>(null)
	const [hoveredItem, setHoveredItem] = useState<FlatOutlineItem | null>(null)
	const [width, setWidth] = useState(parentWidth)
	const [isResizing, setIsResizing] = useState(false)
	const resizeRef = useRef<{ startX: number; startWidth: number } | null>(null)

	// Update local width when parent width changes
	useEffect(() => {
		setWidth(parentWidth)
	}, [parentWidth])

	const handleResizeStart = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault()
			setIsResizing(true)
			resizeRef.current = {
				startX: e.pageX,
				startWidth: width,
			}
		},
		[width],
	)

	const handleResizeEnd = useCallback(() => {
		setIsResizing(false)
		resizeRef.current = null

		// Check if we should hide on resize end
		if (width < MIN_WIDTH) {
			onRequestHide()
		}
	}, [width, onRequestHide])

	const handleResize = useCallback(
		(e: MouseEvent) => {
			if (!resizeRef.current) return

			const delta = e.pageX - resizeRef.current.startX
			const newWidth = Math.min(
				MAX_WIDTH,
				Math.max(0, resizeRef.current.startWidth + delta),
			)

			setWidth(newWidth)
			onWidthChange(newWidth)
		},
		[onWidthChange],
	)

	// Handle resize movement
	useEffect(() => {
		if (!isResizing) return

		document.addEventListener('mousemove', handleResize)
		document.addEventListener('mouseup', handleResizeEnd)

		return () => {
			document.removeEventListener('mousemove', handleResize)
			document.removeEventListener('mouseup', handleResizeEnd)
		}
	}, [isResizing, handleResize, handleResizeEnd])

	// Watch width changes and notify parent when it crosses the visibility threshold
	useEffect(() => {
		if (width < MIN_WIDTH && !isResizing) {
			onRequestHide()
		}
	}, [width, isResizing, onRequestHide])

	const highlightText = useCallback((text: string, searchTerm: string) => {
		if (!searchTerm) return text

		const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'))
		return parts.map((part, i) =>
			part.toLowerCase() === searchTerm.toLowerCase() ? (
				<span key={i} className='rounded-sm bg-yellow-100'>
					{part}
				</span>
			) : (
				part
			),
		)
	}, [])

	const flattenOutline = useCallback(
		(
			items: PDFOutlineItem[],
			level = 0,
			parentPath = '',
			parentExpanded = true,
			parentPaths: string[] = [],
			rootPath = '',
		): FlatOutlineItem[] => {
			return items.flatMap((item, index) => {
				const path = `${parentPath}${level}-${index}`
				const isExpanded = expandedItems.has(path)
				const currentRootPath = level === 0 ? path : rootPath
				const flatItem: FlatOutlineItem = {
					...item,
					path,
					level,
					parentExpanded,
					parentPaths: [...parentPaths, path],
					rootPath: currentRootPath,
				}

				const result = [flatItem]
				if (item.items?.length && (isExpanded || query.length > 0)) {
					result.push(
						...flattenOutline(
							item.items,
							level + 1,
							path,
							(parentExpanded && isExpanded) || query.length > 0,
							flatItem.parentPaths,
							currentRootPath,
						),
					)
				}
				return result
			})
		},
		[expandedItems, query],
	)

	const handleSearch = useCallback(
		(newQuery: string) => {
			if (newQuery && !query) {
				// Starting a new search - save current state
				setPreSearchExpandedItems(new Set(expandedItems))
			} else if (!newQuery && query) {
				// Clearing search - restore previous state
				if (preSearchExpandedItems) {
					setExpandedItems(preSearchExpandedItems)
				}
				setPreSearchExpandedItems(null)
			}
			setQuery(newQuery)
		},
		[query, expandedItems, preSearchExpandedItems],
	)

	const filteredItems = useMemo(() => {
		const items = flattenOutline(outline)
		if (!query) return items.filter(item => item.parentExpanded)

		// First find all matching items
		const matchingPaths = new Set(
			items
				.filter(item => item.title.toLowerCase().includes(query.toLowerCase()))
				.map(item => [...item.parentPaths, item.path])
				.flat(),
		)

		// Then filter to show matching items and their parents
		return items.filter(
			item => item.parentExpanded && matchingPaths.has(item.path),
		)
	}, [outline, query, flattenOutline])

	const toggleExpand = useCallback((path: string) => {
		setExpandedItems(prev => {
			const next = new Set(prev)
			if (next.has(path)) {
				next.delete(path)
			} else {
				next.add(path)
			}
			return next
		})
	}, [])

	const handleOutlineClick = useCallback(
		async (item: FlatOutlineItem) => {
			if (!pdfViewer) return
			setLoadingItem(item.path)

			if (item.items && item.items.length > 0) {
				toggleExpand(item.path)
			}

			try {
				const pdf = pdfViewer.pdfDocument
				if (!pdf || !item.dest) return

				let dest = item.dest
				if (typeof dest === 'string') {
					dest = await pdf.getDestination(dest)
				}

				if (Array.isArray(dest)) {
					const ref = dest[0]
					let pageNumber: number

					if (typeof ref === 'object') {
						pageNumber = (await pdf.getPageIndex(ref)) + 1
					} else {
						pageNumber = (ref as number) + 1
					}

					onPageChange(pageNumber)

					await new Promise(resolve => setTimeout(resolve, 100))

					const pageElement = pdfViewer.container.querySelector(
						`[data-page-number="${pageNumber}"]`,
					)

					if (pageElement) {
						pageElement.scrollIntoView({ behavior: 'auto', block: 'center' })
					}
				}
			} catch (error) {
				console.error('Navigation error:', error)
			} finally {
				setLoadingItem(null)
			}
		},
		[pdfViewer, onPageChange, toggleExpand],
	)

	useEffect(() => {
		if (!currentPage || !pdfViewer?.pdfDocument) return

		const findItemForPage = async (
			items: PDFOutlineItem[],
			parentPath = '',
			level = 0,
		): Promise<string | null> => {
			for (let i = 0; i < items.length; i++) {
				const item = items[i]
				const itemPath = `${parentPath}${level}-${i}`

				try {
					let pageNumber: number | null = null
					if (item.dest) {
						let pageRef: any = null

						if (Array.isArray(item.dest)) {
							pageRef = item.dest[0]
						} else {
							const destRef = await pdfViewer.pdfDocument.getDestination(
								item.dest,
							)
							if (Array.isArray(destRef) && destRef.length > 0) {
								pageRef = destRef[0]
							}
						}

						if (pageRef) {
							if (typeof pageRef === 'object') {
								const pageIndex = await pdfViewer.pdfDocument.getPageIndex(
									pageRef,
								)
								pageNumber = pageIndex + 1
							} else if (typeof pageRef === 'number') {
								pageNumber = pageRef + 1
							}
						}
					}

					if (pageNumber === currentPage) {
						return itemPath
					}
				} catch {
					// Skip errors in page resolution
				}

				if (item.items && item.items.length > 0) {
					const childResult = await findItemForPage(
						item.items,
						itemPath,
						level + 1,
					)
					if (childResult) {
						return childResult
					}
				}
			}
			return null
		}

		void findItemForPage(outline).then(path => {
			setActiveItemPath(path)
		})
	}, [currentPage, outline, pdfViewer])

	return (
		<div
			className='relative h-full overflow-y-auto shadow-lg'
			style={{
				backgroundColor: INTERFACE_BACKGROUND_COLOR,
				width: width + 'px',
				cursor: isResizing ? 'ew-resize' : 'auto',
				opacity: width < MIN_WIDTH ? 0 : 1,
				transition: isResizing ? 'none' : 'opacity 200ms ease-out',
			}}
		>
			<div className='scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-200 hover:scrollbar-thumb-gray-300 h-full overflow-y-auto p-4'>
				<Combobox value={null} onChange={handleOutlineClick} nullable>
					<div className='relative mb-2'>
						{!query && (
							<Search className='pointer-events-none absolute left-2 top-1/2 z-10 h-4 w-4 -translate-y-1/2 text-gray-500' />
						)}
						{query && (
							<button
								onClick={() => handleSearch('')}
								className='absolute left-2 top-1/2 z-10 -translate-y-1/2 rounded p-0.5 text-gray-500 hover:bg-gray-200 hover:text-gray-700'
								type='button'
							>
								<X className='h-4 w-4' />
							</button>
						)}
						<Combobox.Input
							className='w-full rounded border border-gray-200 py-2 pl-9 pr-2 text-sm focus:border-gray-300 focus:outline-none focus:ring-0'
							placeholder='Search outline...'
							onChange={event => handleSearch(event.target.value)}
							displayValue={(_item: FlatOutlineItem | null) => query}
						/>
					</div>
					<Combobox.Options static className='space-y-0.5'>
						{filteredItems.map(item => {
							const isSearchMatch =
								query && item.title.toLowerCase().includes(query.toLowerCase())
							return (
								<Combobox.Option
									key={item.path}
									value={item}
									disabled={!isSearchMatch && query.length > 0}
									onMouseEnter={() => setHoveredItem(item)}
									onMouseLeave={() => setHoveredItem(null)}
									className={twMerge(
										'group relative flex items-center py-0.5',
										isSearchMatch || !query
											? 'cursor-pointer'
											: 'cursor-default',
										query && !isSearchMatch && 'opacity-50',
									)}
									style={{
										marginLeft:
											item.level > 0 ? `${item.level * 20}px` : undefined,
									}}
								>
									{({ active }) => (
										<>
											{item.items?.length > 0 && (
												<button
													onClick={e => {
														if (!isSearchMatch) {
															e.preventDefault()
															e.stopPropagation()
														}
														toggleExpand(item.path)
													}}
													className={twMerge(
														'absolute left-0 flex h-full items-center px-0.5 opacity-0 transition-opacity duration-300',
														(hoveredItem?.path === item.path ||
															hoveredItem?.rootPath === item.rootPath) &&
															'opacity-100',
													)}
												>
													<div className='rounded hover:bg-gray-200'>
														<ChevronRight
															className={twMerge(
																'h-4 w-4 transform transition-transform duration-200',
																expandedItems.has(item.path) && 'rotate-90',
															)}
														/>
													</div>
												</button>
											)}
											<div
												className={twMerge(
													'w-full truncate pl-6 text-left',
													item.bold ? 'font-semibold' : '',
													item.italic ? 'italic' : '',
													item.level === 0
														? 'text-base'
														: 'text-sm text-gray-700',
													item.path === activeItemPath ? 'underline' : '',
													loadingItem === item.path ? 'animate-pulse' : '',
													active && isSearchMatch ? 'underline' : '',
												)}
											>
												{highlightText(item.title, query)}
											</div>
										</>
									)}
								</Combobox.Option>
							)
						})}
					</Combobox.Options>
				</Combobox>
			</div>
			<div
				className='absolute right-0 top-0 h-full w-1 cursor-ew-resize hover:bg-gray-300'
				onMouseDown={handleResizeStart}
			/>
			{isResizing && <div className='fixed inset-0 z-50 cursor-ew-resize' />}
		</div>
	)
}

export default PDFOutline
