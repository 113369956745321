import { useEffect, useState } from 'react'
import { MemoryProvider } from '../../contexts/MemoryProvider'
import { useGame } from '../../hooks/useGame'
import SessionStage from '../SessionStage/SessionStage'
import Sidebar from '../Sidebar'
import Chat from '../chat/Chat'
import DiceToolsWrapper from '../dice/DiceToolsWrapper'
import SceneTools from '../toolbar/SceneTools'
import GMTurnTracker from '../turnTracker/GMTurnTracker'
import PlayerTurnTracker from '../turnTracker/PlayerTurnTracker'
import UserHeads from '../users/UserHeads'
import GameName from './GameName'
import GuideOverlay from './GuideOverlay'
import LeftTools from './LeftTools'
import RightTools from './RightTools'
import Scratchpad from './Scratchpad'

const Interface = () => {
	const { game } = useGame()
	const [showGameName, setShowGameName] = useState(false)
	const [fadeOut, setFadeOut] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setFadeOut(true)
			const hideTimer = setTimeout(() => {
				setShowGameName(false)
			}, 1000) // Match the duration of the CSS transition
			return () => clearTimeout(hideTimer)
		}, 3000) // 3 seconds

		return () => clearTimeout(timer)
	}, [])

	return (
		<MemoryProvider>
			<div className='pointer-events-none fixed inset-0 z-30 flex flex-row gap-4 p-6 text-gray-300'>
				<div
					className='pointer-events-auto flex h-full flex-col justify-start gap-4'
					style={{ flex: '0 0 320px', width: '320px' }}
				>
					<LeftTools />
					<Scratchpad />
					<Chat />
				</div>

				<div className='pointer-events-none flex flex-auto flex-col justify-between'>
					<div className='flex flex-row items-center justify-center space-x-2'>
						{game.turnTracker.currentRound === 0 ? (
							<UserHeads />
						) : (
							<PlayerTurnTracker />
						)}
					</div>

					<div className='justify-end self-center'>
						<DiceToolsWrapper />
					</div>
				</div>

				<div
					className='flex flex-col justify-between space-y-4 @container'
					style={{ flex: '0 0 320px', width: '320px' }}
				>
					<div className='flex flex-1 flex-col space-y-4'>
						<RightTools />
						{/* <Syrinscape /> */}
						<GMTurnTracker />
						<SessionStage />
						<Sidebar />
					</div>
					<SceneTools />
				</div>
			</div>

			<GuideOverlay />

			{showGameName && (
				<div
					className={`pointer-events-none fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-1000 ${
						fadeOut ? 'opacity-0' : 'opacity-100'
					}`}
				>
					<GameName />
				</div>
			)}
		</MemoryProvider>
	)
}

export default Interface
