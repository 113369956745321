import React from 'react'

interface D8IconProps extends React.SVGProps<SVGSVGElement> {
	fill?: string
	stroke?: string
}

const D8Icon: React.FC<D8IconProps> = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	...props
}) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 595.28 595.28'
		{...props}
	>
		<path
			d='M527.24,405.65v-216.03c0-15.19-8.1-29.23-21.26-36.82l-187.09-108.01c-13.16-7.6-29.36-7.6-42.52,0l-187.09,108.01c-13.16,7.6-21.26,21.63-21.26,36.82v216.03c0,15.19,8.1,29.23,21.26,36.82l187.09,108.01c13.16,7.6,29.36,7.6,42.52,0l187.09-108.01c13.16-7.6,21.26-21.63,21.26-36.82Z'
			fill={fill}
			stroke={stroke}
			strokeWidth='22.11'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<polygon
			points='297.64 45.28 79.09 423.82 516.19 423.82 297.64 45.28'
			fill='none'
			stroke={stroke}
			strokeWidth='10.69'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default D8Icon
