import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Avatar from '../../Avatar'
import Tooltip from '../../Tooltip'
import { INTERFACE_BRIGHT_GOLD_COLOR } from '../../interface/constants'

interface AccountLinkProps {
	avatarSrc: string
	userName: string
}

const AccountLink: React.FC<AccountLinkProps> = ({ avatarSrc, userName }) => {
	const [showLabel, setShowLabel] = useState(false)
	const anchorRef = useRef(null)

	return (
		<NavLink to='/profile' className='inline-block text-xl'>
			<div
				ref={anchorRef}
				onMouseEnter={() => setShowLabel(true)}
				onMouseLeave={() => setShowLabel(false)}
			>
				<Avatar
					src={avatarSrc}
					userName={userName}
					color={INTERFACE_BRIGHT_GOLD_COLOR}
					noBorder
					size='md'
				/>
			</div>

			<Tooltip open={showLabel} anchor={anchorRef} placement='bottom'>
				EditAccount
			</Tooltip>
		</NavLink>
	)
}

export default AccountLink
