import { useMutation } from '@apollo/client'
import { useContext, useRef } from 'react'
import GameContext from '../../contexts/game'
import { ADD_ASSET } from '../../graphql/games'
import useAssetUploader from '../../hooks/useAssetUploader'
import Button from '../FormComponents/Button'
import Label from '../FormComponents/Label'
import UploadField from '../FormComponents/UploadField'

export default function CoverImage() {
	const { game, dispatch } = useContext(GameContext)
	const gameId = game._id
	const fileRef = useRef<HTMLInputElement>(null)
	const assetUploader = useAssetUploader()

	const [addAsset] = useMutation(ADD_ASSET, {
		onCompleted: data => {
			const { asset } = data.addAsset
			const assetId = asset._id

			dispatch({
				type: 'UPDATE_COVER_IMAGE',
				payload: {
					coverImage: assetId,
				},
			})
		},
		onError: error => {
			console.error(error)
		},
	})

	const handleChangeImage = async (gameId: string) => {
		// @ts-ignore TODO type this
		const file = fileRef.current.files[0]

		const asset = await assetUploader(file, gameId)
		console.log('added asset', asset)
		if (!asset) return

		addAsset({
			variables: {
				gameId,
				asset,
			},
		})
	}

	return (
		<div className='center mt-1 flex align-middle'>
			<Label htmlFor='coverimage' className='mt-3 flex-grow'>
				Cover
			</Label>

			<div className='relative h-9 w-80'>
				<Button className='pointer-events-none absolute top-0 right-0 mt-0 bg-gray-800'>
					{game.coverImage ? 'Replace Cover Image' : 'Upload Cover Image'}
				</Button>
				<UploadField
					ref={fileRef}
					name='coverimage'
					acceptTypes='image/*'
					onChange={() => {
						handleChangeImage(gameId)
					}}
				/>
			</div>
		</div>
	)
}
