import { useQueue } from '../../contexts/queue'

const UploadProgress = () => {
	const { queueLength, items } = useQueue()

	const activeItems = Object.values(items).filter(
		item =>
			item.status === 'uploading' ||
			item.status === 'processing' ||
			item.status === 'error',
	)

	if (activeItems.length === 0 && queueLength === 0) return null

	return (
		<div className='min-w-[300px] rounded-lg p-4 text-white'>
			<div className='flex flex-col gap-3'>
				{activeItems.map(item => (
					<div key={item.id}>
						<div className='mb-2 flex items-center justify-between'>
							<div className='flex items-center gap-2'>
								{item.status === 'error' ? (
									<div className='h-4 w-4 text-red-500'>⚠️</div>
								) : item.status === 'uploading' ? (
									<div className='h-4 w-4 animate-spin rounded-full border-2 border-blue-500 border-t-transparent' />
								) : item.status === 'processing' ? (
									<div className='h-4 w-4 animate-spin rounded-full border-2 border-green-500 border-t-transparent' />
								) : (
									<div className='h-4 w-4 text-blue-500'>✓</div>
								)}
								<span
									className={`max-w-[180px] truncate text-sm font-medium ${
										item.status === 'error' ? 'text-red-400' : ''
									}`}
								>
									{item.file.name}
								</span>
							</div>
							{item.status !== 'error' &&
								(item.uploadProgress !== undefined ||
									item.processingProgress !== undefined) && (
									<span
										className={`text-sm font-bold ${
											item.status === 'processing'
												? 'text-green-400'
												: 'text-blue-400'
										}`}
									>
										{item.status === 'uploading'
											? `${item.uploadProgress}%`
											: `${item.processingProgress}%`}
									</span>
								)}
						</div>
						{item.status !== 'error' && (
							<div className='h-1.5 w-full rounded-full bg-gray-700/50'>
								<div
									className={`h-1.5 rounded-full transition-all duration-300 ${
										item.status === 'uploading'
											? 'bg-blue-500'
											: item.status === 'processing'
											? 'bg-green-500'
											: 'bg-gray-500'
									}`}
									style={{
										width: `${
											item.status === 'uploading'
												? item.uploadProgress ?? 0
												: item.processingProgress ?? 0
										}%`,
									}}
								/>
							</div>
						)}
						{item.status === 'error' && item.error && (
							<p className='mt-1 text-xs text-red-400'>{item.error}</p>
						)}
					</div>
				))}
				{queueLength > 0 && (
					<div className='flex items-center gap-2 text-sm text-gray-400'>
						<span className='inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-700 text-xs font-medium'>
							{queueLength}
						</span>
						{queueLength === 1 ? 'file' : 'files'} remaining
					</div>
				)}
			</div>
		</div>
	)
}

export default UploadProgress
