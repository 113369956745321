import { ArrowUp } from 'lucide-react'
import { useContext, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import UsersContext from '../../contexts/users'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useUser from '../../hooks/useUser'
import Avatar from '../Avatar'
import Toolbar from '../interface/toolbar/Toolbar'
import TextArea from '../TextArea'

interface InputProps {
	message: string
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	onSend: () => void
}

const Input = ({ message, onChange, onSend }: InputProps) => {
	const { color, userId } = useUser()
	const { usersState } = useContext(UsersContext)
	const user = usersState.users.find(u => u.userId === userId)
	const name = user?.username

	const avatarSrc = useGetAvatarSrc({ userId })
	const inputRef = useRef<HTMLTextAreaElement>(null)
	const toolbarRef = useRef<HTMLDivElement>(null)

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.altKey && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault()
			onSend()
		}
	}

	return (
		<div className='w-full flex-1'>
			<div ref={toolbarRef} className='w-full'>
				<Toolbar className='w-full' expandable>
					<Avatar color={color} src={avatarSrc} size='lg' userName={name} />

					<TextArea
						ref={inputRef}
						value={message}
						onChange={onChange}
						onKeyDown={handleKeyDown}
						placeholder='Message...'
						className='min-h-10 m-0 w-full resize-none rounded-xl border-0 bg-transparent p-2 py-2.5 text-sm text-black outline-none placeholder:text-gray-500'
						style={{ resize: 'none' }}
					/>
					{message && (
						<button
							type='submit'
							className={twMerge('h-10 rounded-r-full pr-4 text-xl text-black')}
							onClick={onSend}
						>
							<ArrowUp />
						</button>
					)}
				</Toolbar>
			</div>
		</div>
	)
}

export default Input
