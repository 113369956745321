import { createContext, ReactNode, useState } from 'react'

interface ProfileEditContextType {
	editProfile: boolean
	setEditProfile: (edit: boolean) => void
}

export const ProfileEditContext = createContext<
	ProfileEditContextType | undefined
>(undefined)

export const ProfileEditProvider = ({ children }: { children: ReactNode }) => {
	const [editProfile, setEditProfile] = useState(false)

	return (
		<ProfileEditContext.Provider value={{ editProfile, setEditProfile }}>
			{children}
		</ProfileEditContext.Provider>
	)
}
