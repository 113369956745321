import { RPGBookCategory } from '../../../shared/types/bookcategories'
import RPGBookCategoryDescriptions from '../interfaces/bookcategories'

// Convert display name to enum value
export const displayNameToCategory = (
	displayName: string,
): RPGBookCategory | null => {
	const entry = Object.entries(RPGBookCategoryDescriptions).find(
		([_, info]) => info.name === displayName,
	)
	return entry ? (entry[0] as RPGBookCategory) : null
}

// Convert enum value to display name
export const categoryToDisplayName = (category: RPGBookCategory): string => {
	return RPGBookCategoryDescriptions[category]?.name || category
}

// Validate a category
export const isValidCategory = (
	category: string,
): category is RPGBookCategory => {
	return category in RPGBookCategoryDescriptions
}

// Convert any string to a valid category (for migration/fixing)
export const toValidCategory = (str: string): RPGBookCategory | null => {
	// If it's already valid, return it
	if (isValidCategory(str)) return str

	// Try to find it by display name
	const fromDisplay = displayNameToCategory(str)
	if (fromDisplay) return fromDisplay

	// Convert to snake_case and check if valid
	const snakeCase = str
		.toLowerCase()
		.replace(/[^a-z0-9]+/g, '_')
		.replace(/^_+|_+$/g, '')

	return isValidCategory(snakeCase) ? snakeCase : null
}

// Filter out invalid categories
export const filterValidCategories = (
	categories: string[],
): RPGBookCategory[] => {
	return categories
		.map(cat => toValidCategory(cat))
		.filter((cat): cat is RPGBookCategory => cat !== null)
}
