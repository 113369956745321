import React from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../hooks/useTheme'
import MediumUppercase from '../interface/text/MediumUppercase'
import SmallUppercase from '../interface/text/SmallUppercase'

import useUser from '../../hooks/useUser'
import D10Icon from '../dice-icons/D10Icon'
import D12Icon from '../dice-icons/D12Icon'
import D20Icon from '../dice-icons/D20Icon'
import D4Icon from '../dice-icons/D4Icon'
import D6Icon from '../dice-icons/D6Icon'
import D8Icon from '../dice-icons/D8Icon'
import Toolbar from '../interface/toolbar/Toolbar'
import ToolButton from '../interface/toolbar/ToolButton'

const diceIcons: {
	[key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>
} = {
	d4: D4Icon,
	d6: D6Icon,
	d8: D8Icon,
	d10: D10Icon,
	d12: D12Icon,
	d20: D20Icon,
}

interface AdvRollBtnProps {
	label: string | React.ReactNode
	tooltip?: string
	onRoll: (notation: string) => void
	onRemove?: (notation: string) => void
	notation: string
	disabled?: boolean
	icons?: string[]
	className?: string
}

const AdvRollBtn: React.FC<AdvRollBtnProps> = ({
	label,
	tooltip,
	onRoll,
	onRemove,
	notation,
	disabled = false,
	icons,
	className,
}) => {
	const { primary } = useTheme()
	const { color } = useUser()

	const roll = () => {
		if (!disabled) {
			onRoll(notation)
		}
	}

	const handleRightClick = (event: React.MouseEvent) => {
		event.preventDefault()
		if (onRemove) {
			onRemove(notation)
		}
	}

	const renderContent = () => {
		if (icons && icons.length > 0) {
			return (
				<div className='flex items-center justify-center'>
					{icons.map((icon, index) => {
						const IconComponent = diceIcons[icon]
						return IconComponent ? (
							<IconComponent
								key={index}
								className='h-7 w-7'
								fill={color}
								stroke='white'
							/>
						) : null
					})}
				</div>
			)
		} else {
			return typeof label === 'string' && label.length > 7 ? (
				<SmallUppercase style={{ color: primary }}>{label}</SmallUppercase>
			) : (
				<MediumUppercase>{label}</MediumUppercase>
			)
		}
	}

	return (
		<Toolbar className={twMerge('shadow-md', className)}>
			<ToolButton
				className={twMerge('w-20', disabled && 'cursor-not-allowed opacity-50')}
				onClick={roll}
				onContextMenu={handleRightClick}
				tooltip={
					typeof tooltip === 'string'
						? tooltip
						: typeof label === 'string'
						? label
						: ''
				}
				disabled={disabled}
			>
				{renderContent()}
			</ToolButton>
		</Toolbar>
	)
}

export default AdvRollBtn
