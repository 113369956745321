import { useMutation } from '@apollo/client'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSpring } from 'react-spring'
import { twMerge } from 'tailwind-merge'
import { RPGBookCategory } from '../../../../../shared/types/bookcategories'
import { BookResource } from '../../../../../shared/types/resources'
import { ResourceContext } from '../../../contexts/resources'
import { DELETE_RESOURCES } from '../../../graphql/resources'
import useBookSelection from '../../../hooks/useBookSelection'
import useDeleteEmbeddings from '../../../hooks/useDeleteEmbeddings'
import RPGBookCategoryDescriptions from '../../../interfaces/bookcategories'
import ResourceDropzone from '../../resources/ResourceDropZone'
import BookHeader from './elements/BookHeader'
import BookSelectionToolbar from './elements/BookSelectionToolbar'
import BookSidebar from './elements/BookSidebar'
import GroupedBookList from './elements/GroupedBookList'
import { ViewMode } from './elements/ViewSelector'

export interface BooksResources {
	[key: string]: BookResource
}

const SIDEBAR_STATE_KEY = 'bookListSidebarOpen'

const BooksPage = () => {
	const { resources, dispatchResource } = useContext(ResourceContext)
	const [search, setSearch] = useState('')
	const [searchTerms, setSearchTerms] = useState<
		Array<{
			type:
				| 'text'
				| 'title'
				| 'author'
				| 'publisher'
				| 'game'
				| 'ruleSystem'
				| 'tag'
			value: string
		}>
	>([])
	const [selectMode, setSelectMode] = useState(false)
	const [currentView, setCurrentView] = useState<ViewMode>(() => {
		const savedView = localStorage.getItem('bookListView')
		return (savedView as ViewMode) || 'category' // Default to 'category' if no saved view
	})
	const [sidebarOpen, setSidebarOpen] = useState(() => {
		const saved = localStorage.getItem(SIDEBAR_STATE_KEY)
		return saved ? JSON.parse(saved) : true // Default to open
	})
	const [selectedCategories, setSelectedCategories] = useState<
		RPGBookCategory[]
	>([])
	const [selectedGameSystems, setSelectedGameSystems] = useState<string[]>([])
	const [selectedRuleSystems, setSelectedRuleSystems] = useState<string[]>([])
	const { selectedIds, handleSelect, handleDeselectAll } =
		useBookSelection<string>()
	const { deleteEmbeddings } = useDeleteEmbeddings()
	const [deleteResources] = useMutation(DELETE_RESOURCES)
	const [isHeaderStuck, setIsHeaderStuck] = useState(false)
	const headerRef = useRef<HTMLDivElement>(null)
	const [cardMargin, setCardMargin] = useState(32) // Start with full margin (2rem)
	const containerRef = useRef<HTMLDivElement>(null)

	const [springs, api] = useSpring(() => ({
		margin: 32,
		config: {
			tension: 300,
			friction: 30,
		},
	}))

	useEffect(() => {
		localStorage.setItem(SIDEBAR_STATE_KEY, JSON.stringify(sidebarOpen))
	}, [sidebarOpen])

	useEffect(() => {
		const handleScroll = () => {
			if (!headerRef.current) return

			const rect = headerRef.current.getBoundingClientRect()
			const distanceToTop = rect.top
			const startDistance = 200 // Distance at which we start reducing margin

			if (distanceToTop <= startDistance) {
				// Calculate a proportional margin based on distance to top
				// When distance is startDistance -> margin is 32 (full margin)
				// When distance is 0 -> margin is 0
				const margin = Math.max(0, (distanceToTop / startDistance) * 32)
				setIsHeaderStuck(distanceToTop <= 0)
				setCardMargin(margin)
			} else {
				setIsHeaderStuck(false)
				setCardMargin(32) // Full margin when not near top
			}
		}

		window.addEventListener('scroll', handleScroll, { passive: true })
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	useEffect(() => {
		api.start({
			margin: cardMargin,
		})
	}, [cardMargin, api])

	// Get unique game systems and rule systems from all book resources
	const { availableGameSystems, availableRuleSystems } = useMemo(() => {
		const gameSystems = new Set<string>()
		const ruleSystems = new Set<string>()
		Object.values(resources).forEach(resource => {
			if (resource.resourceType === 'book') {
				if (resource.metadata.game) {
					gameSystems.add(resource.metadata.game)
				}
				if (resource.metadata.ruleSystem) {
					ruleSystems.add(resource.metadata.ruleSystem)
				}
			}
		})
		return {
			availableGameSystems: Array.from(gameSystems).sort(),
			availableRuleSystems: Array.from(ruleSystems).sort(),
		}
	}, [resources])

	const filteredResources = useMemo(() => {
		return Object.fromEntries(
			Object.entries(resources).filter(([, resource]) => {
				if (resource.resourceType !== 'book') return false

				// Search is always an AND condition
				const searchLower = search.toLowerCase()
				const matchesCurrentSearch =
					searchLower === '' ||
					// Title/name search
					resource.name.toLowerCase().includes(searchLower) ||
					// Description search
					resource.metadata.description?.toLowerCase().includes(searchLower) ||
					// Author search
					resource.metadata.authors?.some(author =>
						author.toLowerCase().includes(searchLower),
					) ||
					// Publisher search
					resource.metadata.publisher?.toLowerCase().includes(searchLower) ||
					// Game system search
					resource.metadata.game?.toLowerCase().includes(searchLower) ||
					// Rule system search
					resource.metadata.ruleSystem?.toLowerCase().includes(searchLower) ||
					// Tags search
					resource.metadata.tags?.some(tag =>
						tag.toLowerCase().includes(searchLower),
					)

				// Check if resource matches any search terms (OR condition)
				const matchesSearchTerms =
					searchTerms.length === 0 ||
					searchTerms.some(term => {
						const termLower = term.value.toLowerCase()
						switch (term.type) {
							case 'title':
								return resource.name.toLowerCase().includes(termLower)
							case 'author':
								return resource.metadata.authors?.some(author =>
									author.toLowerCase().includes(termLower),
								)
							case 'publisher':
								return resource.metadata.publisher
									?.toLowerCase()
									.includes(termLower)
							case 'game':
								return resource.metadata.game?.toLowerCase().includes(termLower)
							case 'ruleSystem':
								return resource.metadata.ruleSystem
									?.toLowerCase()
									.includes(termLower)
							case 'tag':
								return resource.metadata.tags?.some(tag =>
									tag.toLowerCase().includes(termLower),
								)
							case 'text':
							default:
								return (
									resource.name.toLowerCase().includes(termLower) ||
									resource.metadata.description
										?.toLowerCase()
										.includes(termLower) ||
									resource.metadata.authors?.some(author =>
										author.toLowerCase().includes(termLower),
									) ||
									resource.metadata.publisher
										?.toLowerCase()
										.includes(termLower) ||
									resource.metadata.game?.toLowerCase().includes(termLower) ||
									resource.metadata.ruleSystem
										?.toLowerCase()
										.includes(termLower) ||
									resource.metadata.tags?.some(tag =>
										tag.toLowerCase().includes(termLower),
									)
								)
						}
					})

				if (!matchesCurrentSearch || !matchesSearchTerms) return false

				// A book matches if:
				// 1. No filters are selected in a category, OR
				// 2. The book matches ANY of the selected filters in that category

				// Categories: Match any selected category
				const matchesCategories =
					selectedCategories.length === 0 ||
					resource.metadata.categories?.some(
						category =>
							RPGBookCategoryDescriptions[category] &&
							selectedCategories.includes(category),
					) ||
					false

				// Game Systems: Match any selected game system
				const matchesGameSystems =
					selectedGameSystems.length === 0 ||
					(resource.metadata.game &&
						selectedGameSystems.includes(resource.metadata.game))

				// Rule Systems: Match any selected rule system
				const matchesRuleSystems =
					selectedRuleSystems.length === 0 ||
					(resource.metadata.ruleSystem &&
						selectedRuleSystems.includes(resource.metadata.ruleSystem))

				// Book must match ALL filter types (if they have selections)
				return matchesCategories && matchesGameSystems && matchesRuleSystems
			}),
		) as BooksResources
	}, [
		resources,
		search,
		searchTerms,
		selectedCategories,
		selectedGameSystems,
		selectedRuleSystems,
	])

	// Generate suggestions based on all available books
	const suggestions = useMemo(() => {
		const suggestionSet = new Set<string>()
		const result: Array<{
			type: 'title' | 'author' | 'publisher' | 'game' | 'ruleSystem' | 'tag'
			value: string
		}> = []

		Object.values(resources).forEach(resource => {
			if (resource.resourceType !== 'book') return

			// Add title
			if (resource.name && !suggestionSet.has(`title:${resource.name}`)) {
				result.push({ type: 'title', value: resource.name })
				suggestionSet.add(`title:${resource.name}`)
			}

			// Add authors
			resource.metadata.authors?.forEach(author => {
				if (!suggestionSet.has(`author:${author}`)) {
					result.push({ type: 'author', value: author })
					suggestionSet.add(`author:${author}`)
				}
			})

			// Add publisher
			if (
				resource.metadata.publisher &&
				!suggestionSet.has(`publisher:${resource.metadata.publisher}`)
			) {
				result.push({ type: 'publisher', value: resource.metadata.publisher })
				suggestionSet.add(`publisher:${resource.metadata.publisher}`)
			}

			// Add game
			if (
				resource.metadata.game &&
				!suggestionSet.has(`game:${resource.metadata.game}`)
			) {
				result.push({ type: 'game', value: resource.metadata.game })
				suggestionSet.add(`game:${resource.metadata.game}`)
			}

			// Add rule system
			if (
				resource.metadata.ruleSystem &&
				!suggestionSet.has(`ruleSystem:${resource.metadata.ruleSystem}`)
			) {
				result.push({ type: 'ruleSystem', value: resource.metadata.ruleSystem })
				suggestionSet.add(`ruleSystem:${resource.metadata.ruleSystem}`)
			}

			// Add tags
			resource.metadata.tags?.forEach(tag => {
				if (!suggestionSet.has(`tag:${tag}`)) {
					result.push({ type: 'tag', value: tag })
					suggestionSet.add(`tag:${tag}`)
				}
			})
		})

		return result
	}, [resources])

	// Filter suggestions based on current search term
	const filteredSuggestions = useMemo(() => {
		if (!search) return []
		const searchLower = search.toLowerCase()
		return suggestions
			.filter(suggestion =>
				suggestion.value.toLowerCase().includes(searchLower),
			)
			.slice(0, 10) // Limit to 10 suggestions
	}, [suggestions, search])

	const handleAddToGame = () => {
		console.log('handleAddToGame not yet implemented', selectedIds)
		setSelectMode(false)
	}

	const handleDelete = () => {
		if (
			window.confirm(
				`Delete the ${
					selectedIds.length === 1
						? 'selected book'
						: `${selectedIds.length} selected books`
				}?`,
			)
		) {
			deleteResources({
				variables: {
					resourceIds: selectedIds,
				},
			})

			selectedIds.forEach(async bookId => {
				await deleteEmbeddings(bookId)
			})

			dispatchResource({
				type: 'REMOVE_RESOURCES',
				payload: { resourceIds: selectedIds },
			})

			setSelectMode(false)
		}
	}

	const handleSelectAll = () => {
		const allResourceIds = Object.keys(resources)
		allResourceIds.forEach(id => handleSelect(id, true))
	}

	const cardClassName = twMerge(
		isHeaderStuck && 'rounded-none',
		'transition-colors duration-300',
	)

	return (
		<ResourceDropzone cardClassName={cardClassName} style={springs}>
			<div ref={containerRef} className='flex min-h-screen flex-col'>
				<div
					ref={headerRef}
					className={twMerge(
						'sticky top-0 z-50 bg-gray-800/70 p-8 backdrop-blur-xl transition-all duration-300',
						isHeaderStuck && 'py-4 shadow-lg',
					)}
				>
					{!selectMode && (
						<BookHeader
							selectMode={selectMode}
							setSelectMode={setSelectMode}
							onToggleSidebar={() => setSidebarOpen(!sidebarOpen)}
							sidebarOpen={sidebarOpen}
							containerRef={containerRef}
							totalBooks={
								Object.values(resources).filter(r => r.resourceType === 'book')
									.length
							}
							filteredBooks={Object.keys(filteredResources).length}
						/>
					)}
					{selectMode && (
						<BookSelectionToolbar
							selectedIds={selectedIds}
							onAddToGame={handleAddToGame}
							onDelete={handleDelete}
							onSelectAll={handleSelectAll}
							onDeselectAll={handleDeselectAll}
							onDone={() => setSelectMode(false)}
						/>
					)}
				</div>

				<div id='book-list-content' className='flex flex-1'>
					<div
						className={twMerge(
							'transition-all duration-300',
							sidebarOpen ? 'w-64' : 'w-0',
						)}
					>
						<BookSidebar
							isOpen={sidebarOpen}
							selectedCategories={selectedCategories}
							onCategoriesChange={setSelectedCategories}
							selectedGameSystems={selectedGameSystems}
							onGameSystemsChange={setSelectedGameSystems}
							availableGameSystems={availableGameSystems}
							selectedRuleSystems={selectedRuleSystems}
							onRuleSystemsChange={setSelectedRuleSystems}
							availableRuleSystems={availableRuleSystems}
							currentView={currentView}
							onViewChange={setCurrentView}
							searchValue={search}
							onSearch={setSearch}
							searchTerms={searchTerms}
							onSearchTermsChange={setSearchTerms}
							suggestions={filteredSuggestions}
						/>
					</div>

					<div className='flex-1 p-8'>
						{Object.keys(filteredResources).length === 0 ? (
							<p className='my-20 text-center'>No Books</p>
						) : (
							<GroupedBookList
								resources={filteredResources}
								viewMode={currentView}
								selectMode={selectMode}
								selectedIds={selectedIds}
								onSelect={handleSelect}
							/>
						)}
					</div>
				</div>
			</div>
		</ResourceDropzone>
	)
}

export default BooksPage
