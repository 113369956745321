import ObjectID from 'bson-objectid'
import produce, { applyPatches, enablePatches } from 'immer'
import { IActorLocation } from '../../../shared/types/actor'
import { IScene } from '../../../shared/types/scene'
import { IGame, IGameReducerAction, IUserSettings } from '../interfaces/game'
import { pdfSheetReducer } from './pdfSheets'

enablePatches()

const Reducer = produce((draft: IGame, action: IGameReducerAction) => {
	switch (action.type) {
		case 'SET_GAME_TITLE':
			draft.title = action.payload.title
			break

		case 'SET_GAME_DESCRIPTION':
			draft.description = action.payload.description
			break

		case 'ADD_MESSAGE': {
			const newMessage = action.payload
			const newMessageId = newMessage._id
			if (newMessage.type === 'dice' && 'diceResults' in newMessage) {
				draft.log.byId[newMessageId] = {
					...newMessage,
					diceResults: { ...newMessage.diceResults },
				}
			} else {
				draft.log.byId[newMessageId] = newMessage
			}
			draft.log.allIds.push(newMessageId)
			break
		}

		case 'ADD_SYSTEM_MESSAGE': {
			const newMessageId = ObjectID().toHexString()
			const now = Date.now()
			draft.log.byId[newMessageId] = {
				_id: newMessageId,
				sender: null,
				type: 'system',
				access: 'public',
				accessList: [],
				createdAt: now,
				updatedAt: now,
				...action.payload,
			}
			draft.log.allIds.push(newMessageId)
			break
		}

		case 'DELETE_MESSAGE': {
			const messageId = action.payload
			delete draft.log.byId[messageId]
			draft.log.allIds = draft.log.allIds.filter(id => id !== messageId)
			break
		}

		case 'UPDATE_MESSAGE': {
			const updatedMessage = action.payload
			if (draft.log.byId[updatedMessage._id]) {
				if (updatedMessage.type === 'dice' && 'diceResults' in updatedMessage) {
					draft.log.byId[updatedMessage._id] = {
						...draft.log.byId[updatedMessage._id],
						...updatedMessage,
						diceResults: { ...updatedMessage.diceResults },
					}
				} else {
					draft.log.byId[updatedMessage._id] = {
						...draft.log.byId[updatedMessage._id],
						...updatedMessage,
					}
				}
			}
			if (!draft.log.allIds.includes(updatedMessage._id)) {
				draft.log.allIds.push(updatedMessage._id)
			}
			break
		}

		case 'DELETE_ALL_DOCUMENTS': {
			draft.documents.byId = {}
			draft.documents.allIds = []
			Object.values(draft.userSettings).forEach((settings: IUserSettings) => {
				settings.activeSceneId = ''
				settings.assumedCharacterId = ''
			})
			break
		}

		case 'ADD_USER': {
			const { userId, userSettings } = action.payload
			draft.users.push(userId)
			draft.userSettings[userId] = userSettings
			break
		}

		case 'REMOVE_USER': {
			const userId = action.payload.userId
			draft.users = draft.users.filter(id => id !== userId)
			delete draft.userSettings[userId]
			Object.values(draft.documents.byId).forEach(document => {
				document.accessList = document.accessList.filter(id => id !== userId)
			})
			break
		}

		case 'UPDATE_USER_SETTINGS': {
			const { userId, userSettings } = action.payload
			if (draft.userSettings[userId]) {
				Object.assign(draft.userSettings[userId], userSettings)
			}
			break
		}

		case 'UPDATE_USER_COLOR': {
			const { userId, color } = action.payload
			draft.userSettings[userId].color = color
			break
		}

		case 'UPDATE_STATE':
			Object.assign(draft, action.payload.game)
			break

		case 'LOAD_SYSTEM':
			draft.system = action.payload.system
			break

		case 'ADD_DOCUMENT': {
			const newDocument = action.payload.document
			const documentId = newDocument._id
			draft.documents.byId[documentId] = newDocument
			draft.documents.allIds.push(documentId)
			break
		}

		case 'DELETE_DOCUMENT': {
			const documentId = action.payload.documentId
			delete draft.documents.byId[documentId]
			draft.documents.allIds = draft.documents.allIds.filter(
				id => id !== documentId,
			)
			Object.values(draft.userSettings).forEach(settings => {
				if (settings.activeSceneId === documentId) {
					settings.activeSceneId = ''
				}
				if (settings.assumedCharacterId === documentId) {
					settings.assumedCharacterId = ''
				}
			})
			break
		}

		case 'DELETE_LOG': {
			const firstMessageId = draft.log.allIds[0]
			const firstMessage = draft.log.byId[firstMessageId]
			draft.log.byId = { [firstMessageId]: firstMessage }
			draft.log.allIds = [firstMessageId]
			break
		}

		case 'REORDER_DOCUMENTS': {
			const { documentIds, documentId, parentId } = action.payload
			draft.documents.allIds = documentIds
			draft.documents.byId[documentId].parentId = parentId
			break
		}

		case 'UPDATE_DOCUMENT': {
			const updatedDocument = action.payload.updatedDocument
			const existingDocument = draft.documents.byId[updatedDocument._id]

			if (
				!existingDocument ||
				!Object.prototype.hasOwnProperty.call(updatedDocument, 'version') ||
				updatedDocument.version > existingDocument.version
			) {
				const documentCopy = {
					...updatedDocument,
					values: {
						...updatedDocument.values,
					},
				}

				if (documentCopy.type === 'actor') {
					documentCopy.values.locations = Array.isArray(
						updatedDocument.values.locations,
					)
						? [...updatedDocument.values.locations]
						: []
				}

				draft.documents.byId[updatedDocument._id] = documentCopy
				if (!draft.documents.allIds.includes(updatedDocument._id)) {
					draft.documents.allIds.push(updatedDocument._id)
				}
			}
			break
		}

		case 'RENAME_DOCUMENT': {
			const { documentId, name } = action.payload
			if (draft.documents.byId[documentId]) {
				draft.documents.byId[documentId].values.name = name
			}
			break
		}

		case 'ADD_ASSET': {
			const newAsset = action.payload.asset
			const assetId = newAsset._id
			draft.assets.byId[assetId] = newAsset
			draft.assets.allIds.push(assetId)
			break
		}

		case 'REMOVE_ASSET': {
			const assetId = action.payload.assetId
			delete draft.assets.byId[assetId]
			draft.assets.allIds = draft.assets.allIds.filter(id => id !== assetId)
			break
		}

		case 'ADD_ACTOR_WITH_SCENE': {
			const { document: actor, sceneId } = action.payload
			const location = actor.values.locations?.find(
				loc => loc.mapId === sceneId,
			)

			// Add the document if it doesn't exist
			if (!draft.documents.byId[actor._id]) {
				draft.documents.byId[actor._id] = actor
				draft.documents.allIds.push(actor._id)
			} else {
				// Update existing actor's locations
				const existingActor = draft.documents.byId[actor._id]
				if (existingActor.type === 'actor') {
					existingActor.values.locations = existingActor.values.locations || []
					if (
						!existingActor.values.locations.some(loc => loc.mapId === sceneId)
					) {
						existingActor.values.locations.push(location)
					}
				}
			}

			// Add to scene
			const scene = draft.documents.byId[sceneId] as IScene
			if (scene?.type === 'scene') {
				scene.values.actors = scene.values.actors || []
				if (!scene.values.actors.includes(actor._id)) {
					scene.values.actors.push(actor._id)
				}
			}
			break
		}

		case 'REMOVE_ACTOR_FROM_SCENE': {
			const { sceneId, actorId } = action.payload
			const scene = draft.documents.byId[sceneId]
			const actor = draft.documents.byId[actorId]
			if (scene && actor && actor.type === 'actor') {
				if (scene.values.actors) {
					scene.values.actors = scene.values.actors.filter(
						(id: string) => id !== actorId,
					)
				}
				actor.values.locations = actor.values.locations.filter(
					(loc: IActorLocation) => loc.mapId !== sceneId,
				)
			}
			break
		}

		case 'REMOVE_ALL_ACTORS_FROM_SCENE': {
			const { sceneId } = action.payload
			// remove scene from each actor's locations
			Object.values(draft.documents.byId).forEach(document => {
				if (document.type === 'actor') {
					document.values.locations = document.values.locations.filter(
						(loc: IActorLocation) => loc.mapId !== sceneId,
					)
				}
			})
			// remove all actors from the scene
			const scene = draft.documents.byId[sceneId]
			if (scene) {
				scene.values.actors = []
			}
			break
		}

		case 'SET_DRAWING_SIZE': {
			draft.drawing.size = action.payload.size
			break
		}

		case 'SET_DRAWING_COLOR': {
			draft.drawing.color = action.payload.color
			break
		}

		case 'ADD_MARK': {
			const { sceneId, mark } = action.payload
			const document = draft.documents.byId[sceneId]
			if (document) {
				document.values.marks = document.values.marks || []
				document.values.marks.push(mark)
			}
			break
		}

		case 'DELETE_MARKS': {
			const { sceneId, markIds } = action.payload
			const document = draft.documents.byId[sceneId]
			if (document && document.values.marks) {
				document.values.marks = document.values.marks.filter(
					mark => !markIds.includes(mark._id),
				)
			}
			break
		}

		case 'CLEAR_MARKS': {
			const { sceneId } = action.payload
			const scene = draft.documents.byId[sceneId]
			if (scene) {
				scene.values.marks = []
			}
			break
		}

		case 'ADD_FOG_POLYGON': {
			const { sceneId, polygon } = action.payload
			const scene = draft.documents.byId[sceneId]
			if (scene) {
				scene.values.fog = scene.values.fog || []
				scene.values.fog.push(polygon)
			}
			break
		}

		case 'DELETE_FOG_POLYGON': {
			const { sceneId, polygonId } = action.payload
			const scene = draft.documents.byId[sceneId]
			if (scene && scene.values.fog) {
				scene.values.fog = scene.values.fog.filter(
					polygon => polygon._id !== polygonId,
				)
			}
			break
		}

		case 'CLEAR_FOG': {
			const { sceneId } = action.payload
			const scene = draft.documents.byId[sceneId]
			if (scene) {
				scene.values.fog = []
			}
			break
		}

		case 'UPDATE_COVER_IMAGE':
			draft.coverImage = action.payload.coverImage
			break

		case 'UPDATE_LOGO':
			draft.logo = action.payload.logo
			break

		case 'UPDATE_LOGO_VISIBILITY':
			draft.hideLogo = action.payload.hideLogo
			break

		case 'SHOW_MAP': {
			const { sceneId, showMap } = action.payload
			const document = draft.documents.byId[sceneId]
			if (document) {
				document.values.showMap = showMap
			}
			break
		}

		case 'SET_ACTIVE_SCENE': {
			const { sceneId } = action.payload
			Object.values(draft.userSettings).forEach(settings => {
				settings.activeSceneId = sceneId
			})
			break
		}

		case 'SET_DOCUMENT_ACCESS': {
			const { documentId, access } = action.payload
			const accessList = access === 'private' ? action.payload.accessList : []
			if (draft.documents.byId[documentId]) {
				draft.documents.byId[documentId].access = access
				draft.documents.byId[documentId].accessList =
					access === 'private'
						? accessList
						: draft.documents.byId[documentId].accessList
			}
			Object.values(draft.userSettings).forEach(settings => {
				if (
					settings.activeSceneId === documentId ||
					settings.assumedCharacterId === documentId
				) {
					settings.activeSceneId =
						settings.activeSceneId === documentId ? '' : settings.activeSceneId
					settings.assumedCharacterId =
						settings.assumedCharacterId === documentId
							? ''
							: settings.assumedCharacterId
				}
			})
			break
		}

		case 'ADD_USER_TO_DOCUMENT': {
			const { documentId, userId } = action.payload
			if (draft.documents.byId[documentId]) {
				if (!draft.documents.byId[documentId].accessList.includes(userId)) {
					draft.documents.byId[documentId].accessList.push(userId)
				}
			}
			break
		}

		case 'ADD_DOCUMENTS_TO_SCENE': {
			const { sceneId, documentIds } = action.payload
			const scene = draft.documents.byId[sceneId]
			if (scene) {
				scene.values.documents = scene.values.documents
					? [...scene.values.documents, ...documentIds]
					: [...documentIds]
			}
			break
		}

		case 'REMOVE_DOCUMENTS_FROM_SCENE': {
			const { sceneId, documentIds } = action.payload
			const scene = draft.documents.byId[sceneId]
			if (scene && scene.values.documents) {
				scene.values.documents = scene.values.documents.filter(
					(documentId: string) => !documentIds.includes(documentId),
				)
			}
			break
		}

		case 'ASSUME_CHARACTER': {
			const { userId, characterId } = action.payload

			// Reset assumedCharacterId for all users who had assumed this character
			Object.values(draft.userSettings).forEach(setting => {
				if (setting.assumedCharacterId === characterId) {
					setting.assumedCharacterId = ''
				}
			})

			// Update the assumedCharacterId for the specified user
			if (draft.userSettings[userId]) {
				draft.userSettings[userId].assumedCharacterId = characterId
			}

			break
		}

		case 'UNASSUME_CHARACTER': {
			const { characterId } = action.payload
			Object.values(draft.userSettings).forEach(setting => {
				if (setting.assumedCharacterId === characterId) {
					setting.assumedCharacterId = ''
				}
			})
			break
		}

		case 'SET_FLAG': {
			const { flag, value } = action.payload
			draft.flags[flag] = value
			break
		}

		case 'SET_THEME': {
			const { theme } = action.payload
			draft.theme = { ...draft.theme, ...theme }
			break
		}

		case 'APPLY_PATCHES': {
			try {
				return applyPatches(draft, action.patches)
			} catch (error) {
				console.error('Error applying patches:', error)
				return draft // Return the unmodified draft if an error occurs
			}
		}

		case 'TOGGLE_FOLDER_FOLD': {
			const { folderId } = action.payload
			const folder = draft.documents.byId[folderId]
			if (folder) {
				folder.values.folded = !folder.values.folded
			}
			break
		}

		case 'UPDATE_VERSION':
			draft.version = action.payload
			break

		case 'SHOW_TURN_TRACKER':
			draft.turnTracker.show = true
			break

		case 'HIDE_TURN_TRACKER':
			draft.turnTracker.show = false
			break

		case 'ADD_TO_TURN_ORDER':
			draft.turnTracker.turnOrder.byId[action.payload.item.id] =
				action.payload.item
			draft.turnTracker.turnOrder.allIds.splice(
				action.payload.index,
				0,
				action.payload.item.id,
			)
			break

		case 'REMOVE_FROM_TURN_ORDER':
			delete draft.turnTracker.turnOrder.byId[action.payload.id]
			draft.turnTracker.turnOrder.allIds =
				draft.turnTracker.turnOrder.allIds.filter(
					id => id !== action.payload.id,
				)
			break

		case 'SET_TURN_ORDER':
			draft.turnTracker.turnOrder.allIds = action.payload.newTurnOrder
			break

		case 'TURN_ORDER_NEXT': {
			const data = draft.turnTracker

			// If there are no items in the turn order, do nothing
			if (data.turnOrder.allIds.length === 0) {
				return
			}

			let i = data.currentTurnIndex !== null ? data.currentTurnIndex + 1 : 0
			while (i < data.turnOrder.allIds.length) {
				const item = data.turnOrder.byId[data.turnOrder.allIds[i]]
				if (item && item.isActive) {
					data.currentTurnIndex = i
					// Set isTurn to true for the active item and false for all other items
					data.turnOrder.allIds.forEach(id => {
						data.turnOrder.byId[id].isTurn = id === data.turnOrder.allIds[i]
					})
					return
				}
				i++
			}

			// If we've looped around, increment currentRound
			if (data.currentTurnIndex !== null) {
				data.currentRound++
			}

			i = 0
			while (i <= data.currentTurnIndex) {
				const item = data.turnOrder.byId[data.turnOrder.allIds[i]]
				if (item && item.isActive) {
					data.currentTurnIndex = i
					// Set isTurn to true for the active item and false for all other items
					data.turnOrder.allIds.forEach(id => {
						data.turnOrder.byId[id].isTurn = id === data.turnOrder.allIds[i]
					})
					return
				}
				i++
			}

			// If no active turn is found, reset currentTurnIndex to null
			data.currentTurnIndex = null
			break
		}

		case 'TURN_ORDER_PREVIOUS': {
			const data = draft.turnTracker

			// If there are no items in the turn order, do nothing
			if (data.turnOrder.allIds.length === 0) {
				return
			}

			let i =
				data.currentTurnIndex !== null
					? data.currentTurnIndex - 1
					: data.turnOrder.allIds.length - 1
			while (i >= 0) {
				const item = data.turnOrder.byId[data.turnOrder.allIds[i]]
				if (item && item.isActive) {
					data.currentTurnIndex = i
					// Set isTurn to true for the active item and false for all other items
					data.turnOrder.allIds.forEach(id => {
						data.turnOrder.byId[id].isTurn = id === data.turnOrder.allIds[i]
					})
					return
				}
				i--
			}

			// If we've looped around, decrement currentRound
			if (data.currentTurnIndex !== null) {
				data.currentRound--
			}

			i = data.turnOrder.allIds.length - 1
			while (i >= data.currentTurnIndex) {
				const item = data.turnOrder.byId[data.turnOrder.allIds[i]]
				if (item && item.isActive) {
					data.currentTurnIndex = i
					// Set isTurn to true for the active item and false for all other items
					data.turnOrder.allIds.forEach(id => {
						data.turnOrder.byId[id].isTurn = id === data.turnOrder.allIds[i]
					})
					return
				}
				i--
			}

			// If no active turn is found, reset currentTurnIndex to null
			data.currentTurnIndex = null
			break
		}

		case 'CLEAR_TURN_TRACKER':
			draft.turnTracker = {
				...draft.turnTracker,
				currentRound: 0,
				currentTurnIndex: 0,
				turnOrder: {
					byId: {},
					allIds: [],
				},
			}
			break

		case 'SET_TURN_TRACKER_ITEM_VISIBILITY': {
			const id = action.payload.id
			draft.turnTracker.turnOrder.byId[id].visibility =
				!draft.turnTracker.turnOrder.byId[id].visibility
			break
		}

		case 'TOGGLE_TURN_TRACKER_ITEM_IDENTIFIED': {
			const id = action.payload.id
			draft.turnTracker.turnOrder.byId[id].identified =
				!draft.turnTracker.turnOrder.byId[id].identified
			break
		}

		case 'TOGGLE_TURN_TRACKER_ITEM_ISACTIVE': {
			const id = action.payload.id
			draft.turnTracker.turnOrder.byId[id].isActive =
				!draft.turnTracker.turnOrder.byId[id].isActive
			break
		}

		case 'START_TURN_TRACKER': {
			const data = draft.turnTracker
			const activeItems = data.turnOrder.allIds.filter(
				id => data.turnOrder.byId[id].isActive,
			)
			if (activeItems.length === 0) {
				return // No change if there are no active items
			}
			data.currentRound = 1
			data.currentTurnIndex = data.turnOrder.allIds.indexOf(activeItems[0])
			data.turnOrder.allIds.forEach(id => {
				data.turnOrder.byId[id].isTurn =
					activeItems.includes(id) && id === activeItems[0]
			})
			break
		}

		case 'STOP_TURN_TRACKER':
			draft.turnTracker.currentRound = 0
			draft.turnTracker.currentTurnIndex = null
			draft.turnTracker.turnOrder.allIds.forEach(id => {
				draft.turnTracker.turnOrder.byId[id].isTurn = false
			})
			break

		case 'SET_TURN_TRACKER_ITEM_INITIATIVE':
			draft.turnTracker.turnOrder.byId[action.payload.id].initiative =
				action.payload.initiative
			break

		case 'SORT_TURN_ORDER_ASCENDING':
			draft.turnTracker.turnOrder.allIds.sort((a, b) => {
				const itemA = draft.turnTracker.turnOrder.byId[a]
				const itemB = draft.turnTracker.turnOrder.byId[b]
				if (itemA.initiative < itemB.initiative) return -1
				if (itemA.initiative > itemB.initiative) return 1
				return 0
			})
			break

		case 'SORT_TURN_ORDER_DESCENDING':
			draft.turnTracker.turnOrder.allIds.sort((a, b) => {
				const itemA = draft.turnTracker.turnOrder.byId[a]
				const itemB = draft.turnTracker.turnOrder.byId[b]
				if (itemA.initiative > itemB.initiative) return -1
				if (itemA.initiative < itemB.initiative) return 1
				return 0
			})
			break

		case 'SORT_TURN_ORDER': {
			const { dragId, hoverId } = action.payload
			const dragIndex = draft.turnTracker.turnOrder.allIds.indexOf(dragId)
			const hoverIndex = draft.turnTracker.turnOrder.allIds.indexOf(hoverId)

			// Swap the positions of the dragged and hovered items
			draft.turnTracker.turnOrder.allIds[dragIndex] = hoverId
			draft.turnTracker.turnOrder.allIds[hoverIndex] = dragId
			break
		}

		case 'SET_SYRINSCAPE_SESSION_ID':
			draft.syrinscape.sessionId = action.payload
			break

		case 'SET_SYRINSCAPE_VOLUME':
			draft.syrinscape.volume = action.payload
			break

		case 'SHOW_SYRINSCAPE':
			draft.syrinscape.show = true
			break

		case 'HIDE_SYRINSCAPE':
			draft.syrinscape.show = false
			break

		case 'ADD_SAVED_SOUNDSET':
			draft.syrinscape.savedSoundsets.push(action.payload)
			break

		case 'REMOVE_SAVED_SOUNDSET':
			draft.syrinscape.savedSoundsets = draft.syrinscape.savedSoundsets.filter(
				soundset => soundset !== action.payload,
			)
			break

		case 'SET_INVITE_TOKEN':
			draft.inviteToken = action.payload
			break

		case 'SET_BOOKS': {
			const { books } = action.payload
			draft.books.byId = books
			draft.books.allIds = Object.keys(books)
			break
		}

		case 'ADD_BOOK': {
			const newBook = action.payload.book
			draft.books.byId[newBook._id] = newBook
			draft.books.allIds.push(newBook._id)
			break
		}

		case 'REMOVE_BOOK': {
			// don't use this on its own, the asset also needs removing
			const bookIdToRemove = action.payload.bookId
			delete draft.books.byId[bookIdToRemove]
			draft.books.allIds = draft.books.allIds.filter(
				id => id !== bookIdToRemove,
			)
			break
		}

		case 'UPDATE_BOOK': {
			const updatedBook = action.payload.updatedBook
			draft.books.byId[updatedBook._id] = {
				...draft.books.byId[updatedBook._id],
				...updatedBook,
			}
			break
		}

		case 'SET_BOOK_ACCESS': {
			const { bookId, access, accessList } = action.payload
			if (draft.books.byId[bookId]) {
				draft.books.byId[bookId].access = access
				draft.books.byId[bookId].accessList = accessList
			}
			break
		}

		case 'TOGGLE_STAGE':
			if (draft.userSettings[action.payload.userId]) {
				draft.userSettings[action.payload.userId].showStage =
					!draft.userSettings[action.payload.userId].showStage
			}
			break

		case 'ADD_DOCUMENT_TO_STAGE': {
			const { documentId } = action.payload
			if (!draft.stage) {
				draft.stage = { documents: [] }
			}
			if (!draft.stage.documents.includes(documentId)) {
				draft.stage.documents = [...draft.stage.documents, documentId]
			}
			break
		}

		case 'REMOVE_DOCUMENT_FROM_STAGE': {
			const { documentId } = action.payload
			if (draft.stage && draft.stage.documents) {
				draft.stage.documents = draft.stage.documents.filter(
					id => id !== documentId,
				)
			}
			break
		}

		case 'SET_STAGE_DOCUMENTS':
			if (!draft.stage) {
				draft.stage = { documents: [] }
			}
			draft.stage.documents = action.payload.documents
			break

		case 'ADD_PDF_SHEET':
		case 'REMOVE_PDF_SHEET':
		case 'UPDATE_PDF_SHEET': {
			draft.pdfSheets = pdfSheetReducer(draft.pdfSheets, action)
			break
		}

		default:
			break
	}
})

export default Reducer
