import { X } from 'lucide-react'
import ToolButton from '../interface/toolbar/ToolButton'
import TooltipWrapper from '../TooltipWrapper'

interface CloseButtonProps {
	onClick: () => void
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
	return (
		<TooltipWrapper label='Close'>
			<ToolButton onClick={onClick} onTouchEnd={onClick}>
				<X className='h-5 w-5 text-black' aria-hidden='true' />
			</ToolButton>
		</TooltipWrapper>
	)
}

export default CloseButton
