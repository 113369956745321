/**
 * Hook that determines which collection types (Books, Maps, etc.) a user can access.
 * GMs can access all types except folders, while regular players only see types
 * that are either public or explicitly shared with them.
 */
import { useContext, useMemo } from 'react'
import { IDocument, TAccess } from '../../../shared/types/document'
import { stringBoolean } from '../../../shared/types/game'
import GameContext from '../contexts/game'
import { usePdfCollections } from './usePdfCollections'
import useUser from './useUser'

interface CollectionTypeInfo {
	type: string
	pluralName: string
	singularName: string
	defaultAccess?: TAccess
	allowCreate?: stringBoolean
}

const useCollectionTypes = () => {
	const { game } = useContext(GameContext)
	const { documents, system } = game
	const collections = useMemo(() => system?.collections || [], [system])
	const { userId, isGM } = useUser()
	const pdfCollections = usePdfCollections()

	const isDocumentAccessible = (document: IDocument) => {
		return (
			document.access === 'public' ||
			(Array.isArray(document.accessList) &&
				document.accessList.includes(userId))
		)
	}

	const collectionTypes = useMemo(() => {
		// Add 'Books' and PDF sheet collections to the initial collection types
		const allTypes: CollectionTypeInfo[] = [
			...collections.map(c => ({
				type: c.type,
				pluralName: c.pluralName,
				singularName: c.singularName,
				defaultAccess: c.defaultAccess as TAccess,
				allowCreate: c.allowCreate,
			})),
			{
				// this exists only to show the filter in the library
				type: 'Books',
				pluralName: 'Books',
				singularName: 'Book',
				defaultAccess: 'public',
				allowCreate: 'false',
			},
			...pdfCollections.map(c => ({
				type: c.type,
				pluralName: c.pluralName,
				singularName: c.singularName,
				defaultAccess: 'public' as TAccess,
				allowCreate: 'true' as stringBoolean,
			})),
		]

		// GMs see all collections types (except folders)
		if (isGM) {
			return allTypes.filter(({ type }) => type !== 'folder')
		}

		// Players see all collection types that are public
		// or which contain documents they have access to
		const initialTypes = allTypes.filter(
			({ type }) =>
				type === 'Books' ||
				collections.find(c => c.type === type && c.allowCreate === 'true') ||
				pdfCollections.find(c => c.type === type),
		)

		// Filter out types that the user has access to
		const { allIds, byId } = documents
		const accessibleTypes: CollectionTypeInfo[] = []
		allIds.forEach(id => {
			const doc = byId[id]
			if (
				isDocumentAccessible(doc) &&
				!accessibleTypes.some(t => t.type === doc.type)
			) {
				const collectionInfo = initialTypes.find(t => t.type === doc.type)
				if (collectionInfo) {
					accessibleTypes.push(collectionInfo)
				}
			}
		})

		return accessibleTypes
	}, [collections, documents, isGM, userId, pdfCollections])

	// Just sort the collection types
	return collectionTypes.sort((a, b) => a.type.localeCompare(b.type))
}

export default useCollectionTypes
